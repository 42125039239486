import React, { useEffect, useState } from "react";
import Announcement from "../../components/announcement";
import { Tooltip } from "react-tooltip";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../../stores/AuthContext";
import DataTable from "datatables.net-react";
import DT from "datatables.net-dt";
import {
  deleteWorkshop,
  getWorkshopReports,
  updateWorkshop,
} from "../../services/firebase_service";
import { toast } from "react-toastify";

DataTable.use(DT);

function WorkshopList() {
  const { user, workshops, setWorkshops } = useAuth();
  const [loading, setLoading] = useState(true);
  const [tableData, setTableData] = useState([]);
  const [activeFilter, setActiveFilter] = useState(null);
  const [reportCounts, setReportCounts] = useState({});
  const navigate = useNavigate();

  const tableOptions = {
    order: [[2, "asc"]],
    paging: true,
    searching: true,
    lengthMenu: [10, 25, 50, 75, 100],
    language: {
      emptyTable: "No workshops available to display",
    },
    columnDefs: [
      {
        targets: [0, 1],
        visible: false,
      },
      {
        targets: [2],
        render: function (data, type, row) {
          return data
            ? `<div class="d-flex"><img src="${
                row[1]
              }" class="rounded-circle cursor-pointer me-2 object-fit-contain bg-white" width="40" height="40" onClick="editWorkshop('${
                row[0]
              }')" /><div class="desc"><span class="text-primary text-body fw-bold underLink" style="cursor:pointer;" onClick="editWorkshop('${
                row[0]
              }')">${data.split(",")[0]}</span><br/><small class="text-muted">${
                data.split(",")[1]
              }</small></div></div>`
            : ``;
        },
      },
      {
        targets: [3],
        render: function (data, type, row) {
          if (!data) return "";
          const categories = data.split(", ");
          return `<div class="d-flex gap-1 flex-wrap workshopCategories">
            ${categories
              .map(
                (category) =>
                  `<span class="badge bg-primary text-secondary cursor-pointer" onclick="window.filterByCategory('${category}')">${category
                    .split(" ")
                    .map(
                      (word) =>
                        word.charAt(0).toUpperCase() +
                        word.slice(1).toLowerCase()
                    )
                    .join(" ")}</span>`
              )
              .join("")}
          </div>`;
        },
      },
      {
        targets: [5],
        sortable: false,
        render: function (data, type, row) {
          return `<div class="form-check form-switch py-2">
                    <input class="form-check-input cursor-pointer" type="checkbox" id="status${
                      row[0]
                    }" ${
            data ? "checked" : ""
          } onChange="window.updateFeaturedStatus('${row[0]}')">
                  </div>`;
        },
      },
      {
        targets: [6],
        sortable: false,
        render: function (data, type, row) {
          return `<div class="form-check form-switch py-2">
                    <input class="form-check-input cursor-pointer" type="checkbox" id="status${
                      row[0]
                    }" ${
            data ? "checked" : ""
          } onChange="window.updateVerifiedStatus('${row[0]}')">
                  </div>`;
        },
      },
      {
        targets: [7],
        sortable: false,
        render: function (data, type, row) {
          return `<div class="form-check form-switch py-2">
                    <input class="form-check-input cursor-pointer" type="checkbox" id="status${
                      row[0]
                    }" ${
            data ? "checked" : ""
          } onChange="window.updateStatus('${row[0]}')">
                  </div>`;
        },
      },
      {
        targets: [8],
        render: function (data, type, row) {
          return data > 0 
            ? `<span class="badge rounded-pill bg-danger">${data}</span>`
            : '<span class="badge rounded-pill bg-secondary">0</span>';
        },
      },
      {
        targets: [9],
        sortable: false,
        render: function (data, type, row) {
          return `<div class="dropdown">
                    <button type="button" class="btn p-0 dropdown-toggle hide-arrow" data-bs-toggle="dropdown" aria-expanded="false">
                      <i class="ti ti-dots-vertical"></i>
                    </button>
                    <div class="dropdown-menu" style="">
                      <a class="dropdown-item waves-effect cursor-pointer" onClick="editWorkshop('${row[0]}')"><i class="ti ti-pencil me-1"></i> Edit</a>
                      <a class="dropdown-item waves-effect cursor-pointer" onClick="deleteWorkshop('${row[0]}')"><i class="ti ti-trash me-1"></i> Delete</a>
                    </div>
                  </div>`;
        },
      },
      
    ],
  };

  useEffect(() => {
    const fetchReportCounts = async () => {
      const counts = {};
      for (const workshop of workshops) {
        const reportDocs = await getWorkshopReports(workshop.id);
        counts[workshop.id] = reportDocs.docs.filter(doc => !doc.data().doneAt).length;
      }
      setReportCounts(counts);
    };

    if (workshops.length > 0) {
      fetchReportCounts();
    }
  }, [workshops]);

  useEffect(() => {
    let filteredWorkshops = workshops;
    if (activeFilter) {
      filteredWorkshops = workshops.filter((workshop) =>
        workshop.categories.some(
          (category) => category.toLowerCase() === activeFilter.toLowerCase()
        )
      );
    }

    setTableData(
      filteredWorkshops.map((workshop) => {
        const content =
          workshop.description !== null && workshop.description !== ""
            ? workshop.description.replace(/<\/?[^>]+(>|$)/g, "")
            : "";

        return [
          workshop.id,
          workshop.logoUrl ?? "https://via.placeholder.com/150",
          `${workshop.name},${content.substring(0, 50)}` ?? "",
          workshop.categories.map((category) => category).join(", ") ?? "",
          workshop.location !== null && workshop.location !== ""
            ? workshop.location.province
            : "",
          workshop.featured ?? "",
          workshop.verified ?? "",
          workshop.published ?? false,
          reportCounts[workshop.id] || 0,
          workshop.id,
        ];
      })
    );
    setLoading(false);
  }, [workshops, activeFilter, reportCounts]);

  useEffect(() => {
    window.updateStatus = async (id) => {
      const workshop = workshops.find((workshop) => workshop.id === id);
      workshop.published = !workshop.published;

      try {
        await updateWorkshop(id, workshop);
        const updatedWorkshops = workshops.map((workshop) =>
          workshop.id === id ? workshop : workshop
        );
        setWorkshops(updatedWorkshops);
        if (workshop.published) {
          toast.success("Workshop published!");
        } else {
          toast.success("Workshop unpublished!");
        }
      } catch (error) {
        toast.error("Error: Unable to update Workshop status");
        console.error("Error updating Workshop status", error);
      }
    };

    window.updateFeaturedStatus = async (id) => {
      const workshop = workshops.find((e) => e.id === id);
      workshop.featured = !workshop.featured;

      try {
        await updateWorkshop(id, workshop);
        const updatedWorkshops = workshops.map((e) =>
          e.id === id ? workshop : e
        );
        setWorkshops(updatedWorkshops);
        if (workshop.featured) {
          toast.success("Workshop featured!");
        } else {
          toast.success("Workshop unfeatured!");
        }
      } catch (error) {
        toast.error("Error: Unable to update Workshop status");
        console.error("Error updating Workshop status", error);
      }
    };

    window.updateVerifiedStatus = async (id) => {
      const workshop = workshops.find((e) => e.id === id);
      workshop.verified = !workshop.verified;

      try {
        await updateWorkshop(id, workshop);
        const updatedWorkshops = workshops.map((e) =>
          e.id === id ? workshop : e
        );
        setWorkshops(updatedWorkshops);
        if (workshop.verified) {
          toast.success("Workshop verified!");
        } else {
          toast.success("Workshop unverified!");
        }
      } catch (error) {
        toast.error("Error: Unable to update Workshop status");
        console.error("Error updating Workshop status", error);
      }
    };

    window.editWorkshop = (id) => {
      navigate(`/workshops/${id}/edit`);
    };

    window.deleteWorkshop = (id) => {
      if (window.confirm("Are you sure you want to delete this workshop?")) {
        // Call the delete function
        deleteWorkshop(id)
          .then(() => {
            // Remove the deleted workshop from the list
            const updatedWorkshops = workshops.filter((shop) => shop.id !== id);
            setWorkshops(updatedWorkshops);
            toast.success("Workshop deleted successfully");
          })
          .catch((error) => {
            toast.error("Error deleting Workshop");
            console.error("Error deleting Workshop", error);
          });
      }
    };

    window.filterByCategory = (category) => {
      setActiveFilter((prev) => (prev === category ? null : category));
    };

    // Clean up the functions when the component unmounts
    return () => {
      delete window.updateStatus;
      delete window.editWorkshop;
      delete window.deleteWorkshop;
      delete window.updateFeaturedStatus;
      delete window.updateVerifiedStatus;
      delete window.filterByCategory;
    };
  }, [navigate, workshops, setWorkshops]);

  return (
    <div>
      <Announcement />
      <div className="row">
        <div className="col-xl-12">
          <div className="card">
            <div className="card-header bg-transparent">
              <div className="bg-transparent d-flex align-items-center justify-content-between mb-3">
                <div className="left">
                  <h4 className="">Workshops</h4>
                  <p className="mb-0">
                    Manage and view all workshops <br />
                    <br />
                    {activeFilter && (
                      <span className="d-flex align-items-center">
                        Filtered by:{" "}
                        <span className="ms-2 badge bg-primary text-secondary">
                          {activeFilter.charAt(0).toUpperCase() +
                            activeFilter.slice(1).toLowerCase()}
                        </span>
                        <button
                          className="btn btn-dark btn-sm ms-2"
                          onClick={() => setActiveFilter(null)}
                        >
                          <i className="ti ti-x"></i> Clear
                        </button>
                      </span>
                    )}
                  </p>
                </div>
                <div className="right">
                  <Tooltip id="tooltopBottom" place="left" />
                  {!user.emailVerified ? (
                    <div
                      data-tooltip-id="tooltopBottom"
                      data-tooltip-content="You need to verify your email address first"
                      className="cursor-not-allowed"
                    >
                      <button className="btn btn-primary" disabled>
                        <i className="ti ti-plus"></i> New
                      </button>
                    </div>
                  ) : (
                    <Link to="/workshops/new" className="btn btn-primary">
                      <i className="ti ti-plus"></i> New
                    </Link>
                  )}
                  <Tooltip id="disabledBtn" />
                </div>
              </div>
            </div>
            <div className="card-body pt-0">
              <div className="table-responsive rounded-4" data-bs-theme="dark">
                {!loading ? (
                  <DataTable
                    className="table text-nowrap usersTable table-dark mb-0 align-middle"
                    options={tableOptions}
                    data={tableData}
                  >
                    <thead>
                      <tr>
                        <th className="fs-4 fw-semibold mb-0">ID</th>
                        <th className="fs-4 fw-semibold mb-0">Image</th>
                        <th className="fs-4 fw-semibold mb-0">Title</th>
                        <th className="fs-4 fw-semibold mb-0">Categories</th>
                        <th className="fs-4 fw-semibold mb-0">Province</th>
                        <th className="fs-4 fw-semibold mb-0">Featured</th>
                        <th className="fs-4 fw-semibold mb-0">Verified</th>
                        <th className="fs-4 fw-semibold mb-0">Published</th>
                        <th className="fs-4 fw-semibold mb-0">Reports</th>
                        <th>&nbsp;</th>
                      </tr>
                    </thead>
                  </DataTable>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default WorkshopList;
