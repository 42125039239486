import React from "react";
import Dashboard from "./features/dashboard/dashboard";
import { Route, Routes } from "react-router-dom";
import ProtectedRoute from "./services/ProtectedRoute";
import Users from "./features/users/index/users";
import User from "./features/users/single/user";
import Events from "./features/events/list/events";
import Register from "./features/auth/register/register";
import ForgotPassword from "./features/auth/forgotPassword/forgotPassword";
import { useAuth } from "./stores/AuthContext";
import NotFound from "./features/404/404";
import NewEvent from "./features/events/new/new";
import EmailVerificationProtectedRoute from "./services/EmailVerificationProtected";
import EditEvent from "./features/events/edit/edit";
import EditUser from "./features/users/edit/edit";
import Login from "./features/auth/Login";
import RidingSpotList from "./features/riding-spots/list";
import EditRidingSpot from "./features/riding-spots/edit";
import NewRidingSpot from "./features/riding-spots/new/new";
import WorkshopList from "./features/workshop/list";
import EditWorkshop from "./features/workshop/edit";
import NewWorkshop from "./features/workshop/new";
import Products from "./features/products";
import EditProduct from "./features/products/edit";
import NewProduct from "./features/products/new";
import ReportList from "./features/reports/list";
import EventEntries from "./features/events/entries";
import Notifications from "./features/notifications";
import NewNotification from "./features/notifications/new";
import GlobalEntries from "./features/entries/GlobalEntries";
import RequestFeature from "./features/support/RequestFeature";
import Support from "./features/support/support";

const AppRoutes = () => {
  const { userDetails } = useAuth();
  return (
    <Routes>
      {/* Public Routes */}
      <Route path="/login" element={<Login />} />
      <Route path="/register" element={<Register />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />

      {/* General Protected Routes */}
      <Route element={<ProtectedRoute />}>
        <Route path="/" element={<Dashboard />} />

        <Route path="/products" element={<Products />} />
        <Route path="/product/:id/edit" element={<EditProduct />} />
        <Route path="/product/new" element={<NewProduct />} />

        <Route path="/events" element={<Events />} />
        <Route element={<EmailVerificationProtectedRoute />}>
          <Route path="/event/new" element={<NewEvent />} />
          <Route path="/event/:id/edit" element={<EditEvent />} />
          <Route path="/event/:id/entries" element={<EventEntries />} />
        </Route>

        <Route path="/entries" element={<GlobalEntries />} />
        <Route path="/support/feature-request" element={<RequestFeature />} />
        <Route path="/support/request" element={<Support />} />

      </Route>

      {/* Admin Protected Routes */}
      {userDetails && userDetails.role === "admin" && (
        <Route element={<ProtectedRoute />}>
          <Route path="/users" element={<Users />} />
          <Route path="/user/:id" element={<User />} />
          <Route path="/user/:id/edit" element={<EditUser />} />

          <Route path="/notifications" element={<Notifications />} />
          <Route path="/notifications/new" element={<NewNotification />} />

          <Route path="/riding-spots" element={<RidingSpotList />} />
          <Route path="/riding-spots/:id/edit" element={<EditRidingSpot />} />
          <Route path="/riding-spots/new" element={<NewRidingSpot />} />

          <Route path="/workshops" element={<WorkshopList />} />
          <Route path="/workshops/:id/edit" element={<EditWorkshop />} />
          <Route path="/workshops/new" element={<NewWorkshop />} />

          <Route path="/reports" element={<ReportList />} />
        </Route>
      )}

      {/* Fallback Route for 404 */}
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default AppRoutes;
