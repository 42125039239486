import React, { useEffect, useState } from "react";
import { globalSettings } from "../../../stores/GlobalContext";
import { Link, useNavigate } from "react-router-dom";
import "../login.css";
import { useAuth } from "../../../stores/AuthContext";
import AppLogo from "../../../components/logo";

function Register() {
  document.querySelector("title").innerHTML =
    "Register Organiser | " + globalSettings.appName;

  const [organiserName, setOrganiserName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [province, setProvince] = useState("");

  const [error, setError] = useState(null);
  const [pwReqError, setPwReqError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const { register, isAuthenticated } = useAuth();

  function passwordObscure() {
    const passwordInput = document.getElementById("password");
    const passwordIcon = document.querySelector(".password-view-toggle i");
    passwordInput.type =
      passwordInput.type === "password" ? "text" : "password";
    // chanage icon based on password visibility
    passwordIcon.classList.toggle("fa-eye");
    passwordIcon.classList.toggle("fa-eye-slash");
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      setLoading(true);
      const response = await register(email, password, organiserName, province);


      if (organiserName === "" || email === "" || password === "") {
        setError("Please fill in all fields");
        setLoading(false);
        return;
      } else if (province === "") {
        setError("Please select a province");
        setLoading(false);
        return;
      } else if (!response.success) {
        if (
          response.message.includes("auth/password-does-not-meet-requirements")
        ) {
          setError(
            "Your password does not meet the minimum requirements. Your password should;"
          );
          setPwReqError([
            "Be at least 8 characters long",
            "Contain at least one uppercase letter",
            "Contain at least one lowercase letter",
            "Contain at least one number",
            "Contain at least one special character",
          ]);
        } else {
          setError(response.message);
        }
        setLoading(false);
        return;
      } else {
        // Clear form fields
        setOrganiserName("");
        setEmail("");
        setPassword("");
        setProvince("");

        // Changed from navigate("/register") to just showing success message
        // The user should then manually go to login page
        setLoading(false);
        setSuccess(response.message);
      }
    } catch (error) {
      setError(error.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    document.getElementsByTagName("body")[0].classList.add("loginPage");
    document.getElementsByTagName("body")[0].classList.add("d-flex");
    document
      .getElementsByTagName("body")[0]
      .classList.add("align-items-center");
    document.getElementsByTagName("body")[0].classList.add("bg-body-tertiary");

    if (isAuthenticated) {
      document.getElementsByTagName("body")[0].classList.remove("loginPage");
      navigate("/");
    }
  });

  return (
    <div className="row justify-content-center w-100">
      <div className="col-md-8 col-lg-6 col-xxl-3 auth-card">
        <div className="card mb-0">
          <div className="card-body">
            <Link
              to="/"
              className="text-nowrap logo-img text-center d-block w-100"
            >
              <AppLogo />
            </Link>
            <div className="position-relative text-center my-4">
              <p
                className="mb-0 fs-4 px-3 d-inline-block z-index-5 position-relative"
                style={{ background: "#202936" }}
              >
                Sign Up
              </p>
              <span className="border-top w-100 position-absolute top-50 start-50 translate-middle"></span>
            </div>
            {success && (
              <div className="alert alert-success" role="alert">
                <strong>Success!</strong> {success}
              </div>
            )}
            {!success ? (
              <form id="registerForm" onSubmit={handleSubmit}>
                {error && (
                  <div className="alert alert-danger" role="alert">
                    <strong>Oops!</strong> {error}
                    {pwReqError ? (
                      <ul className="list-style">
                        {pwReqError.map((req, index) => (
                          <li key={index}>{req}</li>
                        ))}
                      </ul>
                    ) : null}
                  </div>
                )}
                <div className="mb-3">
                  <label htmlFor="organiserName" className="form-label">
                    Organiser Name
                  </label>
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      id="organiserName"
                      name="organiserName"
                      value={organiserName}
                      onChange={(e) => setOrganiserName(e.target.value)}
                      required
                    />
                    <small
                      id="name"
                      className="form-text text-muted opacity-50"
                    >
                      This is the name that will be displayed to app users when
                      they visit your events.
                    </small>
                  </div>
                </div>
                <div className="mb-3">
                  <label htmlFor="province" className="form-label">
                    Province
                  </label>
                  <select
                    className="form-select"
                    id="province"
                    name="province"
                    value={province}
                    onChange={(e) => setProvince(e.target.value)}
                    required
                  >
                    <option value="" disabled>Select a province</option>
                    <option value="All">All</option>
                    <option value="Eastern Cape">Eastern Cape</option>
                    <option value="Free State">Free State</option>
                    <option value="Gauteng">Gauteng</option>
                    <option value="KwaZulu-Natal">KwaZulu-Natal</option>
                    <option value="Limpopo">Limpopo</option>
                    <option value="Mpumalanga">Mpumalanga</option>
                    <option value="Northern Cape">Northern Cape</option>
                    <option value="North West">North West</option>
                    <option value="Western Cape">Western Cape</option>
                  </select>
                </div>
                <div className="mb-3">
                  <label htmlFor="email" className="form-label">
                    Email Address
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    id="email"
                    name="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                </div>
                <label htmlFor="password" className="form-label">
                  Password
                </label>
                <div className="input-group mb-4">
                  <input
                    type="password"
                    className="form-control"
                    id="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                  />
                  <span
                    className="password-view-toggle input-group-text cursor-pointer"
                    onClick={passwordObscure}
                  >
                    <i className="fa fa-eye"></i>
                  </span>
                </div>
                <button
                  type="submit"
                  className="btn btn-primary w-100 py-8 mb-4 d-flex align-items-center justify-content-center"
                  disabled={loading}
                >
                  {loading ? (
                    <div className="spin me-2">
                      <i className="ti ti-loader"></i>
                    </div>
                  ) : null}
                  Sign Up
                </button>
                <div className="d-flex align-items-center">
                  <p className="fs-4 mb-0 text-dark">
                    Already have an Account?
                  </p>
                  <Link className="text-primary fw-medium ms-2" to="/login">
                    Sign In
                  </Link>
                </div>
              </form>
            ) : (
              <Link
                to="/login"
                className="btn bg-primary-subtle text-primary w-100 py-8"
              >
                Go to Login
              </Link>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Register;
