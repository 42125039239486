import React, { useEffect, useState } from "react";
import { useAuth } from "../../stores/AuthContext";
import { Tooltip } from "react-tooltip";
import CustomLineChart from "../../components/line_chart";
import { formatDistanceToNow } from "date-fns";
import { useNavigate } from "react-router-dom";

const AdminDashboard = () => {
  const [latestUsers, setLatestUsers] = useState([]);
  const [usersThisMonth, setUsersThisMonth] = useState(0);
  const [usersLastMonth, setUsersLastMonth] = useState(0);
  const [usersDifference, setUsersDifference] = useState(0);

  const [eventsThisMonth, setEventsThisMonth] = useState(0);
  const [eventsLastMonth, setEventsLastMonth] = useState(0);
  const [eventsDifference, setEventsDifference] = useState(0);

  const [workshopsThisMonth, setWorkshopsThisMonth] = useState(0);
  const [workshopsLastMonth, setWorkshopsLastMonth] = useState(0);
  const [workshopsDifference, setWorkshopsDifference] = useState(0);

  const [spotsThisMonth, setSpotsThisMonth] = useState(0);
  const [spotsLastMonth, setSpotsLastMonth] = useState(0);
  const [spotsDifference, setSpotsDifference] = useState(0);

  const [usersStatsData, setUsersStatsData] = useState([]);
  const [eventsStatsData, setEventsStatsData] = useState([]);

  const { users, events, ridingSpots, workshops } = useAuth();
  const [isLoaded, setIsLoaded] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    if (!users || !events) {
      return;
    }

    users.sort((a, b) => b.createdAt.seconds - a.createdAt.seconds);
    setLatestUsers(users.slice(0, 5));

    // Create monthly stats for line charts
    const generateMonthlyStats = () => {
      const months = Array.from({ length: 12 }, (_, i) => {
        const d = new Date();
        d.setMonth(d.getMonth() - i);
        return {
          month: d.getMonth(),
          year: d.getFullYear(),
          name: d.toLocaleString("default", { month: "short" }),
          users: 0,
          events: 0,
        };
      }).reverse();

      // Count users per month
      Object.values(users).forEach((user) => {
        const userDate = new Date(user.createdAt.seconds * 1000);
        const userMonth = userDate.getMonth();
        const userYear = userDate.getFullYear();

        const monthIndex = months.findIndex(
          (m) => m.month === userMonth && m.year === userYear
        );

        if (monthIndex !== -1) {
          months[monthIndex].users++;
        }
      });

      // Count events per month
      Object.values(events).forEach((event) => {
        const eventDate = new Date(event.date.seconds * 1000);
        const eventMonth = eventDate.getMonth();
        const eventYear = eventDate.getFullYear();

        const monthIndex = months.findIndex(
          (m) => m.month === eventMonth && m.year === eventYear
        );

        if (monthIndex !== -1) {
          months[monthIndex].events++;
        }
      });

      // Create separate arrays for users and events stats
      const usersStats = months.map((m) => ({
        name: m.name,
        users: m.users,
        pv: 0,
        amt: 0,
      }));

      const eventsStats = months.map((m) => ({
        name: m.name,
        events: m.events,
        pv: 0,
        amt: 0,
      }));

      return { usersStats, eventsStats };
    };

    const { usersStats, eventsStats } = generateMonthlyStats();
    setUsersStatsData(usersStats);
    setEventsStatsData(eventsStats);

    let thisMonthUsers = 0;
    let lastMonthUsers = 0;
    let thisMonthEvents = 0;
    let lastMonthEvents = 0;
    let thisMonthWorkshops = 0;
    let lastMonthWorkshops = 0;
    let thisMonthSpots = 0;
    let lastMonthSpots = 0;

    const currentDate = new Date();
    const currentMonth = currentDate.getMonth();
    const currentYear = currentDate.getFullYear();

    const lastMonth = currentMonth === 0 ? 11 : currentMonth - 1;
    const lastMonthYear = currentMonth === 0 ? currentYear - 1 : currentYear;

    // Calculate user stats
    Object.values(users).forEach((user) => {
      const userDate = new Date(user.createdAt.seconds * 1000);
      const userMonth = userDate.getMonth();
      const userYear = userDate.getFullYear();

      if (userMonth === currentMonth && userYear === currentYear) {
        thisMonthUsers++;
      } else if (userMonth === lastMonth && userYear === lastMonthYear) {
        lastMonthUsers++;
      }
    });

    // Calculate event stats
    Object.values(events).forEach((event) => {
      const eventDate = new Date(event.date.seconds * 1000);
      const eventMonth = eventDate.getMonth();
      const eventYear = eventDate.getFullYear();

      if (eventMonth === currentMonth && eventYear === currentYear) {
        thisMonthEvents++;
      } else if (eventMonth === lastMonth && eventYear === lastMonthYear) {
        lastMonthEvents++;
      }
    });

    // Calculate workshop stats
    Object.values(workshops).forEach((workshop) => {
      if (!workshop.createdAt) {
        return;
      }
      const workshopDate = new Date(workshop.createdAt.seconds * 1000);
      const workshopMonth = workshopDate.getMonth();
      const workshopYear = workshopDate.getFullYear();

      if (workshopMonth === currentMonth && workshopYear === currentYear) {
        thisMonthWorkshops++;
      } else if (
        workshopMonth === lastMonth &&
        workshopYear === lastMonthYear
      ) {
        lastMonthWorkshops++;
      }
    });

    // Calculate riding spots stats
    Object.values(ridingSpots).forEach((spot) => {
      if (!spot.createdAt) {
        return;
      }
      const spotDate = new Date(spot.createdAt.seconds * 1000);
      const spotMonth = spotDate.getMonth();
      const spotYear = spotDate.getFullYear();

      if (spotMonth === currentMonth && spotYear === currentYear) {
        thisMonthSpots++;
      } else if (spotMonth === lastMonth && spotYear === lastMonthYear) {
        lastMonthSpots++;
      }
    });

    // Update states
    setUsersThisMonth(thisMonthUsers);
    setUsersLastMonth(lastMonthUsers);
    setEventsThisMonth(thisMonthEvents);
    setEventsLastMonth(lastMonthEvents);
    setWorkshopsThisMonth(thisMonthWorkshops);
    setWorkshopsLastMonth(lastMonthWorkshops);
    setSpotsThisMonth(thisMonthSpots);
    setSpotsLastMonth(lastMonthSpots);

    // Calculate differences
    const usersDiff =
      lastMonthUsers === 0
        ? 0
        : ((thisMonthUsers - lastMonthUsers) / lastMonthUsers) * 100;
    const eventsDiff =
      lastMonthEvents === 0
        ? 0
        : ((thisMonthEvents - lastMonthEvents) / lastMonthEvents) * 100;
    const workshopsDiff =
      lastMonthWorkshops === 0
        ? 0
        : ((thisMonthWorkshops - lastMonthWorkshops) / lastMonthWorkshops) *
          100;
    const spotsDiff =
      lastMonthSpots === 0
        ? 0
        : ((thisMonthSpots - lastMonthSpots) / lastMonthSpots) * 100;

    setUsersDifference(Math.round(usersDiff));
    setEventsDifference(Math.round(eventsDiff));
    setWorkshopsDifference(Math.round(workshopsDiff));
    setSpotsDifference(Math.round(spotsDiff));
    setIsLoaded(true);
  }, [users, events, ridingSpots, workshops]);

  const handleRowClick = (id) => {
    navigate(`/user/${id}`);
  };

  return (
    <>
      <div className="row">
        <div className="col-sm-3 d-flex align-items-stretch">
          <div className="card w-100">
            <div className="card-body pb-0 mb-xxl-4 pb-1">
              <p className="mb-1 fs-3">New Users</p>
              <h4 className="fw-semibold fs-7">{usersThisMonth}</h4>
              <div className="d-flex align-items-center mb-3">
                {Math.abs(usersDifference) < 0.1 ? (
                  <span className="me-1 rounded-circle bg-warning-subtle round-20 d-flex align-items-center justify-content-center">
                    <i className="ti ti-minus text-warning"></i>
                  </span>
                ) : usersDifference > 0 ? (
                  <span className="me-1 rounded-circle bg-success-subtle round-20 d-flex align-items-center justify-content-center">
                    <i className="ti ti-arrow-up-left text-success"></i>
                  </span>
                ) : (
                  <span className="me-1 rounded-circle bg-danger-subtle round-20 d-flex align-items-center justify-content-center">
                    <i className="ti ti-arrow-down-right text-danger"></i>
                  </span>
                )}
                <p
                  className="text-dark fs-3 mb-0"
                  data-tooltip-id="last-months-users"
                  data-tooltip-content={`Last month: ${usersLastMonth} users`}
                  title={`Last month: ${usersLastMonth} users`}
                >
                  <Tooltip
                    id="last-months-users"
                    place="right"
                    effect="solid"
                    type="dark"
                    backgroundColor="#000"
                  ></Tooltip>
                  {Math.abs(usersDifference) ?? 0}%
                </p>
              </div>
            </div>
            <div id="customers"></div>
          </div>
        </div>
        {/* <!-- Events --> */}
        <div className="col-sm-3 d-flex align-items-stretch">
          <div className="card w-100">
            <div className="card-body">
              <p className="mb-1 fs-3">Events</p>
              <h4 className="fw-semibold fs-7">{eventsThisMonth}</h4>
              <div className="d-flex align-items-center mb-3">
                {Math.abs(eventsDifference) < 0.1 ? (
                  <span className="me-1 rounded-circle bg-warning-subtle round-20 d-flex align-items-center justify-content-center">
                    <i className="ti ti-minus text-warning"></i>
                  </span>
                ) : eventsDifference > 0 ? (
                  <span className="me-1 rounded-circle bg-success-subtle round-20 d-flex align-items-center justify-content-center">
                    <i className="ti ti-arrow-up-left text-success"></i>
                  </span>
                ) : (
                  <span className="me-1 rounded-circle bg-danger-subtle round-20 d-flex align-items-center justify-content-center">
                    <i className="ti ti-arrow-down-right text-danger"></i>
                  </span>
                )}
                <p
                  className="text-dark fs-3 mb-0"
                  data-tooltip-id="last-months-events"
                  data-tooltip-content={`Last month: ${eventsLastMonth} events`}
                  title={`Last month: ${eventsLastMonth} events`}
                >
                  <Tooltip
                    id="last-months-events"
                    place="right"
                    effect="solid"
                    type="dark"
                    backgroundColor="#000"
                  ></Tooltip>
                  {Math.abs(eventsDifference) ?? 0}%
                </p>
              </div>
              <div id="projects"></div>
            </div>
          </div>
        </div>
        {/* <!-- Workshops --> */}
        <div className="col-sm-3 d-flex align-items-stretch">
          <div className="card w-100">
            <div className="card-body">
              <p className="mb-1 fs-3">Workshops</p>
              <h4 className="fw-semibold fs-7">{workshopsThisMonth}</h4>
              <div className="d-flex align-items-center mb-3">
                {Math.abs(workshopsDifference) < 0.1 ? (
                  <span className="me-1 rounded-circle bg-warning-subtle round-20 d-flex align-items-center justify-content-center">
                    <i className="ti ti-minus text-warning"></i>
                  </span>
                ) : workshopsDifference > 0 ? (
                  <span className="me-1 rounded-circle bg-success-subtle round-20 d-flex align-items-center justify-content-center">
                    <i className="ti ti-arrow-up-left text-success"></i>
                  </span>
                ) : (
                  <span className="me-1 rounded-circle bg-danger-subtle round-20 d-flex align-items-center justify-content-center">
                    <i className="ti ti-arrow-down-right text-danger"></i>
                  </span>
                )}
                <p
                  className="text-dark fs-3 mb-0"
                  data-tooltip-id="last-months-workshops"
                  data-tooltip-content={`Last month: ${workshopsLastMonth} workshops`}
                >
                  <Tooltip
                    id="last-months-workshops"
                    place="right"
                    effect="solid"
                    type="dark"
                    backgroundColor="#000"
                  ></Tooltip>
                  {Math.abs(workshopsDifference) ?? 0}%
                </p>
              </div>
              <div id="workshops"></div>
            </div>
          </div>
        </div>
        {/* <!-- Riding Spots --> */}
        <div className="col-sm-3 d-flex align-items-stretch">
          <div className="card w-100">
            <div
              className="card-body cursor-pointer"
              onClick={() => navigate("/riding-spots")}
            >
              <p className="mb-1 fs-3">Riding Spots</p>
              <h4 className="fw-semibold fs-7">{spotsThisMonth}</h4>
              <div className="d-flex align-items-center mb-3">
                {Math.abs(spotsDifference) < 0.1 ? (
                  <span className="me-1 rounded-circle bg-warning-subtle round-20 d-flex align-items-center justify-content-center">
                    <i className="ti ti-minus text-warning"></i>
                  </span>
                ) : spotsDifference > 0 ? (
                  <span className="me-1 rounded-circle bg-success-subtle round-20 d-flex align-items-center justify-content-center">
                    <i className="ti ti-arrow-up-left text-success"></i>
                  </span>
                ) : (
                  <span className="me-1 rounded-circle bg-danger-subtle round-20 d-flex align-items-center justify-content-center">
                    <i className="ti ti-arrow-down-right text-danger"></i>
                  </span>
                )}
                <p
                  className="text-dark fs-3 mb-0"
                  data-tooltip-id="last-months-spots"
                  data-tooltip-content={`Last month: ${spotsLastMonth} spots`}
                >
                  <Tooltip
                    id="last-months-spots"
                    place="right"
                    effect="solid"
                    type="dark"
                    backgroundColor="#000"
                  ></Tooltip>
                  {Math.abs(spotsDifference) ?? 0}%
                </p>
              </div>
              <div id="spots"></div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-6 d-flex align-items-stretch">
          <div className="card w-100">
            <div className="card-body">
              <div className="d-sm-flex d-block align-items-center justify-content-between mb-9">
                <div className="mb-3 mb-sm-0">
                  <h4 className="card-title fw-semibold">New Users</h4>
                  <p className="card-subtitle mb-0">
                    User registrations by month (last 12 months)
                  </p>
                </div>
              </div>
              <div
                className="row align-items-center"
                style={{ minHeight: "300px" }}
              >
                <CustomLineChart label="users" data={usersStatsData} />
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-6 d-flex align-items-stretch">
          <div className="card w-100">
            <div className="card-body">
              <div className="d-sm-flex d-block align-items-center justify-content-between mb-9">
                <div className="mb-3 mb-sm-0">
                  <h4 className="card-title fw-semibold">New Events</h4>
                  <p className="card-subtitle mb-0">
                    Events by month (last 12 months)
                  </p>
                </div>
              </div>
              <div
                className="row align-items-center"
                style={{ minHeight: "300px" }}
              >
                <CustomLineChart label="events" data={eventsStatsData} />
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-12 d-flex align-items-stretch">
          <div className="card w-100">
            <div className="card-body">
              <div className="d-sm-flex d-block align-items-center justify-content-between mb-7">
                <div className="mb-3 mb-sm-0">
                  <h4 className="card-title fw-semibold">Newest Users</h4>
                  <p className="card-subtitle">Recent Registrations</p>
                </div>
              </div>
              <div className="table-responsive">
                <table className="table align-middle text-nowrap mb-0">
                  <thead>
                    <tr className="text-muted fw-semibold">
                      <th scope="col" className="ps-0">
                        Assigned
                      </th>
                      <th scope="col">Last Login</th>
                      <th scope="col">Created At</th>
                    </tr>
                  </thead>
                  <tbody className="border-top">
                    {latestUsers.map((user, index) => (
                      <tr
                        key={`${index}User${user.uid}`}
                        className="cursor-pointer"
                        onClick={() => handleRowClick(user.uid)}
                      >
                        <td className="ps-0">
                          <div className="d-flex align-items-center">
                            <div className="me-2 pe-1">
                              <img
                                src={
                                  user.photoURL ??
                                  `https://ui-avatars.com/api/?name=${user.firstName}+${user.lastName}&background=random&color=fff`
                                }
                                className="rounded-circle cursor-pointer"
                                width="40"
                                height="40"
                                alt={`${user.firstName}+${user.lastName}`}
                              />
                            </div>
                            <div>
                              <h6 className="fw-semibold mb-1">
                                {user.firstName} {user.lastName}
                              </h6>
                              <p className="fs-2 mb-0 text-muted">
                                <a
                                  href={`mailto:${user.email}`}
                                  target="_blank"
                                  rel="noreferrer"
                                  className="underLink"
                                >
                                  {user.email}
                                </a>
                              </p>
                            </div>
                          </div>
                        </td>
                        <td>
                          <p className="mb-0 fw-normal fs-4">
                            {user.lastLogin?.seconds
                              ? formatDistanceToNow(
                                  new Date(user.lastLogin.seconds * 1000),
                                  {
                                    addSuffix: true,
                                  }
                                )
                              : "Never"}
                          </p>
                        </td>
                        <td>
                          <p className="mb-0 fw-normal fs-4">
                            {new Date(
                              user.createdAt.seconds * 1000
                            ).toLocaleString("en-GB", {
                              day: "numeric",
                              month: "short",
                              year: "numeric",
                              hour: "2-digit",
                              minute: "2-digit",
                            })}
                          </p>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminDashboard;
