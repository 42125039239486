import React, { useState, useEffect } from "react";
import { useAuth } from "../../stores/AuthContext";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  notifySingleUser,
  notifyMultipleUsers,
} from "../../services/firebase_service";
import "./notificationStyles.css";
import Select from "react-select";

function NewNotification() {
  const { users, events } = useAuth();
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    title: "",
    body: "",
    userId: "",
    message: {
      data: {},
      clickAction: "",
    },
    targetId: "",
    selectedEvent: null,
    notificationType: "single", // Default to single user
    province: "", // For province-based notifications
  });

  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [actionTypes] = useState([
    { id: "GENERAL_NOTIFICATION", name: "General Notification" },
    { id: "SINGLE_EVENT", name: "Single Event" },
    { id: "SINGLE_PRODUCT", name: "Single Product" },
    { id: "PROFILE_UPDATE", name: "Profile Update" },
  ]);

  // Add provinces list
  const [provinces] = useState([
    "All",
    "Eastern Cape",
    "Free State",
    "Gauteng",
    "KwaZulu-Natal",
    "Limpopo",
    "Mpumalanga",
    "Northern Cape",
    "North West",
    "Western Cape",
  ]);

  const [userOptions, setUserOptions] = useState([]);
  const [usersInAll, setUsersInAll] = useState([]);
  const [usersCount, setUsersCount] = useState(0);
  const [eventOptions, setEventOptions] = useState([]);

  useEffect(() => {
    if (users && users.length > 0) {
      const options = users.map((user) => ({
        value: user.uid,
        label: `${user.firstName || "Unnamed"} ${user.lastName || "user"} (${
          user.email || "No email"
        })`,
        user: user,
      }));
      setUserOptions(options);
      
      // Update the user counts whenever the province or users change
      updateUsersInProvince();
    }
  }, [users, formData.province]);
  
  // New function to update province user counts
  const updateUsersInProvince = () => {
    if (!users || !formData.province) return;
    
    // Filter users who have the selected province OR have "All" as their preferred province
    const provinceSpecificUsers = users.filter(
      user => user.settings && user.settings.preferedProvince === formData.province
    );
    
    const allProvinceUsers = users.filter(
      user => user.settings && user.settings.preferedProvince === "All"
    );
    
    setUsersInAll(allProvinceUsers);
    setUsersCount(provinceSpecificUsers.length + allProvinceUsers.length);
  };

  useEffect(() => {
    if (events && events.length > 0) {
      const publishedEvents = events.filter((event) => event.published);
      const options = publishedEvents.map((event) => ({
        value: event.id,
        label: event.title,
        event: event,
      }));
      setEventOptions(options);
    }
  }, [events]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "clickAction") {
      // When action type changes, reset related fields
      setFormData((prev) => ({
        ...prev,
        message: {
          ...prev.message,
          clickAction: value,
          data: {},
        },
        targetId: "",
        selectedEvent: null,
      }));
    } else {
      setFormData((prev) => ({
        ...prev,
        [name]: value,
      }));
      
      // If changing province, update user counts
      if (name === "province") {
        setTimeout(updateUsersInProvince, 0);
      }
    }

    // Clear error when field is edited
    if (errors[name]) {
      setErrors((prev) => ({
        ...prev,
        [name]: "",
      }));
    }
  };

  const handleUserChange = (selectedOption) => {
    setFormData((prev) => ({
      ...prev,
      userId: selectedOption ? selectedOption.value : "",
    }));

    if (errors.userId) {
      setErrors((prev) => ({
        ...prev,
        userId: "",
      }));
    }
  };

  const handleEventChange = (selectedOption) => {
    if (selectedOption) {
      const eventData = selectedOption.event;

      setFormData((prev) => ({
        ...prev,
        selectedEvent: selectedOption,
        targetId: eventData.id,
        message: {
          ...prev.message,
          data: { event: eventData },
        },
      }));

      if (errors.targetId || errors.selectedEvent) {
        setErrors((prev) => ({
          ...prev,
          targetId: "",
          selectedEvent: "",
        }));
      }
    } else {
      setFormData((prev) => ({
        ...prev,
        selectedEvent: null,
        targetId: "",
        message: {
          ...prev.message,
          data: {},
        },
      }));
    }
  };

  const validateForm = () => {
    const newErrors = {};

    if (!formData.title.trim()) {
      newErrors.title = "Title is required";
    }

    if (!formData.body.trim()) {
      newErrors.body = "Message body is required";
    }

    // Validate based on notification type
    if (formData.notificationType === "single" && !formData.userId) {
      newErrors.userId = "Please select a user";
    } else if (formData.notificationType === "province" && !formData.province) {
      newErrors.province = "Please select a province";
    }

    if (
      formData.message.clickAction === "SINGLE_EVENT" &&
      !formData.selectedEvent
    ) {
      newErrors.selectedEvent = "Please select an event";
    } else if (
      formData.message.clickAction &&
      formData.message.clickAction !== "GENERAL_NOTIFICATION" &&
      formData.message.clickAction !== "SINGLE_EVENT" &&
      !formData.targetId.trim()
    ) {
      newErrors.targetId = "Target ID is required for this action type";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      toast.error("Please fix the errors in the form");
      return;
    }

    setLoading(true);

    try {
      const notificationData = {
        title: formData.title,
        body: formData.body,
        data: {
          click_action: formData.message.clickAction,
        },
      };

      // Add data based on the action type
      if (
        formData.message.clickAction === "SINGLE_EVENT" &&
        formData.selectedEvent
      ) {
        notificationData.data.id = formData.targetId;

        // Extract only essential event data to avoid sending large objects
        const eventData = formData.selectedEvent.event;
        notificationData.data.eventId = eventData.id;
        notificationData.data.eventTitle = eventData.title;
        notificationData.data.eventLocation = eventData.location;
        notificationData.data.eventDate = eventData.date
          ? new Date(eventData.date.seconds * 1000).toISOString()
          : "";
        notificationData.data.eventImageUrl = eventData.imageUrl || "";
      } else if (
        formData.targetId &&
        formData.message.clickAction !== "GENERAL_NOTIFICATION"
      ) {
        notificationData.data.id = formData.targetId;
      }

      let success = false;

      if (formData.notificationType === "single") {
        // Send to single user
        success = await notifySingleUser(formData.userId, notificationData);
      } else if (formData.notificationType === "province") {
        // Send to all users in a province - include both specific province and "All" users
        const provinceUsers = users
          .filter(
            user => 
              user.settings && (
                user.settings.preferedProvince === formData.province || 
                user.settings.preferedProvince === "All"
              )
          )
          .map(user => user.uid);

        if (provinceUsers.length === 0) {
          toast.warning(`No users found in ${formData.province}`);
          setLoading(false);
          return;
        }

        success = await notifyMultipleUsers(provinceUsers, notificationData);
      }

      if (success !== false) {
        try {
          // Store the notification in database
          // Uncomment if needed:
          // await addNotification({
          //   body: formData.body,
          //   data: {
          //     click_action: formData.message.clickAction,
          //     id: formData.targetId,
          //   },
          //   id: "",
          //   read: false,
          //   timestamp: new Date(),
          //   title: formData.title,
          //   userId: formData.notificationType === "single" ? formData.userId : "multiple",
          // });

          const successMessage =
            formData.notificationType === "single"
              ? "Notification sent successfully!"
              : `Notification sent to all users in ${formData.province}!`;

          toast.success(successMessage);
          navigate("/notifications");
        } catch (error) {
          console.error("Error sending notification:", error);
          toast.error(
            `Failed to send notification: ${
              error.message || "Please try again"
            }`
          );
        }
      }
    } catch (error) {
      console.error("Error sending notification:", error);
      toast.error(
        `Failed to send notification: ${error.message || "Please try again"}`
      );
    } finally {
      setLoading(false);
    }
  };

  // Custom styles for react-select
  const customSelectStyles = {
    control: (provided, state) => ({
      ...provided,
      backgroundColor: "#2b2c40",
      borderColor: errors.userId ? "#dc3545" : "#444564",
      boxShadow: state.isFocused
        ? "0 0 0 0.25rem rgba(105, 108, 255, 0.25)"
        : "none",
      "&:hover": {
        borderColor: "#444564",
      },
    }),
    option: (provided, { isSelected, isFocused }) => ({
      ...provided,
      backgroundColor: isSelected
        ? "#696cff"
        : isFocused
        ? "rgba(105, 108, 255, 0.1)"
        : "#2b2c40",
      color: isSelected ? "#fff" : "#d0d0d0",
      cursor: "pointer",
      ":active": {
        backgroundColor: isSelected ? "#696cff" : "rgba(105, 108, 255, 0.2)",
      },
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: "#2b2c40",
      zIndex: 9999,
    }),
    input: (provided) => ({
      ...provided,
      color: "#d0d0d0",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#d0d0d0",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "#666",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      color: "#666",
      "&:hover": {
        color: "#d0d0d0",
      },
    }),
    clearIndicator: (provided) => ({
      ...provided,
      color: "#666",
      "&:hover": {
        color: "#d0d0d0",
      },
    }),
    multiValue: (provided) => ({
      ...provided,
      backgroundColor: "rgba(105, 108, 255, 0.2)",
    }),
    multiValueLabel: (provided) => ({
      ...provided,
      color: "#d0d0d0",
    }),
    multiValueRemove: (provided) => ({
      ...provided,
      color: "#666",
      "&:hover": {
        backgroundColor: "rgba(220, 53, 69, 0.2)",
        color: "#dc3545",
      },
    }),
  };

  return (
    <div className="row">
      <div className="col-xl-12">
        <div className="card">
          <div className="card-header bg-transparent">
            <div className="d-flex align-items-center">
              <div>
                <h4 className="card-title mb-0">New Notification</h4>
                <p className="mb-0 mt-1">Send a notification to users</p>
              </div>
            </div>
          </div>

          <div className="card-body">
            <form onSubmit={handleSubmit}>
              <div className="mb-3">
                <label className="form-label">Notification Target</label>
                <div className="d-flex">
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="notificationType"
                      id="singleUser"
                      value="single"
                      checked={formData.notificationType === "single"}
                      onChange={handleChange}
                    />
                    <label className="form-check-label" htmlFor="singleUser">
                      Single User
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="notificationType"
                      id="provinceUsers"
                      value="province"
                      checked={formData.notificationType === "province"}
                      onChange={handleChange}
                    />
                    <label className="form-check-label" htmlFor="provinceUsers">
                      Users by Province
                    </label>
                  </div>
                </div>
              </div>

              {formData.notificationType === "single" ? (
                <div className="mb-3">
                  <label htmlFor="userId" className="form-label">
                    Select User <span className="text-danger">*</span>
                  </label>
                  <Select
                    id="userId"
                    options={userOptions}
                    styles={customSelectStyles}
                    placeholder="Search and select a user..."
                    isClearable
                    onChange={handleUserChange}
                    className="react-select-container"
                    classNamePrefix="react-select"
                    formatOptionLabel={(option) => (
                      <div className="d-flex align-items-center">
                        {option.user.photoURL ? (
                          <img
                            src={option.user.photoURL}
                            alt="avatar"
                            className="rounded-circle me-2"
                            width="24"
                            height="24"
                            style={{ objectFit: "cover" }}
                          />
                        ) : (
                          <div
                            className="avatar-initial rounded-circle bg-label-info me-2"
                            style={{
                              width: "24px",
                              height: "24px",
                              fontSize: "12px",
                            }}
                          >
                            <span className="avatar-content">
                              {(option.user.firstName || "?")
                                .charAt(0)
                                .toUpperCase()}
                              {(option.user.lastName || "?")
                                .charAt(0)
                                .toUpperCase()}
                            </span>
                          </div>
                        )}
                        <div>
                          <div>
                            {option.user.firstName} {option.user.lastName}
                          </div>
                          <small className="text-muted">
                            {option.user.email || "No email"}
                          </small>
                        </div>
                      </div>
                    )}
                  />
                  {errors.userId && (
                    <div className="text-danger form-text">{errors.userId}</div>
                  )}
                </div>
              ) : (
                <div className="mb-3">
                  <label htmlFor="province" className="form-label">
                    Select Province <span className="text-danger">*</span>
                  </label>
                  <select
                    className={`form-control ${
                      errors.province ? "is-invalid" : ""
                    }`}
                    id="province"
                    name="province"
                    value={formData.province}
                    onChange={handleChange}
                  >
                    <option value="">Select a province</option>
                    {provinces.map((province) => (
                      <option key={province} value={province}>
                        {province}
                      </option>
                    ))}
                  </select>
                  {errors.province && (
                    <div className="invalid-feedback">{errors.province}</div>
                  )}
                  {formData.province && users && (
                    <small className="text-info mt-1 d-block">
                      {usersCount} user(s) will receive this notification
                      {usersInAll.length > 0 && (
                        <span> (including {usersInAll.length} user(s) subscribed to "All" provinces)</span>
                      )}
                    </small>
                  )}
                </div>
              )}

              <div className="mb-3">
                <label htmlFor="title" className="form-label">
                  Notification Title <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  className={`form-control ${errors.title ? "is-invalid" : ""}`}
                  id="title"
                  name="title"
                  placeholder="Enter notification title"
                  value={formData.title}
                  onChange={handleChange}
                />
                {errors.title && (
                  <div className="invalid-feedback">{errors.title}</div>
                )}
              </div>

              <div className="mb-3">
                <label htmlFor="body" className="form-label">
                  Notification Message <span className="text-danger">*</span>
                </label>
                <textarea
                  className={`form-control ${errors.body ? "is-invalid" : ""}`}
                  id="body"
                  name="body"
                  rows="4"
                  placeholder="Enter the notification message"
                  value={formData.body}
                  onChange={handleChange}
                ></textarea>
                {errors.body && (
                  <div className="invalid-feedback">{errors.body}</div>
                )}
              </div>

              <div className="mb-3">
                <label htmlFor="clickAction" className="form-label">
                  Action Type
                </label>
                <select
                  className="form-control"
                  id="clickAction"
                  name="clickAction"
                  value={formData.message.clickAction}
                  onChange={handleChange}
                >
                  <option value="">No action (informational only)</option>
                  {actionTypes.map((action) => (
                    <option key={action.id} value={action.id}>
                      {action.name}
                    </option>
                  ))}
                </select>
                <small className="text-muted">
                  The action will determine what happens when the user clicks on
                  the notification.
                </small>
              </div>

              {formData.message.clickAction === "SINGLE_EVENT" && (
                <div className="mb-3">
                  <label htmlFor="selectedEvent" className="form-label">
                    Select Event <span className="text-danger">*</span>
                  </label>
                  <Select
                    id="selectedEvent"
                    options={eventOptions}
                    styles={customSelectStyles}
                    placeholder="Search and select an event..."
                    isClearable
                    onChange={handleEventChange}
                    className="react-select-container"
                    classNamePrefix="react-select"
                    formatOptionLabel={(option) => (
                      <div className="d-flex align-items-center">
                        {option.event.imageUrl ? (
                          <img
                            src={option.event.imageUrl}
                            alt="event"
                            className="rounded me-2"
                            width="32"
                            height="32"
                            style={{ objectFit: "cover" }}
                          />
                        ) : (
                          <div
                            className="avatar-initial rounded bg-label-primary me-2"
                            style={{ width: "32px", height: "32px" }}
                          >
                            <span className="avatar-content">
                              {option.event.title.charAt(0).toUpperCase()}
                            </span>
                          </div>
                        )}
                        <div>
                          <div>{option.event.title}</div>
                          <small className="text-muted">
                            {option.event.date
                              ? new Date(
                                  option.event.date.seconds * 1000
                                ).toLocaleDateString()
                              : "No date"}{" "}
                            -{" "}
                            {option.event.location
                              ? option.event.location.split(",")[0]
                              : "No location"}
                          </small>
                        </div>
                      </div>
                    )}
                  />
                  {errors.selectedEvent && (
                    <div className="text-danger form-text">
                      {errors.selectedEvent}
                    </div>
                  )}
                </div>
              )}

              {formData.message.clickAction &&
                formData.message.clickAction !== "GENERAL_NOTIFICATION" &&
                formData.message.clickAction !== "SINGLE_EVENT" && (
                  <div className="mb-3">
                    <label htmlFor="targetId" className="form-label">
                      Target ID <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className={`form-control ${
                        errors.targetId ? "is-invalid" : ""
                      }`}
                      id="targetId"
                      name="targetId"
                      placeholder="Enter the target ID (e.g., product ID)"
                      value={formData.targetId}
                      onChange={handleChange}
                    />
                    {errors.targetId && (
                      <div className="invalid-feedback">{errors.targetId}</div>
                    )}
                    <small className="text-muted">
                      {formData.message.clickAction === "SINGLE_PRODUCT" &&
                        "Enter the product ID the user should view when clicking the notification."}
                      {formData.message.clickAction === "PROFILE_UPDATE" &&
                        "Enter the user ID whose profile should be viewed."}
                    </small>
                  </div>
                )}

              <div className="d-flex justify-content-between mt-4">
                <button
                  type="button"
                  className="btn btn-outline-secondary"
                  onClick={() => navigate("/notifications")}
                  disabled={loading}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="btn btn-primary"
                  disabled={loading}
                >
                  {loading ? (
                    <>
                      <span
                        className="spinner-border spinner-border-sm me-1"
                        role="status"
                        aria-hidden="true"
                      ></span>
                      Sending...
                    </>
                  ) : formData.notificationType === "single" ? (
                    "Send Notification"
                  ) : (
                    `Send to All Users in ${
                      formData.province || "Selected Province"
                    }`
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NewNotification;
