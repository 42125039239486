import React, { useEffect, useState } from "react";
import Announcement from "../../components/announcement";
import { Tooltip } from "react-tooltip";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../../stores/AuthContext";
import DataTable from "datatables.net-react";
import DT from "datatables.net-dt";
import {
  deleteRidingSpot,
  getRidingSpotReports,
  updateRidingSpot,
} from "../../services/firebase_service";
import { toast } from "react-toastify";

DataTable.use(DT);

function RidingSpotList() {
  const { user, ridingSpots, setRidingSpots } = useAuth();
  const [loading, setLoading] = useState(true);
  const [tableData, setTableData] = useState([]);
  const [reportCounts, setReportCounts] = useState({});
  const navigate = useNavigate();

  const tableOptions = {
    order: [[2, "asc"]],
    paging: true,
    searching: true,
    lengthMenu: [10, 25, 50, 75, 100],
    language: {
      emptyTable: "No riding spots available to display",
    },
    columnDefs: [
      {
        targets: [0, 1],
        visible: false,
      },
      {
        targets: [2],
        render: function (data, type, row) {
          return data
            ? `<div class="d-flex"><img src="${
                row[1]
              }" class="rounded-circle cursor-pointer me-2 object-fit-cover" width="40" height="40" onClick="editRidingSpot('${
                row[0]
              }')" /><div class="desc"><span class="text-primary text-body fw-bold underLink" style="cursor:pointer;" onClick="editRidingSpot('${
                row[0]
              }')">${data.split(",")[0]}</span><br/><small class="text-muted">${
                data.split(",")[1]
              }</small></div></div>`
            : ``;
        },
      },
      {
        targets: [7],
        render: function (data, type, row) {
          return data > 0 
            ? `<span class="badge rounded-pill bg-danger">${data}</span>`
            : '<span class="badge rounded-pill bg-secondary">0</span>';
        },
      },
      {
        targets: [8],
        render: function (data, type, row) {
          return `<div class="form-check form-switch py-2">
                    <input class="form-check-input cursor-pointer" type="checkbox" id="status${
                      row[0]
                    }" ${
            data ? "checked" : ""
          } onChange="window.updateStatus('${row[0]}')">
                  </div>`;
        },
      },
      {
        targets: [9],
        sortable: false,
        render: function (data, type, row) {
          return `<div class="dropdown">
                    <button type="button" class="btn p-0 dropdown-toggle hide-arrow" data-bs-toggle="dropdown" aria-expanded="false">
                      <i class="ti ti-dots-vertical"></i>
                    </button>
                    <div class="dropdown-menu" style="">
                      <a class="dropdown-item waves-effect cursor-pointer" onClick="editRidingSpot('${row[0]}')"><i class="ti ti-pencil me-1"></i> Edit</a>
                      <a class="dropdown-item waves-effect cursor-pointer" onClick="deleteRidingSpot('${row[0]}')"><i class="ti ti-trash me-1"></i> Delete</a>
                    </div>
                  </div>`;
        },
      },
    ],
  };

  useEffect(() => {
    const fetchReportCounts = async () => {
      const counts = {};
      for (const spot of ridingSpots) {
        const reportsDocs = await getRidingSpotReports(spot.id);
        counts[spot.id] = reportsDocs.docs.filter(doc => !doc.data().doneAt).length;
      }
      setReportCounts(counts);
    };

    if (ridingSpots.length > 0) {
      fetchReportCounts();
    }
  }, [ridingSpots]);

  useEffect(() => {
    setTableData(
      ridingSpots.map((ridingSpot) => {
        const eventContent = ridingSpot.description.replace(
          /<\/?[^>]+(>|$)/g,
          ""
        );

        return [
          ridingSpot.id,
          ridingSpot.imageUrl ?? "https://via.placeholder.com/150",
          `${ridingSpot.name},${eventContent.substring(0, 50)}` ?? "",
          ridingSpot.location.province ?? "",
          ridingSpot.riderLevel ?? "",
          ridingSpot.distance ?? "",
          ridingSpot.price ?? "",
          reportCounts[ridingSpot.id] || 0,
          ridingSpot.published ?? "",
          ridingSpot.id,
        ];
      })
    );
    setLoading(false);
  }, [ridingSpots, reportCounts]);

  useEffect(() => {
    window.updateStatus = async (id) => {
      const ridingSpot = ridingSpots.find((ridingSpot) => ridingSpot.id === id);
      ridingSpot.published = !ridingSpot.published;

      try {
        await updateRidingSpot(id, ridingSpot);
        const updatedSpots = ridingSpots.map((e) =>
          e.id === id ? ridingSpot : e
        );
        setRidingSpots(updatedSpots);
        if (ridingSpot.published) {
          toast.success("Riding Spot published!");
        } else {
          toast.success("Riding Spot unpublished!");
        }
      } catch (error) {
        toast.error("Error: Unable to update Riding Spot status");
        console.error("Error updating Riding Spot status", error);
      }
    };

    window.editRidingSpot = (id) => {
      navigate(`/riding-spots/${id}/edit`);
    };

    window.deleteRidingSpot = (id) => {
      if (window.confirm("Are you sure you want to delete this riding spot?")) {
        // Call the delete function
        deleteRidingSpot(id)
          .then(() => {
            // Remove the deleted riding spot from the list
            const updatedSpots = ridingSpots.filter((spot) => spot.id !== id);
            setRidingSpots(updatedSpots);
            toast.success("Riding Spot deleted successfully");
          })
          .catch((error) => {
            toast.error("Error deleting Riding Spot");
            console.error("Error deleting Riding Spot", error);
          });
      }
    };

    // Clean up the functions when the component unmounts
    return () => {
      delete window.updateStatus;
      delete window.editRidingSpot;
      delete window.deleteRidingSpot;
    };
  }, [navigate, ridingSpots, setRidingSpots]);

  return (
    <div>
      <Announcement />
      <div className="row">
        <div className="col-xl-12">
          <div className="card">
            <div className="card-header bg-transparent">
              <div className="bg-transparent d-flex align-items-center justify-content-between mb-3">
                <div className="left">
                  <h4 className="">Riding Spots</h4>
                  <p className="mb-0">Manage and view all riding spots</p>
                </div>
                <div className="right">
                  <Tooltip id="tooltopBottom" place="left" />
                  {!user.emailVerified ? (
                    <div
                      data-tooltip-id="tooltopBottom"
                      data-tooltip-content="You need to verify your email address first"
                      className="cursor-not-allowed"
                    >
                      <button className="btn btn-primary" disabled>
                        <i className="ti ti-plus"></i> New
                      </button>
                    </div>
                  ) : (
                    <Link to="/riding-spots/new" className="btn btn-primary">
                      <i className="ti ti-plus"></i> New
                    </Link>
                  )}
                  <Tooltip id="disabledBtn" />
                </div>
              </div>
            </div>
            <div className="card-body pt-0">
              <div className="table-responsive rounded-4" data-bs-theme="dark">
                {!loading ? (
                  <DataTable
                    className="table text-nowrap usersTable table-dark mb-0 align-middle"
                    options={tableOptions}
                    data={tableData}
                  >
                    <thead>
                      <tr>
                        <th className="fs-4 fw-semibold mb-0">ID</th>
                        <th className="fs-4 fw-semibold mb-0">Image</th>
                        <th className="fs-4 fw-semibold mb-0">Title</th>
                        <th className="fs-4 fw-semibold mb-0">Province</th>
                        <th className="fs-4 fw-semibold mb-0">Rider Level</th>
                        <th className="fs-4 fw-semibold mb-0">Distance</th>
                        <th className="fs-4 fw-semibold mb-0">Price</th>
                        <th className="fs-4 fw-semibold mb-0">Reports</th>
                        <th className="fs-4 fw-semibold mb-0">Published</th>
                        <th>&nbsp;</th>
                      </tr>
                    </thead>
                  </DataTable>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RidingSpotList;
