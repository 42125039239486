import React, { useEffect, useState } from "react";
import Announcement from "../../components/announcement";
import { useAuth } from "../../stores/AuthContext";
import DataTable from "datatables.net-react";
import DT from "datatables.net-dt";
import { updateReport } from "../../services/firebase_service";
import { toast } from "react-toastify";

DataTable.use(DT);

function ReportList() {
  const { reports, setReports } = useAuth();
  const [loading, setLoading] = useState(true);
  const [tableData, setTableData] = useState([]);

  const tableOptions = {
    order: [[0, "desc"]],
    paging: true,
    searching: true,
    lengthMenu: [10, 25, 50, 75, 100],
    language: {
      emptyTable: "No reports available to display",
    },
    columnDefs: [
      {
        targets: [0],
        render: function (data, type, row) {
          if (data && data.seconds) {
            return new Date(data.seconds * 1000).toLocaleString('en-GB', {
              day: '2-digit',
              month: 'short',
              year: 'numeric',
              hour: '2-digit',
              minute: '2-digit',
              hour12: false
            });
          }
          return '';
        },
      },
      {
        targets: [3],
        sortable: false,
        render: function (data, type, row) {
          return `<div class="form-check form-switch py-2">
                    <input class="form-check-input cursor-pointer" type="checkbox" ${
                      data ? "checked" : ""
                    } onChange="window.updateStatus('${row[4]}')">
                  </div>`;
        },
      },
    ],
  };

  useEffect(() => {
    setTableData(
      reports.map((report) => [
        report.reportedAt != null && report.reportedAt != ""
          ? report.reportedAt
          : "",
        report.itemType,
        report.reason,
        report.doneAt ? true : false,
        report.id,
      ])
    );
    setLoading(false);
  }, [reports]);

  useEffect(() => {
    window.updateStatus = async (id) => {
      const report = reports.find((report) => report.id === id);
      const updatedReport = {
        ...report,
        doneAt: report.doneAt ? null : new Date(),
      };

      try {
        await updateReport(id, updatedReport);
        const updatedReports = reports.map((r) =>
          r.id === id ? updatedReport : r
        );
        setReports(updatedReports);
        toast.success("Report status updated!");
      } catch (error) {
        toast.error("Error: Unable to update report status");
        console.error("Error updating report status", error);
      }
    };

    return () => {
      delete window.updateStatus;
    };
  }, [reports, setReports]);

  return (
    <div>
      <Announcement />
      <div className="row">
        <div className="col-xl-12">
          <div className="card">
            <div className="card-header bg-transparent">
              <div className="bg-transparent d-flex align-items-center justify-content-between mb-3">
                <div className="left">
                  <h4 className="">Reports</h4>
                  <p className="mb-0">View and manage all reports</p>
                </div>
              </div>
            </div>
            <div className="card-body pt-0">
              <div className="table-responsive rounded-4" data-bs-theme="dark">
                {!loading ? (
                  <DataTable
                    className="table text-nowrap reportsTable table-dark mb-0 align-middle"
                    options={tableOptions}
                    data={tableData}
                  >
                    <thead>
                      <tr>
                        <th className="fs-4 fw-semibold mb-0">Date</th>
                        <th className="fs-4 fw-semibold mb-0">Type</th>
                        <th className="fs-4 fw-semibold mb-0">Reason</th>
                        <th className="fs-4 fw-semibold mb-0">Done</th>
                      </tr>
                    </thead>
                  </DataTable>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ReportList;
