import React from "react";
import { LineChart, Line, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';
const data = [
  { name: "Page A", users: 400, pv: 2400, amt: 2400 },
  { name: "Page B", users: 300, pv: 4567, amt: 2400 },
  { name: "Page C", users: 300, pv: 1398, amt: 2400 },
  { name: "Page D", users: 200, pv: 9800, amt: 2400 },
  { name: "Page E", users: 278, pv: 3908, amt: 2400 },
  { name: "Page F", users: 189, pv: 4800, amt: 2400 },
];

function CustomLineChart({ label, data }) {
  return (
    <ResponsiveContainer width="100%" height={300}>
      <LineChart
        data={data}
        margin={{ top: 5, right: 20, bottom: 5, left: 0 }}
      >
        <Line type="monotone" dataKey={label} stroke="#F6BE01" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
      </LineChart>
    </ResponsiveContainer>
  );
}

export default CustomLineChart;
