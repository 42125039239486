import { toast } from "react-toastify";
import { db } from "../firebase";
import {
  collection,
  getDocs,
  addDoc,
  setDoc,
  doc,
  updateDoc,
  serverTimestamp,
  deleteDoc,
  getDoc,
} from "firebase/firestore";

// Remove admin SDK initialization

/*******
 *
 * Create functions
 *
 ********/

export const addEvent = (event) => {
  return addDoc(collection(db, "events"), event);
};

export const addRidingSpot = async (ridingSpot) => {
  return addDoc(collection(db, "riding_spots"), ridingSpot);
};

export const addWorkshop = async (workshop) => {
  return addDoc(collection(db, "workshops"), workshop);
};

export const addNotification = async (notification) => {
  return addDoc(collection(db, "notifications"), notification);
};

export const addSponsor = async (sponsor) => {
  return addDoc(collection(db, "sponsors"), sponsor);
}

/*******
 *
 * Update functions
 *
 ********/

export const updateEvent = (eventId, event) => {
  return setDoc(doc(db, "events", eventId), event);
};

export const updateProduct = async (productId, product) => {
  return setDoc(doc(db, "products", productId), product);
};

export const updateRidingSpot = async (id, ridingSpot) => {
  return setDoc(doc(db, "riding_spots", id), ridingSpot);
};

export const updateWorkshop = async (id, workshop) => {
  return setDoc(doc(db, "workshops", id), workshop);
};

export const updateUser = async (userId, userData) => {
  try {
    const userRef = doc(db, "users", userId);
    const updateData = {
      ...userData,
      updatedAt: serverTimestamp(),
    };
    delete updateData.uid; // Remove uid from update data
    await updateDoc(userRef, updateData);
    return true;
  } catch (error) {
    console.error("Error updating user:", error);
    throw new Error("Permission denied: Unable to update user data");
  }
};

export const updateUserLoginStatus = async (userId, status) => {
  try {
    const userRef = doc(db, "users", userId);
    await updateDoc(userRef, {
      status: status,
      updatedAt: serverTimestamp(),
    });
    return true;
  } catch (error) {
    console.error("Error updating user login status:", error);
    throw new Error("Permission denied: Unable to update user status");
  }
};

export const updateReport = async (id, data) => {
  const reportRef = doc(db, "reports", id);
  await updateDoc(reportRef, data);
};

/*******
 *
 * Delete functions
 *
 ********/

export const deleteRidingSpot = async (id) => {
  return deleteDoc(doc(db, "riding_spots", id));
};

export const deleteEvent = async (eventId) => {
  return deleteDoc(doc(db, "events", eventId));
};

export const deleteProduct = async (productId) => {
  return deleteDoc(doc(db, "products", productId));
};

export const deleteWorkshop = async (id) => {
  return deleteDoc(doc(db, "workshops", id));
};

export const deleteUserDocs = async (userId) => {
  try {
    const response = await fetch(
      "https://us-central1-funduro-za.cloudfunctions.net/deleteUser",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          uid: userId,
        }),
      }
    );

    if (!response.ok) {
      throw new Error("Failed to delete user authentication");
    }

    await deleteDoc(doc(db, "users", userId));
    return true;
  } catch (error) {
    console.error("Error deleting user:", error);
    toast.error("Error deleting user.");
    throw error;
  }
};

/*******
 *
 * General functions
 *
 ********/
export const getRidingSpotReports = async (id) => {
  const reportsRef = collection(db, "riding_spots", id, "error_reports");
  return await getDocs(reportsRef);
};

export const getWorkshopReports = async (id) => {
  const reportsRef = collection(db, "workshops", id, "error_reports");
  return await getDocs(reportsRef);
};

export const notifyProUsers = async (eventId, eventDetails) => {
  try {
    toast.info("Sending notifications to pro users...", { autoClose: false });

    // Call the Cloud Function to send notifications
    const response = await fetch(
      "https://us-central1-funduro-za.cloudfunctions.net/sendEventProNotifications",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          eventId,
          eventTitle: eventDetails.title,
          eventDate: eventDetails.date,
          eventLocation: eventDetails.location,
        }),
      }
    );

    const result = await response.json();
    toast.dismiss();

    if (!response.ok) {
      throw new Error(result.error || "Failed to send notifications");
    }

    toast.success(
      `Successfully sent notifications to ${result.successCount} pro users`
    );
    return true;
  } catch (error) {
    toast.dismiss();
    console.error("Error sending notifications:", error);
    toast.error("Failed to send notifications to pro users");
    throw error;
  }
};

export const notifySingleUser = async (userId, message) => {
  if (!userId) {
    toast.error("User ID is required to send notifications");
    return;
  }
  if (!message.title || !message.body || !message.data) {
    toast.error("Notification title, body, and data are required");
    return;
  }

  try {
    // Convert all data values to strings to meet FCM requirements
    const stringifiedData = {};
    
    // Process all keys in the data object
    Object.keys(message.data).forEach(key => {
      const value = message.data[key];
      
      // If the value is an object or array, stringify it
      if (typeof value === 'object' && value !== null) {
        stringifiedData[key] = JSON.stringify(value);
      } else if (value !== undefined && value !== null) {
        // Convert all other non-null values to strings
        stringifiedData[key] = String(value);
      } else {
        // Skip null or undefined values
        stringifiedData[key] = '';
      }
    });

    const response = await fetch(
      "https://us-central1-funduro-za.cloudfunctions.net/sendUserNotification",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          userId,
          title: message.title,
          body: message.body,
          data: stringifiedData,
        }),
      }
    );

    if (!response.ok) {
      const errorText = await response.text();
      throw new Error(`Failed to send notification: ${errorText}`);
    }

    const result = await response.json();

    if (result.success) {
      toast.success("Successfully sent notification to user");
      return true;
    } else {
      console.error("Error sending notification to user:", result.error);
      toast.error(`Failed to send notification: ${result.error || 'Unknown error'}`);
      return false;
    }
  } catch (error) {
    console.error("Error sending notification to user:", error);
    toast.error(`Error: ${error.message || 'Failed to send notification'}`);
    throw error;
  }
};

export const notifyMultipleUsers = async (userIds, message) => {
  if (!userIds || !userIds.length) {
    toast.error("User IDs are required to send notifications");
    return
  }
  if (!message.title || !message.body || !message.data) {
    toast.error("Notification title, body, and data are required");
    return
  }

  try {
    const response = await fetch(
      "https://us-central1-funduro-za.cloudfunctions.net/sendUsersNotifications",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          userIds,
          title: message.title,
          body: message.body,
          data: message.data,
        }),
      }
    );

    if (!response.ok) {
      throw new Error("Failed to send notifications to users");
    }

    const result = await response.json();

    if (result.success) {
      toast.success("Successfully sent notifications to users");
    }else{
      console.error("Error sending notifications to users:", result.error);
      toast.error("Failed to send notifications to users");
    }
  } catch (error) {
    console.error("Error sending notifications to users:", error);
    throw error;
  }
};

export const isUserAllowed = async (id) => {
  const userDoc = await getDoc(doc(db, "users", id));
  const user = userDoc.data();
  return user?.role === "admin" || user?.role === "organiser";
};

export const getEventById = async (eventId) => {
  try {
    const eventDoc = await getDoc(doc(db, "events", eventId));
    if (eventDoc.exists()) {
      return { id: eventDoc.id, ...eventDoc.data() };
    } else {
      console.error("Event not found");
      return null;
    }
  } catch (error) {
    console.error("Error fetching event:", error);
    throw error;
  }
};
