import React, { useCallback, useState } from "react";
import Announcement from "../../components/announcement";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../../stores/AuthContext";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { useDropzone } from "react-dropzone";
import { storage, db } from "../../firebase";
import { toast } from "react-toastify";
import GooglePlacesAutocomplete, {
  geocodeByPlaceId,
} from "react-google-places-autocomplete";
import { collection, addDoc, Timestamp } from "firebase/firestore";

function NewProduct() {
  const navigate = useNavigate();
  const { setProducts } = useAuth();
  const [product, setProduct] = useState({
    itemName: "",
    description: "",
    price: "",
    category: "",
    location: "",
    phoneNumber: {
      countryDialCode: "27",
      number: "",
      allowWhatsApp: true,
    },
    images: [],
    featured: false,
    status: "pending",
    link: "",
    timestamp: Timestamp.now(),
    updatedAt: Timestamp.now(),
  });

  const [loading, setLoading] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [previews, setPreviews] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [locationError, setLocationError] = useState(null);

  const handleDrop = useCallback((acceptedFiles) => {
    setSelectedFiles((prev) => [...prev, ...acceptedFiles]);
    setPreviews((prev) => [
      ...prev,
      ...acceptedFiles.map((file) => URL.createObjectURL(file)),
    ]);
  }, []);

  const removeFile = (index) => {
    setSelectedFiles((prev) => prev.filter((_, i) => i !== index));
    setPreviews((prev) => prev.filter((_, i) => i !== index));
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: handleDrop,
    accept: {
      "image/*": [".jpeg", ".jpg", ".png"],
    },
  });

  const uploadImages = async () => {
    if (!selectedFiles.length) return [];

    const uploadPromises = selectedFiles.map(async (file) => {
      const storageRef = ref(storage, `productImages/image_${Date.now()}`);
      const uploadTask = uploadBytesResumable(storageRef, file);

      return new Promise((resolve, reject) => {
        uploadTask.on(
          "state_changed",
          null,
          (error) => reject(error),
          async () => {
            const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
            resolve(downloadURL);
          }
        );
      });
    });

    setUploading(true);
    const uploadedUrls = await Promise.all(uploadPromises);
    setUploading(false);
    return uploadedUrls;
  };

  const handlePlaceSelected = (value) => {
    geocodeByPlaceId(value.value.place_id)
      .then((results) => {
        setProduct({ ...product, location: results[0].formatted_address });
      })
      .catch((error) => {
        console.error("Error fetching location:", error);
        setLocationError("Failed to get location details");
      });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const imageUrls = await uploadImages();
      const newProduct = {
        ...product,
        phoneNumber: {
          ...product.phoneNumber,
          countryDialCode: "27",
          number: product.phoneNumber.number.startsWith("0")
            ? product.phoneNumber.number.slice(1)
            : product.phoneNumber.number,
        },
        sellerId:
          product.sellerId != null && product.sellerId != ""
            ? product.sellerId
            : "system",
        images: imageUrls,
      };

      const docRef = await addDoc(collection(db, "products"), newProduct);
      setProducts((prev) => [...prev, { ...newProduct, id: docRef.id }]);

      toast.success("Product created successfully!");
      navigate("/products");
    } catch (error) {
      console.error("Error creating product:", error);
      toast.error("Failed to create product");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <Announcement />
      <div className="row">
        <div className="col-xl-12">
          <div className="card">
            <div className="card-header bg-transparent">
              <div className="d-flex align-items-center justify-content-between">
                <div>
                  <h4>New Product</h4>
                  <p className="mb-0">Create a new product listing</p>
                </div>
                <Link to="/products" className="btn btn-primary">
                  <i className="ti ti-arrow-left"></i> Back to Products
                </Link>
              </div>
            </div>

            <form onSubmit={handleSubmit}>
              <div className="card-body">
                <div className="row">
                  <div className="col-xl-8">
                    {/* Basic Details */}
                    <div className="row">
                      <div className="col-xl-6 mb-3">
                        <label className="form-label">Product Name</label>
                        <input
                          type="text"
                          className="form-control"
                          value={product.itemName}
                          onChange={(e) =>
                            setProduct({ ...product, itemName: e.target.value })
                          }
                          required
                        />
                      </div>

                      <div className="col-xl-6 mb-3">
                        <label className="form-label">Price</label>
                        <input
                          type="number"
                          className="form-control"
                          value={product.price}
                          onChange={(e) =>
                            setProduct({ ...product, price: e.target.value })
                          }
                          required
                        />
                      </div>

                      <div className="col-xl-6 mb-3">
                        <label className="form-label">Category</label>
                        <select
                          className="form-select"
                          name="category"
                          id="category"
                          value={product.category}
                          onChange={(option) =>
                            setProduct({
                              ...product,
                              category: option.target.value,
                            })
                          }
                        >
                          <option value=""></option>
                          <option value="2-Stroke">2-Stroke</option>
                          <option value="4-Stroke">4-Stroke</option>
                          <option value="Adventure">Adventure</option>
                          <option value="Gear">Gear</option>
                          <option value="Parts">Parts</option>
                        </select>
                      </div>

                      <div className="col-xl-6 mb-3">
                        <label className="form-label">Contact Number</label>
                        <input
                          type="tel"
                          className="form-control"
                          value={product.phoneNumber.number}
                          onChange={(e) =>
                            setProduct({
                              ...product,
                              phoneNumber: {
                                ...product.phoneNumber,
                                number: e.target.value,
                              },
                            })
                          }
                        />
                      </div>

                      <div className="col-12 mb-3">
                        <label className="form-label">Location</label>
                        <GooglePlacesAutocomplete
                          apiKey="AIzaSyD_Lsht21cw3UEhTUP9kPW0R9kylZIqCmM"
                          selectProps={{
                            value: product.location,
                            className:
                              "locationAutoComplete" +
                              (locationError ? " is-invalid" : ""),
                            onChange: handlePlaceSelected,
                            placeholder: "Search for a location",
                            onFocus: (e) => {
                              e.target.value = "";
                              setLocationError(null);
                            },
                            styles: {
                              placeholder: (provided) => ({
                                ...provided,
                                color: "#7c8fac",
                              }),
                              control: (provided) => ({
                                ...provided,
                                background: "transparent",
                                border: "1px solid #333f55",
                                borderRadius: "5px",
                              }),
                              input: (provided) => ({
                                ...provided,
                                color: "#fff",
                                cursor: "text",
                              }),
                              option: (provided) => ({
                                ...provided,
                                color: "#fff",
                                background: "#0F1820",
                                cursor: "pointer",
                              }),
                              dropdownIndicator: (provided) => ({
                                ...provided,
                                display: "none",
                              }),
                              indicatorSeparator: (provided) => ({
                                ...provided,
                                display: "none",
                              }),
                              menu: (provided) => ({
                                ...provided,
                                background: "#0F1820",
                              }),
                              loadingIndicator: (provided) => ({
                                ...provided,
                                color: "#fff",
                              }),
                              loadingMessage: (provided) => ({
                                ...provided,
                                color: "#fff",
                              }),
                              noOptionsMessage: (provided) => ({
                                ...provided,
                                color: "#fff",
                              }),
                              singleValue: (provided) => ({
                                ...provided,
                                color: "#fff",
                              }),
                            },
                          }}
                          apiOptions={{
                            region: "za",
                          }}
                          autocompletionRequest={{
                            componentRestrictions: {
                              country: ["za"],
                            },
                          }}
                          style={{ background: "#ff0000" }}
                        />
                        {locationError ? (
                          <div className="invalid-feedback">
                            {locationError}
                          </div>
                        ) : null}
                        {product.location ? (
                          <small className="text-muted">
                            {product.location}
                          </small>
                        ) : null}
                      </div>

                      <div className="col-12 mb-3">
                        <label className="form-label">Description</label>
                        <textarea
                          className="form-control"
                          rows="6"
                          value={product.description}
                          onChange={(e) =>
                            setProduct({
                              ...product,
                              description: e.target.value,
                            })
                          }
                        />
                      </div>
                    </div>
                  </div>

                  <div className="col-xl-4">
                    <div className="mb-3">
                      <label className="form-label">Product Images</label>
                      <div
                        {...getRootProps({
                          className: "dropzone border rounded-3 p-3",
                        })}
                      >
                        <input {...getInputProps()} />
                        <p className="text-center mb-0">
                          Drag images here or click to upload
                        </p>
                      </div>

                      <div className="mt-3 d-flex flex-wrap gap-2">
                        {previews.map((preview, index) => (
                          <div key={index} className="position-relative">
                            <img
                              src={preview}
                              alt={`Preview ${index + 1}`}
                              style={{
                                width: "100px",
                                height: "100px",
                                objectFit: "cover",
                              }}
                            />
                            <button
                              type="button"
                              className="btn btn-danger btn-sm position-absolute top-0 end-0"
                              onClick={() => removeFile(index)}
                            >
                              ×
                            </button>
                          </div>
                        ))}
                      </div>
                    </div>

                    <div className="mb-3">
                      <label className="form-label">Product JSON</label>
                      <textarea
                        className="form-control"
                        id="parsedJson"
                        rows="6"
                        defaultValue={JSON.stringify(
                          {
                            itemName: product.itemName,
                            description: product.description,
                            price: product.price,
                            category: product.category,
                            location: product.location,
                            phoneNumber: product.phoneNumber,
                          },
                          null,
                          2
                        )}
                      />
                      <button
                        type="button"
                        className="btn btn-primary mt-2"
                        onClick={() => {
                          try {
                            const textarea =
                              document.getElementById("parsedJson");
                            const parsedJson = JSON.parse(textarea.value);
                            console.log(parsedJson);
                            setProduct({
                              ...product,
                              ...parsedJson,
                              phoneNumber: {
                                number: parsedJson.phoneNumber || "",
                                allowWhatsApp:
                                  parsedJson.allowWhatsApp || false,
                              },
                            });
                            toast.success("JSON parsed successfully!");
                          } catch (err) {
                            toast.error("Invalid JSON format");
                          }
                        }}
                      >
                        Parse JSON
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-footer d-flex align-items-center justify-content-end">
                <div className="form-check form-switch py-2 me-4">
                  <input
                    className="form-check-input cursor-pointer"
                    type="checkbox"
                    id="featuredProduct"
                    checked={product.featured}
                    onChange={(e) =>
                      setProduct({ ...product, featured: e.target.checked })
                    }
                  />
                  <label
                    htmlFor="featuredProduct"
                    className="form-check-label cursor-pointer"
                  >
                    Featured
                  </label>
                </div>

                <div className="form-check form-switch py-2 me-4">
                  <input
                    className="form-check-input cursor-pointer"
                    type="checkbox"
                    id="productStatus"
                    checked={product.status == "approved" ? true : false}
                    onChange={(e) =>
                      setProduct({
                        ...product,
                        status: e.target.checked ? "approved" : "pending",
                      })
                    }
                  />
                  <label
                    htmlFor="featuredProduct"
                    className="form-check-label cursor-pointer"
                  >
                    Approved
                  </label>
                </div>

                <button
                  type="submit"
                  className="btn btn-primary d-flex align-items-center"
                  disabled={loading || uploading}
                >
                  {loading || uploading ? (
                    <>
                      <div className="spin me-2">
                        <i className="ti ti-loader"></i>
                      </div>{" "}
                      Creating...
                    </>
                  ) : (
                    "Create Product"
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NewProduct;
