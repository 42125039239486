import React, { useState, useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { Modal, Button, Form, Spinner } from "react-bootstrap";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { collection, addDoc, Timestamp, doc, updateDoc } from "firebase/firestore";
import { storage, db } from "../firebase";
import { useAuth } from "../stores/AuthContext";
import { toast } from "react-toastify";

function SponsorModal({ show, handleClose, onSponsorSaved }) {
  const { userDetails, setSponsors } = useAuth();
  const [isSaving, setIsSaving] = useState(false);
  const [sponsorData, setSponsorData] = useState({
    name: "",
    email: "",
    contactNumber: "",
    website: "",
    imageUrl: "",
  });
  const [sponsorImage, setSponsorImage] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setSponsorData({ ...sponsorData, [id]: value });
  };

  const handleImageDrop = useCallback((acceptedFiles) => {
    if (acceptedFiles && acceptedFiles.length) {
      const file = acceptedFiles[0];
      setSponsorImage(file);
      setImagePreview(URL.createObjectURL(file));
    }
  }, []);

  const removeImage = () => {
    setSponsorImage(null);
    setImagePreview(null);
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: handleImageDrop,
    accept: {
      'image/*': ['.png', '.jpg', '.jpeg', '.gif', '.webp']
    },
    maxFiles: 1,
  });

  const uploadSponsorImage = async () => {
    if (!sponsorImage) return null;

    const storageRef = ref(storage, `sponsorImages/${sponsorImage.name}`);
    const uploadTask = uploadBytesResumable(storageRef, sponsorImage);

    return new Promise((resolve, reject) => {
      uploadTask.on(
        "state_changed",
        null,
        (error) => {
          console.error("Sponsor image upload error:", error);
          toast.error("Failed to upload the sponsor image.");
          reject(error);
        },
        async () => {
          const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
          resolve(downloadURL);
        }
      );
    });
  };

  const saveSponsor = async () => {
    if (!sponsorData.name) {
      toast.error("Please enter a sponsor name");
      return;
    }

    try {
      setIsSaving(true);
      
      // Upload logo if exists
      let imageUrl = null;
      if (sponsorImage) {
        imageUrl = await uploadSponsorImage();
      }
      
      const sponsorToSave = {
        ...sponsorData,
        imageUrl: imageUrl,
        createdAt: Timestamp.now(),
        createdBy: userDetails.uid
      };
      
      // Add to Firebase sponsors collection
      const sponsorsCollection = collection(db, "sponsors");
      const docRef = await addDoc(sponsorsCollection, sponsorToSave);
      
      // Update the document with its own ID
      const sponsorDocRef = doc(db, "sponsors", docRef.id);
      await updateDoc(sponsorDocRef, {
        id: docRef.id
      });
      
      // Add to context with ID included
      const newSponsor = { 
        ...sponsorToSave, 
        id: docRef.id 
      };
      setSponsors(prev => [...prev, newSponsor]);
      
      toast.success("Sponsor saved successfully!");
      
      // Reset form
      setSponsorData({
        name: "",
        email: "",
        contactNumber: "",
        website: "",
        imageUrl: "",
      });
      setSponsorImage(null);
      setImagePreview(null);
      
      // Call the callback if provided
      if (onSponsorSaved) {
        onSponsorSaved(newSponsor);
      }
      
      // Close the modal
      handleClose();
    } catch (error) {
      console.error("Error saving sponsor:", error);
      toast.error("Failed to save sponsor");
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <Modal show={show} onHide={handleClose} centered backdrop="static">
      <Modal.Header closeButton>
        <Modal.Title>Create New Sponsor</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group className="mb-3">
            <Form.Label htmlFor="name">Sponsor Name <span className="text-danger">*</span></Form.Label>
            <Form.Control
              type="text"
              id="name"
              value={sponsorData.name}
              onChange={handleInputChange}
              required
            />
          </Form.Group>
          
          <Form.Group className="mb-3">
            <Form.Label htmlFor="email">Email</Form.Label>
            <Form.Control
              type="email"
              id="email"
              value={sponsorData.email}
              onChange={handleInputChange}
            />
          </Form.Group>
          
          <Form.Group className="mb-3">
            <Form.Label htmlFor="contactNumber">Contact Number</Form.Label>
            <Form.Control
              type="text"
              id="contactNumber"
              value={sponsorData.contactNumber}
              onChange={handleInputChange}
            />
          </Form.Group>
          
          <Form.Group className="mb-3">
            <Form.Label htmlFor="website">Website</Form.Label>
            <Form.Control
              type="url"
              id="website"
              value={sponsorData.website}
              onChange={handleInputChange}
            />
          </Form.Group>
          
          <Form.Group className="mb-3">
            <Form.Label>Sponsor Logo</Form.Label>
            <div
              {...getRootProps({
                className: "dropzone border rounded-3 p-3 text-center",
              })}
            >
              <input {...getInputProps()} />
              {imagePreview ? (
                <div>
                  <img
                    src={imagePreview}
                    alt="Sponsor Logo Preview"
                    style={{
                      maxWidth: "100%",
                      maxHeight: "150px",
                      marginBottom: "10px",
                    }}
                  />
                  <Button
                    variant="danger"
                    size="sm"
                    onClick={(e) => {
                      e.stopPropagation();
                      removeImage();
                    }}
                  >
                    Remove Logo
                  </Button>
                </div>
              ) : (
                <p className="mb-0">
                  Drag sponsor logo here, or click to upload
                </p>
              )}
            </div>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Cancel
        </Button>
        <Button 
          variant="primary" 
          onClick={saveSponsor}
          disabled={isSaving}
        >
          {isSaving ? (
            <>
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
                className="me-2"
              />
              Saving...
            </>
          ) : (
            "Save Sponsor"
          )}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default SponsorModal;
