import React, { useEffect, useState } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { useAuth } from "../../../stores/AuthContext";
import Announcement from "../../../components/announcement";
import { updateUser } from "../../../services/firebase_service";

function EditUser() {
  const id = useParams().id;
  const { users, userDetails, setUsers } = useAuth();
  const [user, setUser] = useState({});
  // Only include the specific settings properties we need
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    role: "",
    status: true,
    gender: "",
    phoneNumber: {
      countryDialCode: "27",
      countryFlag: "",
      countryIsoCode: "ZA",
      number: "",
      verified: false
    },
    settings: {
      preferedProvince: "",
    },
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    console.log("Users available:", users);
    const userData = users.find((u) => u.id === id);
    console.log("Found user data:", userData);
    setUser(userData || {});

    if (userData) {
      // Extract only the preferedProvince from settings
      const preferedProvince = userData.settings?.preferedProvince || "";
      
      setFormData({
        firstName: userData.firstName || "",
        lastName: userData.lastName || "",
        email: userData.email || "",
        role: userData.role || "user",
        status: userData.status || false,
        gender: userData.gender || "",
        phoneNumber: userData.phoneNumber || {
          countryDialCode: "27",
          countryFlag: "",
          countryIsoCode: "ZA",
          number: "",
          verified: false
        },
        // Create a clean settings object with only what we need
        settings: {
          preferedProvince: preferedProvince,
        },
      });
    }
  }, [id, users]);

  const handleSettingsChange = (e) => {
    const { name, value } = e.target;
    console.log("Settings field change:", name, value);
    setFormData({
      ...formData,
      settings: {
        // Only preserve the preferedProvince property
        preferedProvince: name === "preferedProvince" ? value : formData.settings.preferedProvince,
      }
    });
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    console.log(
      "Form field change:",
      name,
      type === "checkbox" ? checked : value
    );
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const handlePhoneNumberChange = (e) => {
    const phoneValue = e.target.value;
    // Remove leading zero if present
    const formattedNumber = phoneValue.startsWith('0') ? phoneValue.substring(1) : phoneValue;
    
    setFormData({
      ...formData,
      phoneNumber: {
        ...formData.phoneNumber,
        number: formattedNumber
      }
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError("");
    setSuccess("");

    try {
      // Create a clean version of formData with only the settings we want to update
      const dataToUpdate = {
        ...formData,
        settings: {
          // If user already has other settings, preserve them
          ...(user.settings || {}),
          // But override with our new value
          preferedProvince: formData.settings.preferedProvince,
        }
      };
      
      await updateUser(id, dataToUpdate);
      setUsers((users) => {
        const index = users.findIndex((u) => u.id === id);
        const updatedUsers = [...users];
        updatedUsers[index] = { id, ...dataToUpdate };
        return updatedUsers;
      });
      setSuccess("User updated successfully");
      navigate("/users");
    } catch (err) {
      setError(err.message || "Failed to update user");
    } finally {
      setLoading(false);
    }
  };

  const goBack = () => {
    navigate(-1);
  };

  return (
    <div>
      <Announcement />
      <div className="row">
        <div className="col-xl-12">
          <div className="card">
            <div className="card-header bg-transparent d-flex align-items-center justify-content-between">
              <div className="left">
                <h4 className="card-title">
                  Edit {user?.firstName} {user?.lastName}
                </h4>
              </div>
              <div className="right">
                <butt onClick={goBack} className="btn btn-primary">
                  <i className="ti ti-arrow-left"></i> Back
                </butt>
              </div>
            </div>
            <div className="card-body pt-0">
              {error && <div className="alert alert-danger">{error}</div>}
              {success && <div className="alert alert-success">{success}</div>}

              <form onSubmit={handleSubmit}>
                <div className="row mb-3">
                  <div className="col-md-6">
                    <label htmlFor="firstName" className="form-label">
                      First Name
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="firstName"
                      name="firstName"
                      value={formData.firstName}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="lastName" className="form-label">
                      Last Name
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="lastName"
                      name="lastName"
                      value={formData.lastName}
                      onChange={handleChange}
                      required
                    />
                  </div>
                </div>

                <div className="row mb-3">
                  <div className="col-md-6">
                    <label htmlFor="email" className="form-label">
                      Email
                    </label>
                    <input
                      type="email"
                      className="form-control"
                      id="email"
                      name="email"
                      value={formData.email}
                      disabled
                      readOnly
                    />
                    <small className="text-muted">
                      Email cannot be changed
                    </small>
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="role" className="form-label">
                      Role
                    </label>
                    <select
                      className="form-select"
                      id="role"
                      name="role"
                      value={formData.role}
                      onChange={handleChange}
                      disabled={userDetails.uid === user.uid ? true : false}
                    >
                      <option value="user">User</option>
                      <option value="admin">Admin</option>
                      <option value="organiser">Organiser</option>
                    </select>
                  </div>
                </div>
                
                <div className="row mb-3">
                  <div className="col-md-6">
                    <label htmlFor="gender" className="form-label">
                      Gender
                    </label>
                    <select
                      className="form-select"
                      id="gender"
                      name="gender"
                      value={formData.gender}
                      onChange={handleChange}
                    >
                      <option value="">Select Gender</option>
                      <option value="Male">Male</option>
                      <option value="Female">Female</option>
                      <option value="Other">Other</option>
                    </select>
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="phoneNumber" className="form-label">
                      Phone Number
                    </label>
                    <div className="input-group">
                      <span className="input-group-text">+{formData.phoneNumber.countryDialCode}</span>
                      <input
                        type="tel"
                        className="form-control"
                        id="phoneNumber"
                        name="phoneNumber"
                        value={formData.phoneNumber.number}
                        onChange={handlePhoneNumberChange}
                        placeholder="Phone number (without leading 0)"
                      />
                    </div>
                    <small className="text-muted">
                      Enter without leading zero (e.g. for 072 123 4567, enter 72 123 4567)
                    </small>
                  </div>
                </div>

                <div className="row mb-3">
                  <div className="col-md-6">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="status"
                        name="status"
                        checked={formData.status}
                        onChange={handleChange}
                      />
                      <label className="form-check-label" htmlFor="status">
                        Active
                      </label>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="preferedProvince" className="form-label">
                      Preferred Province
                    </label>
                    <select
                      className="form-select"
                      id="preferedProvince"
                      name="preferedProvince"
                      value={formData.settings?.preferedProvince || ""}
                      onChange={handleSettingsChange}
                    >
                      <option value="">Select Province</option>
                      <option value="All">All</option>
                      <option value="Eastern Cape">Eastern Cape</option>
                      <option value="Free State">Free State</option>
                      <option value="Gauteng">Gauteng</option>
                      <option value="KwaZulu-Natal">KwaZulu-Natal</option>
                      <option value="Limpopo">Limpopo</option>
                      <option value="Mpumalanga">Mpumalanga</option>
                      <option value="Northern Cape">Northern Cape</option>
                      <option value="North West">North West</option>
                      <option value="Western Cape">Western Cape</option>
                    </select>
                  </div>
                </div>

                <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                  <Link to="/users" className="btn btn-secondary me-2">
                    Cancel
                  </Link>
                  <button
                    type="submit"
                    className="btn btn-primary"
                    disabled={loading}
                  >
                    {loading ? "Updating..." : "Update User"}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditUser;
