import React, { useState, useEffect } from "react";
import "./notificationStyles.css";
import { useAuth } from "../../stores/AuthContext";
import DataTable from "datatables.net-react";
import DT from "datatables.net-dt";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";

import "bootstrap/dist/js/bootstrap.bundle.min";

// Replace individual imports with a single import from react-bootstrap
import { Modal, Button } from "react-bootstrap";

// Initialize DataTable
DataTable.use(DT);

function Notifications() {
  const { notifications, users } = useAuth();
  const [notificationsList, setNotificationsList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [tableData, setTableData] = useState([]);
  const navigate = useNavigate();

  // Add state for modal
  const [showModal, setShowModal] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectedNotificationTitle, setSelectedNotificationTitle] =
    useState("");

  const tableOptions = {
    order: [[2, "desc"]], // Sort by date (descending)
    paging: true,
    searching: true,
    lengthMenu: [10, 25, 50, 75, 100],
    language: {
      emptyTable: "No notifications available to display",
    },
    columnDefs: [
      {
        targets: [0], // ID column
        visible: false,
      },
      {
        targets: [1], // Title and body
        render: function (data, type, row) {
          return `<div class="d-flex">
            <div class="desc">
              <div class="d-flex align-items-center gap-2">
                <span class="text-primary text-body fw-bold">${data.title}</span>
              </div>
              <small class="text-muted">${data.message}</small>
            </div>
          </div>`;
        },
      },
      {
        targets: [2], // Date column
        render: function (data, type, row) {
          if (type === "sort" || type === "type") {
            return new Date(data).getTime();
          }
          return `<span class="text-body fw-bold">${data}</span>`;
        },
      },
      {
        targets: [3], // User column (now recipients count)
        render: function (data, type, row) {
          if (!data || data.length === 0)
            return `<span class="text-muted">Unknown</span>`;
          return `<div class="d-flex align-items-center">
            <span class="badge bg-info rounded-pill">${data.length}</span>
            <span class="ms-2 cursor-pointer text-primary" onClick="showUsersList('${row[0]}')">
              View Recipients
            </span>
          </div>`;
        },
      },
      {
        targets: [4], // Status column
        render: function (data, type, row) {
          // Calculate if all grouped notifications are read
          const allRead = Array.isArray(data)
            ? data.every((status) => status === true)
            : !!data;

          // Calculate if some but not all are read
          const partiallyRead = Array.isArray(data)
            ? data.some((status) => status === true) &&
              !data.every((status) => status === true)
            : false;

          if (partiallyRead) {
            return `<span class="badge bg-info">Partially Read</span>`;
          }

          return allRead
            ? `<span class="badge bg-success">Read</span>`
            : `<span class="badge bg-warning">Unread</span>`;
        },
      },
      {
        targets: [5], // Action column
        sortable: false,
        render: function (data, type, row) {
          return `<div class="dropdown">
            <button type="button" class="btn p-0 dropdown-toggle hide-arrow" data-bs-toggle="dropdown" aria-expanded="false">
              <i class="ti ti-dots-vertical"></i>
            </button>
            <div class="dropdown-menu">
              <a class="dropdown-item waves-effect cursor-pointer" onClick="markAsRead('${
                row[0]
              }')">
                <i class="ti ti-check me-1"></i> Mark All as Read
              </a>
              ${
                row[6]
                  ? `<a class="dropdown-item waves-effect cursor-pointer" onClick="navigateToItem('${row[6]}')">
                <i class="ti ti-eye me-1"></i> View Details
              </a>`
                  : ""
              }
              <a class="dropdown-item waves-effect cursor-pointer" onClick="deleteNotification('${
                row[0]
              }')">
                <i class="ti ti-trash me-1"></i> Delete All
              </a>
            </div>
          </div>`;
        },
      },
    ],
  };

  useEffect(() => {
    const fetchNotifications = async () => {
      try {
        setNotificationsList(notifications || []);
        setLoading(false);
      } catch (err) {
        setError("Failed to fetch notifications");
        setLoading(false);
      }
    };

    fetchNotifications();
  }, [notifications]);

  useEffect(() => {
    // Group notifications by title and body
    const groupedNotifications = notificationsList.reduce(
      (acc, notification) => {
        const key = `${notification.title}__${notification.body}`;

        if (!acc[key]) {
          acc[key] = {
            id: key, // Use the key as the ID for the group
            title: notification.title || "Notification",
            body: notification.body || "",
            notifications: [],
            userIds: [],
            timestamps: [],
            targetId: notification.data?.id || "",
          };
        }

        acc[key].notifications.push(notification);
        acc[key].userIds.push(notification.userId);

        if (notification.timestamp?.seconds) {
          acc[key].timestamps.push(notification.timestamp.seconds);
        }

        return acc;
      },
      {}
    );

    // Convert the grouped notifications object to an array
    const groupedArray = Object.values(groupedNotifications);

    setTableData(
      groupedArray.map((group) => {
        // Get the most recent timestamp
        const latestTimestamp = Math.max(...group.timestamps);
        const formattedTimestamp = latestTimestamp
          ? new Date(latestTimestamp * 1000).toLocaleDateString("en-GB", {
              day: "2-digit",
              month: "short",
              year: "numeric",
              hour: "2-digit",
              minute: "2-digit",
            })
          : "Unknown";

        // Find user data for all users in this notification group
        const relatedUsers = group.userIds
          .map((userId) => users?.find((u) => u.uid === userId))
          .filter(Boolean);

        // Get read status of all notifications in the group
        const readStatuses = group.notifications.map((n) => n.read);

        return [
          group.id,
          {
            title: group.title,
            message: group.body,
          },
          formattedTimestamp,
          relatedUsers, // Pass the array of users
          readStatuses, // Pass all read statuses
          group.id,
          group.targetId,
        ];
      })
    );
  }, [notificationsList, users]);

  useEffect(() => {
    window.markAsRead = async (groupId) => {
      try {
        // Update all notifications in the group
        const updated = notificationsList.map((n) => {
          if (`${n.title}__${n.body}` === groupId) {
            return { ...n, read: true };
          }
          return n;
        });
        setNotificationsList(updated);
        toast.success("Notifications marked as read!");
        // In a real app, you would update all notifications in your database
      } catch (error) {
        toast.error("Error updating notification status");
      }
    };

    window.viewUser = (userId) => {
      if (!userId) return;
      navigate(`/user/${userId}`);
    };

    window.showUsersList = (groupId) => {
      const group = notificationsList.filter(
        (n) => `${n.title}__${n.body}` === groupId
      );
      if (!group.length) return;

      // Find users associated with these notifications
      const notificationUsers = group
        .map((notification) =>
          users?.find((u) => u.uid === notification.userId)
        )
        .filter(Boolean);

      setSelectedUsers(notificationUsers);
      setSelectedNotificationTitle(group[0].title || "Notification");
      setShowModal(true);
    };

    window.navigateToItem = (itemId) => {
      if (!itemId) return;
      window.open(`/item/${itemId}`, "_blank");
    };

    window.deleteNotification = async (groupId) => {
      if (
        window.confirm(
          "Are you sure you want to delete all these notifications?"
        )
      ) {
        try {
          // Delete all notifications in the group
          const updatedNotifications = notificationsList.filter(
            (n) => `${n.title}__${n.body}` !== groupId
          );
          setNotificationsList(updatedNotifications);
          toast.success("Notifications deleted successfully!");
          // In a real app, you would delete from your database
        } catch (error) {
          toast.error("Error: Unable to delete notifications");
        }
      }
    };

    // Clean up the functions when the component unmounts
    return () => {
      delete window.markAsRead;
      delete window.navigateToItem;
      delete window.viewUser;
      delete window.deleteNotification;
      delete window.showUsersList;
    };
  }, [notificationsList, users]);

  // Function to handle closing the modal
  const handleCloseModal = () => setShowModal(false);

  if (loading) {
    return (
      <div className="card">
        <div className="card-body">
          <div className="text-center p-4">
            <div className="spinner-border text-primary" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
            <p className="mt-3">Loading notifications...</p>
          </div>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="card">
        <div className="card-body">
          <div className="alert alert-danger" role="alert">
            {error}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div>
      <div className="row">
        <div className="col-xl-12">
          <div className="card">
            <div className="card-header bg-transparent">
              <div className="bg-transparent d-flex align-items-center justify-content-between mb-3">
                <div className="left">
                  <h4 className="">Notifications</h4>
                  <p className="mb-0">View and manage your notifications</p>
                </div>
                <div className="right">
                  <Link className="btn btn-primary" to="/notifications/new">
                    <i className="ti ti-plus"></i> New
                  </Link>
                </div>
              </div>
            </div>
            <div className="card-body pt-0">
              <div className="table-responsive rounded-4" data-bs-theme="dark">
                {notificationsList.length === 0 ? (
                  <div className="alert alert-info">
                    <div className="d-flex align-items-center gap-2">
                      <i className="ti ti-info"></i>
                      <span>No notifications to display</span>
                    </div>
                  </div>
                ) : (
                  <DataTable
                    className="table text-nowrap notificationsTable table-dark mb-0 align-middle"
                    options={tableOptions}
                    data={tableData}
                  >
                    <thead>
                      <tr>
                        <th className="fs-4 fw-semibold mb-0">ID</th>
                        <th className="fs-4 fw-semibold mb-0">Title</th>
                        <th className="fs-4 fw-semibold mb-0">Date</th>
                        <th className="fs-4 fw-semibold mb-0">Recipients</th>
                        <th className="fs-4 fw-semibold mb-0">Status</th>
                        <th className="fs-4 fw-semibold mb-0">Actions</th>
                      </tr>
                    </thead>
                  </DataTable>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Modal for displaying users list */}
      <Modal show={showModal} onHide={handleCloseModal} size="lg" centered>
        <Modal.Header closeButton>
          <Modal.Title>Recipients for: {selectedNotificationTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="table-responsive">
            <table className="table">
              <thead>
                <tr>
                  <th>User</th>
                  <th>Email</th>
                  <th>View</th>
                </tr>
              </thead>
              <tbody>
                {selectedUsers.map((user, index) => (
                  <tr key={index}>
                    <td>
                      <div className="d-flex align-items-center">
                        {user.photoURL ? (
                          <img
                            src={user.photoURL}
                            className="rounded-circle me-2"
                            width="30"
                            height="30"
                            alt={`${user.firstName} ${user.lastName}`}
                          />
                        ) : (
                          <div className="avatar-initial rounded-circle bg-label-info me-2">
                            <span className="avatar-content">
                              {(user.firstName || user.email || "?")
                                .charAt(0)
                                .toUpperCase()}
                            </span>
                          </div>
                        )}
                        <span>
                          {user.firstName} {user.lastName}
                        </span>
                      </div>
                    </td>
                    <td>{user.email}</td>
                    <td>
                      <Button
                        variant="outline-primary"
                        size="sm"
                        onClick={() => {
                          handleCloseModal();
                          navigate(`/user/${user.uid}`);
                        }}
                      >
                        <i className="ti ti-eye me-1"></i> View Profile
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default Notifications;
