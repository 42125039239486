import React, { useEffect, useState } from "react";
import { useAuth } from "../../stores/AuthContext";
import { json, Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { deleteProduct, updateProduct } from "../../services/firebase_service";
import Announcement from "../../components/announcement";

import DataTable from "datatables.net-react";
import DT from "datatables.net-dt";

DataTable.use(DT);

function Products() {
  const { products, user, setProducts } = useAuth();
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [tableData, setTableData] = useState({});

  const tableOptions = {
    order: [[2, "asc"]],
    paging: true,
    searching: true,
    lengthMenu: [10, 25, 50, 75, 100],
    language: {
      emptyTable: "No products available to display",
    },
    columnDefs: [
      {
        targets: [0, 1],
        visible: false,
      },
      {
        targets: [2], // Column for the product title
        render: function (data, type, row) {
          return data
            ? `<div class="d-flex"><img src="${
                row[1]
              }" class="rounded-circle cursor-pointer me-2 object-fit-cover" width="40" height="40" onClick="navigateToProduct('${
                row[0]
              }')" /><div class="desc"><span class="text-primary text-body fw-bold underLink" style="cursor:pointer;" onClick="navigateToProduct('${
                row[0]
              }')">${data.split(",")[0]}</span><br/><small class="text-muted">${
                data.split(",")[1]
              }</small></div></div>`
            : ``;
        },
      },
      {
        targets: [5],
        render: function (data, type, row) {
          if (type === "sort" || type === "type") {
            return new Date(data.split(" ").join(" ")).getTime();
          }
          return `<span class="text-body fw-bold">${data}</span>`;
        },
      },
      {
        targets: [6],
        render: function (data, type, row) {
          return `<span class="text-body fw-bold">${new Intl.NumberFormat('en-ZA', { 
            style: 'currency', 
            currency: 'ZAR' 
          }).format(data)}</span>`;
        },
      },
      {
        targets: [7],
        sortable: false,
        render: function (data, type, row) {
          return `<div class="form-check form-switch py-2">
                      <input class="form-check-input cursor-pointer" type="checkbox" id="status${
                        row[0]
                      }" ${
            data == "approved" ? "checked" : ""
          } onChange="window.updateStatus('${row[0]}')">
                    </div>`;
        },
      },
      {
        targets: [8],
        sortable: false,
        render: function (data, type, row) {
          return `<div class="dropdown">
                      <button type="button" class="btn p-0 dropdown-toggle hide-arrow" data-bs-toggle="dropdown" aria-expanded="false">
                        <i class="ti ti-dots-vertical"></i>
                      </button>
                      <div class="dropdown-menu" style="">
                        <a class="dropdown-item waves-effect cursor-pointer" onClick="editProduct('${row[0]}')"><i class="ti ti-pencil me-1"></i> Edit</a>
                        <a class="dropdown-item waves-effect cursor-pointer" onClick="deleteProduct('${row[0]}')"><i class="ti ti-trash me-1"></i> Delete</a>
                      </div>
                    </div>`;
        },
      },
    ],
  };

  const navigate = useNavigate();

  useEffect(() => {
    if (products) {
      setFilteredProducts(products);
      setLoading(false);
    }

    let approvedProducts = products.filter((product) => product.status === "approved");
    console.log("Products", JSON.stringify(approvedProducts));
  }, [products]);

  useEffect(() => {
    setTableData(
      filteredProducts.map((product) => {
        const productContent = product.description.replace(
          /<\/?[^>]+(>|$)/g,
          ""
        );

        return [
          product.id,
          product.images[0] ?? "https://via.placeholder.com/150",
          `${product.itemName},${productContent.substring(0, 50)}` ?? "",
          product.category ?? "",
          (product.location != null && product.location != '') ? product.location : "Unknown",
          product.timestamp
            ? new Date(product.timestamp.seconds * 1000).toLocaleString(
                "en-GB",
                {
                  day: "numeric",
                  month: "short",
                  year: "numeric",
                  hour: "2-digit",
                  minute: "2-digit",
                }
              )
            : "",
          product.price ?? "",
          product.status ?? "",
          product.id,
        ];
      })
    );
  }, [filteredProducts]);

  useEffect(() => {
    window.updateStatus = async (productId) => {
      const product = products.find((product) => product.id === productId);
      product.status = product.status === "approved" ? "pending" : "approved";

      try {
        await updateProduct(productId, product);
        const updatedProducts = products.map((e) =>
          e.id === productId ? product : e
        );
        setProducts(updatedProducts);
        if (product.published) {
          toast.success("Product approved!");
        } else {
          toast.success("Product dissapproved!");
        }
      } catch (error) {
        toast.error("Error: Unable to update product status");
        console.error("Error updating product status", error);
      }
    };

    window.deleteProduct = async (productId) => {
      if (window.confirm("Are you sure you want to delete this product?")) {
        try {
          await deleteProduct(productId);
          const updatedProducts = products.filter((e) => e.id !== productId);
          setProducts(updatedProducts);
          toast.success("Product deleted successfully!");
        } catch (error) {
          toast.error("Error: Unable to delete product");
          console.error("Error deleting product", error);
        }
      }
    };

    window.editProduct = (productId) => {
      navigate(`/product/${productId}/edit`);
    };

    window.navigateToProduct = (productId) => {
      window.open(`https://share.funduro.app/product/${productId}`, "_blank");
    };

    // Clean up the functions when the component unmounts
    return () => {
      delete window.navigateToProduct;
      delete window.updateStatus;
      delete window.editProduct;
      delete window.deleteProduct;
    };
  }, [navigate, products, setProducts]);

  return (
    <div>
      <Announcement />
      <div className="row">
        <div className="col-xl-12">
          <div className="card">
            <div className="card-header bg-transparent">
              <div className="bg-transparent d-flex align-items-center justify-content-between mb-3">
                <div className="left">
                  <h4 className="">Products</h4>
                  <p className="mb-0">
                    Manage and view all registered products
                  </p>
                </div>
                <div className="right">
                  <Link to="/product/new" className="btn btn-primary">
                    <i className="ti ti-plus"></i> New
                  </Link>
                </div>
              </div>
            </div>
            <div className="card-body pt-0">
              <div className="table-responsive rounded-4" data-bs-theme="dark">
                {!loading ? (
                  <DataTable
                    className="table text-nowrap usersTable table-dark mb-0 align-middle"
                    options={tableOptions}
                    data={tableData}
                  >
                    <thead>
                      <tr>
                        <th className="fs-4 fw-semibold mb-0">ID</th>
                        <th className="fs-4 fw-semibold mb-0">Image</th>
                        <th className="fs-4 fw-semibold mb-0">Title</th>
                        <th className="fs-4 fw-semibold mb-0">Category</th>
                        <th className="fs-4 fw-semibold mb-0">Location</th>
                        <th className="fs-4 fw-semibold mb-0">Added</th>
                        <th className="fs-4 fw-semibold mb-0">Price</th>
                        <th className="fs-4 fw-semibold mb-0">Published</th>
                        <th>&nbsp;</th>
                      </tr>
                    </thead>
                  </DataTable>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Products;
