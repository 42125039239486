import { Outlet, Navigate } from "react-router-dom";
import { useAuth } from "../stores/AuthContext";

const ProtectedRoutes = () => {
  const { user } = useAuth();

  // useEffect(() => {
  //   if (
  //     user &&
  //     userDetails != null &&
  //     userDetails.role !== "admin" &&
  //     userDetails.role !== "organizer"
  //   ) {
  //     logout();
  //   }
  // }, [user, logout, userDetails]);

  return user ? <Outlet /> : <Navigate to="/login" />;
};

export default ProtectedRoutes;
