import React, { useEffect, useState, useCallback } from "react";
import { useAuth } from "../../stores/AuthContext";
import { Link, useNavigate, useParams } from "react-router-dom";
import { collection, query, getDocs } from "firebase/firestore";
import DataTable from "datatables.net-react";
import DT from "datatables.net-dt";
import { toast } from "react-toastify";
import { db } from "../../firebase";
import * as XLSX from "xlsx";
import { Modal } from "bootstrap";
import {
  notifyMultipleUsers,
} from "../../services/firebase_service";

DataTable.use(DT);

function EventEntries() {
  const { id } = useParams();
  const { events, user, users, userDetails } = useAuth();
  const [entries, setEntries] = useState([]);
  const [loading, setLoading] = useState(true);
  const [tableData, setTableData] = useState({});
  const currentEvent = events.find((event) => event.id === id);
  const navigate = useNavigate();

  // Notification state
  const [showNotificationModal, setShowNotificationModal] = useState(false);
  const [notificationModal, setNotificationModal] = useState(null);
  const [notificationLoading, setNotificationLoading] = useState(false);
  const [notificationData, setNotificationData] = useState({
    title: "",
    body: "",
    notificationType: "all", // "all" for all entries, "nonEntrants" for users who haven't entered
    userId: "",
    message: {
      data: {},
      clickAction: "SINGLE_EVENT",
    },
  });
  const [notificationErrors, setNotificationErrors] = useState({});
  const [nonEntrantUsers, setNonEntrantUsers] = useState([]);
  const [nonEntrantCount, setNonEntrantCount] = useState(0);

  const tableOptions = {
    order: [[9, "desc"]],
    paging: true,
    searching: true,
    lengthMenu: [10, 25, 50, 75, 100],
    language: {
      emptyTable: "No entries available to display",
    },
    columnDefs: [
      {
        targets: [0],
        visible: false,
      },
      {
        targets: [1, 5, 6, 7, 8],
        sortable: false,
      },
      {
        targets: [3],
        render: function (data, type, row) {
          return `<a href="mailto:${data}" class="underLink" target="_blank">${data}</a>`;
        },
      },
      {
        targets: [4],
        render: function (data, type, row) {
          return `<a href="tel:${data}" class="underLink" target="_blank">${data}</a>`;
        },
      },
      {
        targets: [9],
        render: function (data, type, row) {
          if (type === "sort" || type === "type") {
            return new Date(data).getTime();
          }
          return data;
        },
      },
    ],
  };

  useEffect(() => {
    const fetchEntries = async () => {
      try {
        const entriesRef = collection(db, `events/${id}/entries`);
        const entriesQuery = query(entriesRef);
        const querySnapshot = await getDocs(entriesQuery);

        const entriesData = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        setEntries(entriesData);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching entries:", error);
        toast.error("Error loading entries");
        setLoading(false);
      }
    };

    if (id) {
      fetchEntries();
    }
  }, [id]);

  // Fixed useEffect to prevent error on refresh
  useEffect(() => {
    // Only run this check when events data has been loaded
    if (!events || events.length === 0) {
      return; // Exit early if events haven't loaded yet
    }

    if (!currentEvent) {
      // If event ID doesn't exist in loaded events, redirect
      navigate("/events");
      toast.error("Event not found");
      return;
    }

    if (currentEvent.createdBy !== user?.uid && userDetails?.role !== "admin") {
      // Check user permissions
      navigate("/events");
      toast.error("You don't have permission to view this page");
    }
  }, [currentEvent, events, user?.uid, userDetails?.role, navigate]);

  const getUserDetails = useCallback((id) => {
    const user = users.find((user) => user.uid === id);
    return user;
  }, [users]);

  useEffect(() => {
    const newTableData = entries.map((entry) => {
      const userDetail = getUserDetails(entry.userId) || {};
      return [
        entry.id,
        userDetail?.registrationDetails?.idNumber || "Unknown",
        `${userDetail?.firstName || ""} ${userDetail?.lastName || ""}`.trim() ||
          "Unknown",
        userDetail?.email || "Unknown",
        userDetail?.phoneNumber?.number
          ? `0${userDetail.phoneNumber.number}`
          : "Unknown",
        `${entry.details?.emergencyContactName}  <br/>0${entry.details?.emergencyContactPhone} <br/>${entry.details?.emergencyContactRelation}`,
        `${entry.details?.medicalAidName}  <br/>0${entry.details?.medicalAidNumber}`,
        entry.details?.allergies || "None",
        entry.details?.medications || "None",
        entry.createdAt?.seconds
          ? new Date(entry.createdAt.seconds * 1000).toLocaleDateString(
              "en-GB",
              {
                day: "2-digit",
                month: "short",
                year: "numeric",
                hour: "2-digit",
                minute: "2-digit",
              }
            )
          : "Unknown",
      ];
    });
    setTableData(newTableData);

  }, [entries, users, getUserDetails]); // Added getUserDetails to dependencies

  const createMarkup = (html) => {
    return { __html: html };
  };

  const exportToCSV = () => {
    if (entries.length === 0) {
      toast.error("No entries to export");
      return;
    }

    try {
      // Create CSV header
      let csvContent =
        "ID Number,Name,Email,Phone,Emergency Contact,Medical Aid,Allergies,Medications,Registration Date\n";

      // Add data rows
      entries.forEach((entry) => {
        const userDetail = getUserDetails(entry.userId) || {};
        const row = [
          `"${userDetail?.registrationDetails?.idNumber || "Unknown"}"`,
          `"${
            `${userDetail?.firstName || ""} ${
              userDetail?.lastName || ""
            }`.trim() || "Unknown"
          }"`,
          `"${userDetail?.email || "Unknown"}"`,
          `"${
            userDetail?.phoneNumber?.number
              ? `0${userDetail.phoneNumber.number}`
              : "Unknown"
          }"`,
          `"${entry.details?.emergencyContactName || ""} - 0${
            entry.details?.emergencyContactPhone || ""
          } (${entry.details?.emergencyContactRelation || ""})"`,
          `"${entry.details?.medicalAidName || ""} - ${
            entry.details?.medicalAidNumber || ""
          }"`,
          `"${entry.details?.allergies || "None"}"`,
          `"${entry.details?.medications || "None"}"`,
          `"${
            entry.createdAt?.seconds
              ? new Date(entry.createdAt.seconds * 1000).toLocaleDateString(
                  "en-GB",
                  {
                    day: "2-digit",
                    month: "short",
                    year: "numeric",
                    hour: "2-digit",
                    minute: "2-digit",
                  }
                )
              : "Unknown"
          }"`,
        ].join(",");
        csvContent += row + "\n";
      });

      // Create a blob and download link
      const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.setAttribute("href", url);
      link.setAttribute(
        "download",
        `${currentEvent?.title || "event"}_entries_${new Date()
          .toISOString()
          .slice(0, 10)}.csv`
      );
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      toast.success("Entries exported successfully");
    } catch (error) {
      console.error("Export error:", error);
      toast.error("Failed to export entries");
    }
  };

  const exportToXLSX = () => {
    if (entries.length === 0) {
      toast.error("No entries to export");
      return;
    }

    try {
      // Prepare data for XLSX
      const xlsxData = entries.map((entry) => {
        const userDetail = getUserDetails(entry.userId) || {};
        return {
          "ID Number": userDetail?.registrationDetails?.idNumber || "Unknown",
          Name:
            `${userDetail?.firstName || ""} ${
              userDetail?.lastName || ""
            }`.trim() || "Unknown",
          Email: userDetail?.email || "Unknown",
          Phone: userDetail?.phoneNumber?.number
            ? `0${userDetail.phoneNumber.number}`
            : "Unknown",
          "Emergency Contact": `${
            entry.details?.emergencyContactName || ""
          } - 0${entry.details?.emergencyContactPhone || ""} (${
            entry.details?.emergencyContactRelation || ""
          })`,
          "Medical Aid": `${entry.details?.medicalAidName || ""} - ${
            entry.details?.medicalAidNumber || ""
          }`,
          Allergies: entry.details?.allergies || "None",
          Medications: entry.details?.medications || "None",
          "Registration Date": entry.createdAt?.seconds
            ? new Date(entry.createdAt.seconds * 1000).toLocaleDateString(
                "en-GB",
                {
                  day: "2-digit",
                  month: "short",
                  year: "numeric",
                  hour: "2-digit",
                  minute: "2-digit",
                }
              )
            : "Unknown",
        };
      });

      // Create workbook and worksheet
      const worksheet = XLSX.utils.json_to_sheet(xlsxData);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Entries");

      // Auto-size columns
      const colWidths = [
        { wch: 15 }, // ID Number
        { wch: 25 }, // Name
        { wch: 30 }, // Email
        { wch: 15 }, // Phone
        { wch: 40 }, // Emergency Contact
        { wch: 30 }, // Medical Aid
        { wch: 20 }, // Allergies
        { wch: 20 }, // Medications
        { wch: 25 }, // Registration Date
      ];
      worksheet["!cols"] = colWidths;

      // Generate filename
      const fileName = `${currentEvent?.title || "event"}_entries_${new Date()
        .toISOString()
        .slice(0, 10)}.xlsx`;

      // Save file
      XLSX.writeFile(workbook, fileName);

      toast.success("Entries exported successfully to Excel");
    } catch (error) {
      console.error("XLSX Export error:", error);
      toast.error("Failed to export entries to Excel");
    }
  };

  // Find users who haven't entered but are in the same province as the event
  useEffect(() => {
    if (users && entries && currentEvent) {
      // Get all users who have entered
      const enteredUserIds = entries.map((entry) => entry.userId);

      // Get the province from the event
      const eventProvince = currentEvent.location.split(", ")[1] || "All";

      // Find users who haven't entered but are in the same province
      const nonEntrants = users.filter((user) => {
        // Skip users who have already entered
        if (enteredUserIds.includes(user.uid)) return false;

        // Include users if they have the same province preference or "All"
        const userProvince = user.settings.preferedProvince;
        return userProvince === "All" || userProvince === eventProvince;
      });

      setNonEntrantUsers(nonEntrants);
      setNonEntrantCount(nonEntrants.length);
    }
  }, [users, entries, currentEvent]);

  // Initialize notification modal - Also ensure currentEvent exists before accessing it
  useEffect(() => {
    if (typeof window !== "undefined") {
      const modalElement = document.getElementById("notificationModal");
      if (modalElement) {
        const modal = new Modal(modalElement);
        setNotificationModal(modal);

        // Initialize with event data if available
        if (currentEvent) {
          setNotificationData((prev) => ({
            ...prev,
            message: {
              ...prev.message,
              clickAction: "SINGLE_EVENT", // Always set to SINGLE_EVENT
              data: { event: currentEvent },
            },
            targetId: currentEvent.id,
          }));
        }
      }
    }
  }, [currentEvent]);

  // Handle showing/hiding the notification modal
  const toggleNotificationModal = (show) => {
    if (show) {
      setNotificationData((prev) => ({
        ...prev,
        title: `${currentEvent?.title || "Event"} Update`,
        body: "",
        notificationType: "all",
        userId: "",
      }));
      setNotificationErrors({});
      notificationModal.show();
      setShowNotificationModal(true);
    } else {
      notificationModal.hide();
      setShowNotificationModal(false);
    }
  };

  // Handle notification form changes
  const handleNotificationChange = (e) => {
    const { name, value } = e.target;

    if (name === "clickAction") {
      // Update action type
      setNotificationData((prev) => ({
        ...prev,
        message: {
          ...prev.message,
          clickAction: value,
        },
      }));
    } else {
      setNotificationData((prev) => ({
        ...prev,
        [name]: value,
      }));
    }

    // Clear error when field is edited
    if (notificationErrors[name]) {
      setNotificationErrors((prev) => ({
        ...prev,
        [name]: "",
      }));
    }
  };

  // Validate notification form
  const validateNotificationForm = () => {
    const newErrors = {};

    if (!notificationData.title.trim()) {
      newErrors.title = "Title is required";
    }

    if (!notificationData.body.trim()) {
      newErrors.body = "Message body is required";
    }

    setNotificationErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  // Handle notification submission
  const handleNotificationSubmit = async (e) => {
    e.preventDefault();

    if (!validateNotificationForm()) {
      toast.error("Please fix the errors in the form");
      return;
    }

    setNotificationLoading(true);

    try {
      const notificationPayload = {
        title: notificationData.title,
        body: notificationData.body,
        data: {
          click_action: "SINGLE_EVENT", // Always use SINGLE_EVENT
        },
      };

      // Add event data
      if (currentEvent) {
        notificationPayload.data.id = currentEvent.id;
        notificationPayload.data.eventId = currentEvent.id;
        notificationPayload.data.eventTitle = currentEvent.title;
        notificationPayload.data.eventLocation = currentEvent.location;
        notificationPayload.data.eventDate = currentEvent.date
          ? new Date(currentEvent.date.seconds * 1000).toISOString()
          : "";
        notificationPayload.data.eventImageUrl = currentEvent.imageUrl || "";
      }

      let success = false;

      if (notificationData.notificationType === "all") {
        // Send to all users who have entries
        const entryUserIds = entries.map((entry) => entry.userId);

        if (entryUserIds.length === 0) {
          toast.warning("No users to notify");
          setNotificationLoading(false);
          return;
        }

        success = await notifyMultipleUsers(entryUserIds, notificationPayload);

        if (success !== false) {
          toast.success(
            `Notification sent to all ${entries.length} participants!`
          );
          toggleNotificationModal(false);
        }
      } else if (notificationData.notificationType === "nonEntrants") {
        // Send to users who haven't entered but are in the same province
        const nonEntrantUserIds = nonEntrantUsers.map((user) => user.uid);

        if (nonEntrantUserIds.length === 0) {
          toast.warning("No non-entrant users found in this province");
          setNotificationLoading(false);
          return;
        }

        success = await notifyMultipleUsers(
          nonEntrantUserIds,
          notificationPayload
        );

        if (success !== false) {
          toast.success(
            `Notification sent to ${nonEntrantUserIds.length} potential participants!`
          );
          toggleNotificationModal(false);
        }
      }
    } catch (error) {
      console.error("Error sending notification:", error);
      toast.error(
        `Failed to send notification: ${error.message || "Please try again"}`
      );
    } finally {
      setNotificationLoading(false);
    }
  };

  return (
    <>
      <div className="card">
        <div className="card-header bg-transparent">
          <div className="d-flex align-items-center justify-content-between">
            <div className="left">
              <h4 className="mb-0">Event Entries</h4>
              <p className="mb-0">Manage and view all entries for this event</p>
            </div>
            <div className="right d-flex gap-2">
              <button
                className="btn btn-info"
                onClick={() => toggleNotificationModal(true)}
                disabled={loading || !currentEvent}
              >
                <i className="ti ti-bell"></i> Send a Notification
              </button>
              {/* Fixed export dropdown */}
              <div className="dropdown">
                <button
                  className={`btn btn-success dropdown-toggle ${
                    entries.length === 0 && "hidden"
                  }`}
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  disabled={entries.length === 0 || loading}
                >
                  <i className="ti ti-file-export"></i> Export
                </button>
                <ul className="dropdown-menu dropdown-menu-end">
                  <li>
                    <button className="dropdown-item" onClick={exportToCSV}>
                      <i className="ti ti-file-text me-2"></i>CSV Format
                    </button>
                  </li>
                  <li>
                    <button className="dropdown-item" onClick={exportToXLSX}>
                      <i className="ti ti-file-spreadsheet me-2"></i>Excel
                      Format
                    </button>
                  </li>
                </ul>
              </div>
              <Link to="/events" className="btn btn-primary">
                <i className="ti ti-arrow-left"></i> Back to Events
              </Link>
            </div>
          </div>
        </div>

        {loading ? (
          <div className="card-body text-center py-5">
            <div className="spinner-border text-primary" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
            <p className="mt-2">Loading event details...</p>
          </div>
        ) : currentEvent ? (
          <>
            <div className="event-summary mb-4 px-4">
              <div className="row">
                <div className="col-md-3">
                  {currentEvent.imageUrl && (
                    <img
                      src={currentEvent.imageUrl}
                      alt={currentEvent.title}
                      className="img-fluid rounded"
                      style={{ maxHeight: "200px", objectFit: "cover" }}
                    />
                  )}
                </div>
                <div className="col-md-9">
                  <h3>{currentEvent.title}</h3>
                  <div
                    className="text-muted limitLines"
                    dangerouslySetInnerHTML={createMarkup(
                      currentEvent.description
                    )}
                  />
                  <div className="d-flex gap-3">
                    <span className="badge bg-primary text-secondary">
                      {new Date(
                        currentEvent.date.seconds * 1000
                      ).toLocaleDateString("en-GB", {
                        day: "2-digit",
                        month: "short",
                        year: "numeric",
                      })}
                    </span>
                    <span className="badge bg-secondary text-primary">
                      {entries.length} Entries
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="table-responsive rounded-4" data-bs-theme="dark">
              <DataTable
                className="table text-nowrap entriesTable table-dark mb-0 align-middle"
                options={tableOptions}
                data={tableData}
              >
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>ID Number</th>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Phone</th>
                    <th>Emergency</th>
                    <th>Medical Aid</th>
                    <th>Allergies</th>
                    <th>Medications</th>
                    <th>Date</th>
                  </tr>
                </thead>
              </DataTable>
            </div>
          </>
        ) : (
          <div className="card-body text-center py-5">
            <div className="alert alert-warning">
              <i className="ti ti-alert-circle me-2"></i>
              Event not found or you don't have permission to view it
            </div>
          </div>
        )}
      </div>

      {/* Only render modal if currentEvent exists */}
      {currentEvent && (
        <div
          className="modal fade"
          id="notificationModal"
          tabIndex="-1"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">
                  Send Notification to Participants
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  onClick={() => toggleNotificationModal(false)}
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <form onSubmit={handleNotificationSubmit}>
                  <div className="mb-3">
                    <label className="form-label">Notification Target</label>
                    <div className="d-flex">
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="notificationType"
                          id="allParticipants"
                          value="all"
                          checked={notificationData.notificationType === "all"}
                          onChange={handleNotificationChange}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="allParticipants"
                        >
                          All Participants ({entries.length})
                        </label>
                      </div>
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="notificationType"
                          id="nonEntrants"
                          value="nonEntrants"
                          checked={
                            notificationData.notificationType === "nonEntrants"
                          }
                          onChange={handleNotificationChange}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="nonEntrants"
                        >
                          Users Haven't Entered ({nonEntrantCount})
                        </label>
                      </div>
                    </div>

                    {notificationData.notificationType === "nonEntrants" &&
                      nonEntrantCount > 0 && (
                        <small className="text-info mt-1 d-block">
                          This will send notifications to {nonEntrantCount}{" "}
                          users in {currentEvent.location.split(", ")[1]}{" "}
                          province and those who selected "All" who haven't entered this event yet.
                        </small>
                      )}
                  </div>

                  <div className="mb-3">
                    <label htmlFor="title" className="form-label">
                      Notification Title <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className={`form-control ${
                        notificationErrors.title ? "is-invalid" : ""
                      }`}
                      id="title"
                      name="title"
                      placeholder="Enter notification title"
                      value={notificationData.title}
                      onChange={handleNotificationChange}
                    />
                    {notificationErrors.title && (
                      <div className="invalid-feedback">
                        {notificationErrors.title}
                      </div>
                    )}
                  </div>

                  <div className="mb-3">
                    <label htmlFor="body" className="form-label">
                      Notification Message{" "}
                      <span className="text-danger">*</span>
                    </label>
                    <textarea
                      className={`form-control ${
                        notificationErrors.body ? "is-invalid" : ""
                      }`}
                      id="body"
                      name="body"
                      rows="4"
                      placeholder="Enter the notification message"
                      value={notificationData.body}
                      onChange={handleNotificationChange}
                    ></textarea>
                    {notificationErrors.body && (
                      <div className="invalid-feedback">
                        {notificationErrors.body}
                      </div>
                    )}
                  </div>

                  <div className="mb-3">
                    <label htmlFor="clickAction" className="form-label">
                      Action Type
                    </label>
                    <div className="form-control bg-light">
                      Single Event
                      <small className="text-muted d-block">
                        Users will be taken to this event when they click the
                        notification.
                      </small>
                    </div>
                  </div>

                  <div className="event-preview mb-3">
                    <h6>Event Details</h6>
                    <div className="card">
                      <div className="card-body">
                        <div className="d-flex align-items-center">
                          {currentEvent?.imageUrl && (
                            <img
                              src={currentEvent.imageUrl}
                              alt={currentEvent.title}
                              className="rounded me-3"
                              style={{
                                width: "60px",
                                height: "60px",
                                objectFit: "cover",
                              }}
                            />
                          )}
                          <div>
                            <h6 className="mb-1">
                              {currentEvent?.title || "Event"}
                            </h6>
                            <p className="text-muted small mb-0">
                              {currentEvent?.date &&
                                new Date(
                                  currentEvent.date.seconds * 1000
                                ).toLocaleDateString()}
                              {currentEvent?.location &&
                                ` - ${currentEvent.location}`}
                            </p>
                            <small className="text-primary">
                              {entries.length} participants
                            </small>
                            {currentEvent?.province && (
                              <small className="text-secondary d-block">
                                Province: {currentEvent.province}
                              </small>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-outline-secondary"
                  onClick={() => toggleNotificationModal(false)}
                  disabled={notificationLoading}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={handleNotificationSubmit}
                  disabled={notificationLoading}
                >
                  {notificationLoading ? (
                    <>
                      <span
                        className="spinner-border spinner-border-sm me-1"
                        role="status"
                        aria-hidden="true"
                      ></span>
                      Sending...
                    </>
                  ) : notificationData.notificationType === "all" ? (
                    `Send to All ${entries.length} Participants`
                  ) : (
                    `Send to ${nonEntrantCount} Potential Participants`
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default EventEntries;
