import React, { useEffect, useState } from "react";
import "./dashboard.css";
import { useAuth } from "../../stores/AuthContext";
import Announcement from "../../components/announcement";
import "react-tooltip/dist/react-tooltip.css";
import AdminDashboard from "./admin-dashboard";
import OrganizerDashboard from "./organizer-dashboard";
import { globalSettings } from "../../stores/GlobalContext";

const Dashboard = () => {
  document.querySelector("title").innerHTML =
    "Dashboard | " + globalSettings.appName;
  const { userDetails } = useAuth();
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    if (userDetails) {
      setIsLoaded(true);
    }
  }, [userDetails]);

  const renderDashboardByRole = () => {
    if (userDetails?.role === "admin") {
      return <AdminDashboard />;
    } else {
      return <OrganizerDashboard />;
    }
  };

  return isLoaded ? (
    <div>
      <Announcement />
      {renderDashboardByRole()}
    </div>
  ) : (
    <div className="d-flex justify-content-center align-items-center h-100">
      <div className="spinner-border text-primary" role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
    </div>
  );
};

export default Dashboard;
