import React, { useCallback, useEffect, useState } from "react";
import Announcement from "../../components/announcement";
import { Link, useParams } from "react-router-dom";
import { useAuth } from "../../stores/AuthContext";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { useDropzone } from "react-dropzone";
import { storage } from "../../firebase";
import { toast } from "react-toastify";
import GooglePlacesAutocomplete, {
  geocodeByPlaceId,
} from "react-google-places-autocomplete";
import Select from "react-select";
import {
  getRidingSpotReports,
  updateRidingSpot,
} from "../../services/firebase_service";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  updateDoc,
  Timestamp,
} from "firebase/firestore";
import { db } from "../../firebase";

function EditRidingSpot() {
  const { id } = useParams();
  const { ridingSpots, setRidingSpots, userDetails } = useAuth();
  const [ridingSpot, setRidingSpot] = useState();
  const [loading, setLoading] = useState(false);

  const [name, setName] = useState("");
  const [price, setPrice] = useState("");
  const [contactEmail, setContactEmail] = useState("");
  const [contactNumber, setContactNumber] = useState("");
  const [location, setLocation] = useState([]);
  const [locationError, setLocationError] = useState("");
  const [distance, setDistance] = useState("");
  const [description, setDescription] = useState("");
  const [image, setImage] = useState("");
  const [published, setPublished] = useState(false);
  const [bikeType, setBikeType] = useState("");
  const [riderLevel, setRiderLevel] = useState("");
  const [ridingTypes, setRidingTypes] = useState([]);
  const [createdAt, setCreatedAt] = useState("");
  const [spotJson, setSpotJson] = useState("");

  const [selectedFile, setSelectedFile] = useState(null);
  const [preview, setPreview] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [uploadedImageUrl, setUploadedImageUrl] = useState("");
  const [errorReports, setErrorReports] = useState([]);

  useEffect(() => {
    const ridingSpotData = ridingSpots.find((spot) => spot.id === id);
    if (ridingSpotData) {
      setRidingSpot(ridingSpotData);
      setName(ridingSpotData.name);
      setContactEmail(ridingSpotData.contactEmail);
      setContactNumber(ridingSpotData.contactNumber);
      setPrice(ridingSpotData.price);
      setLocation(ridingSpotData.location);
      setDistance(ridingSpotData.distance);
      setDescription(ridingSpotData.description);
      setImage(ridingSpotData.imageUrl);
      setPublished(ridingSpotData.published);
      setBikeType(ridingSpotData.bikeType);
      setRidingTypes(ridingSpotData.ridingTypes);
      setRiderLevel(ridingSpotData.riderLevel);
      setCreatedAt(ridingSpotData.createdAt);
      setPreview(ridingSpotData.imageUrl);
    }
  }, [ridingSpots]);

  useEffect(() => {
    const fetchErrorReports = async () => {
      if (id) {
        const reportsDocs = await getRidingSpotReports(id);
        const reports = reportsDocs.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setErrorReports(reports);
      }
    };

    fetchErrorReports();
  }, [id]);

  const handleDrop = useCallback((acceptedFiles) => {
    if (acceptedFiles && acceptedFiles.length) {
      const file = acceptedFiles[0];
      setSelectedFile(file);
      setPreview(URL.createObjectURL(file));
    }
  }, []);

  const removeFile = () => {
    setSelectedFile(null);
    setPreview(null);
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: handleDrop,
    accept: {
      "image/*": [".jpeg", ".jpg", ".png", ".gif"],
    },
    maxFiles: 1,
  });

  const uploadImage = async () => {
    if (!selectedFile) {
      return null;
    }

    const storageRef = ref(storage, `ridingSpotImages/${selectedFile.name}`);
    const uploadTask = uploadBytesResumable(storageRef, selectedFile);

    setUploading(true);

    return new Promise((resolve, reject) => {
      uploadTask.on(
        "state_changed",
        null,
        (error) => {
          console.error("Upload error:", error);
          toast.fire("Error!", "Failed to upload the image.", "error");
          setUploading(false);
          reject(error);
        },
        async () => {
          const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
          setUploadedImageUrl(downloadURL);
          setUploading(false);
          resolve(downloadURL);
        }
      );
    });
  };

  const handleLocationChange = (address) => {
    var location = {
      address: address,
      city: "",
      country: "",
      place: null,
      province: "",
      street: null,
    };
    setLocation({ address });
  };

  const handlePlaceSelected = (value) => {
    const placeId = value.value.place_id;

    geocodeByPlaceId(placeId)
      .then((results) => {
        // Extract address components
        const addressComponents = results[0].address_components;

        // Find suburb (locality or sublocality)
        const suburb = addressComponents.find(
          (component) =>
            component.types.includes("sublocality") ||
            component.types.includes("locality")
        )?.long_name;

        // Find province (administrative_area_level_1)
        const province = addressComponents.find((component) =>
          component.types.includes("administrative_area_level_1")
        )?.long_name;

        // You can now set these values in your state if needed
        if (suburb && province) {
          const locationData = {
            suburb: suburb,
            province: province,
            city:
              addressComponents.find((component) =>
                component.types.includes("locality")
              )?.long_name || "",
            address: results[0].formatted_address,
            place: results[0].place_id,
            street:
              addressComponents.find((component) =>
                component.types.includes("route")
              )?.long_name || "",
          };
          setLocation(locationData);
        } else {
          setLocationError(
            "The location you selected is not valid. Please search for a valid location."
          );
        }
      })
      .catch((error) => console.error(error));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Convert or validate createdAt timestamp
    let timestampCreatedAt;
    if (createdAt instanceof Timestamp) {
      timestampCreatedAt = createdAt;
    } else if (typeof createdAt === "string") {
      // If it's a string (from form), create new timestamp
      timestampCreatedAt = Timestamp.now();
    } else {
      // Fallback to current timestamp
      timestampCreatedAt = Timestamp.now();
    }

    const updatedSpot = {
      ...ridingSpot,
      name: name || "",
      price: price || "",
      contactEmail: contactEmail || "",
      contactNumber: contactNumber || "",
      location: location || "",
      distance: distance || "",
      description: description || "",
      imageUrl: uploadedImageUrl || image,
      published: published || false,
      bikeType: bikeType || "",
      riderLevel: riderLevel || "",
      ridingTypes: ridingTypes || [],
      createdAt: timestampCreatedAt,
      updatedAt: Timestamp.now(),
    };

    if (spotJson) {
      try {
        const json = JSON.parse(spotJson);
        updatedSpot.location = json.location;
        updatedSpot.ridingTypes = json.ridingTypes;
      } catch (error) {
        console.error("Error parsing JSON:", error);
        toast.error("Error parsing JSON. Please check the JSON format.");
        return;
      }
    }

    if (name && bikeType) {
      if (selectedFile) {
        uploadImage()
          .then(async () => {
            setRidingSpots((prevSpots) =>
              prevSpots.map((spot) =>
                spot.id === id ? { ...spot, ...updatedSpot } : spot
              )
            );
            await updateRidingSpot(id, updatedSpot);
            toast.success("Riding Spot updated successfully!");
          })
          .catch((error) => {
            console.error("Error uploading image:", error);
            toast.error("Error uploading image. Please try again.");
          });
      } else {
        setRidingSpots((prevSpots) =>
          prevSpots.map((spot) =>
            spot.id === id ? { ...spot, ...updatedSpot } : spot
          )
        );
        await updateRidingSpot(id, updatedSpot);
        toast.success("Riding Spot updated successfully!");
      }
    } else {
      toast.error("Please fill in all required fields.");
    }
  };

  const handleErrorReportDone = async (reportId) => {
    try {
      const reportRef = doc(db, "riding_spots", id, "error_reports", reportId);
      await updateDoc(reportRef, {
        doneAt: Timestamp.now(),
      });

      // Update local state
      setErrorReports((prev) =>
        prev.map((report) =>
          report.id === reportId
            ? { ...report, doneAt: Timestamp.now() }
            : report
        )
      );

      toast.success("Error report marked as done");
    } catch (error) {
      console.error("Error updating report:", error);
      toast.error("Failed to update error report");
    }
  };

  const processJson = () => {};

  return (
    <div>
      <Announcement />
      <div className="row">
        <div className="col-xl-12">
          <div className="card">
            <div className="card-header bg-transparent">
              <div className="bg-transparent d-flex align-items-center justify-content-between mb-3">
                <div className="left">
                  <h4 className="">Edit Riding Spot</h4>
                  <p className="mb-0">Add your Riding Spot details below</p>
                </div>
                <div className="right">
                  <Link to="/riding-spots" className="btn btn-primary">
                    <i className="ti ti-arrow-left"></i> Back to Riding Spots
                  </Link>
                </div>
              </div>
            </div>
            {ridingSpot && (
              <form id="editRidingSpot" onSubmit={handleSubmit}>
                <div className="card-body pt-0 row">
                  <div className="col-xl-8">
                    <div className="row">
                      <div className="col-xl-6">
                        <div className="mb-3">
                          <label htmlFor="eventTitle" className="form-label">
                            Riding Spot Name{" "}
                            <span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="name"
                            name="name"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            required
                          />
                        </div>
                      </div>

                      <div className="col-xl-6">
                        <div className="mb-3">
                          <label htmlFor="contactEmail" className="form-label">
                            Contact Email <span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="contactEmail"
                            name="contactEmail"
                            value={contactEmail}
                            onChange={(e) => setContactEmail(e.target.value)}
                            required
                          />
                        </div>
                      </div>

                      <div className="col-xl-6">
                        <div className="mb-3">
                          <label htmlFor="contactNumber" className="form-label">
                            Contact Number{" "}
                            <span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="contactNumber"
                            name="contactNumber"
                            value={contactNumber}
                            onChange={(e) => {
                              if (
                                e.target.value != "" &&
                                e.target.value != null &&
                                e.target.value.length > 0
                              ) {
                                if (!/^\d+$/.test(e.target.value)) {
                                  e.target.value = e.target.value.replace(
                                    /[^0-9]/g,
                                    ""
                                  );
                                }
                              }
                              setContactNumber(e.target.value);
                            }}
                            required
                          />
                        </div>
                      </div>

                      <div className="col-xl-6">
                        <div className="mb-3">
                          <label htmlFor="bikeType" className="form-label">
                            Bike Type <span className="text-danger">*</span>
                          </label>
                          <select
                            className="form-select"
                            name="bikeType"
                            id="bikeType"
                            value={bikeType}
                            onChange={(e) => setBikeType(e.target.value)}
                          >
                            <option value="Bikes & Quads">Bikes & Quads</option>
                            <option value="Bikes Only">Bikes Only</option>
                            <option value="Quads Only">Quads Only</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-xl-6">
                        <div className="mb-3">
                          <label htmlFor="riderLevel" className="form-label">
                            Rider Level
                          </label>
                          <select
                            className="form-select"
                            name="riderLevel"
                            id="riderLevel"
                            value={riderLevel}
                            onChange={(e) => setRiderLevel(e.target.value)}
                          >
                            <option value=""></option>
                            <option value="All Levels">All Levels</option>
                            <option value="Beginner">Beginner</option>
                            <option value="Intermediate">Intermediate</option>
                            <option value="Advanced">Advanced</option>
                            <option value="Expert">Expert</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-xl-6">
                        <div className="mb-3">
                          <label htmlFor="distance" className="form-label">
                            Riding Spot Distance
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="distance"
                            name="distance"
                            value={distance}
                            onChange={(e) => setDistance(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-xl-6">
                        <div className="mb-3">
                          <label htmlFor="price" className="form-label">
                            Riding Spot Price
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="price"
                            name="price"
                            value={price}
                            onChange={(e) => setPrice(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-xl-6">
                        <div className="mb-3">
                          <label htmlFor="eventLocation" className="form-label">
                            Riding Spot Location{" "}
                            <span className="text-danger">*</span>
                          </label>
                          <GooglePlacesAutocomplete
                            apiKey="AIzaSyD_Lsht21cw3UEhTUP9cPW0R9kylZIqCmM"
                            selectProps={{
                              className:
                                "locationAutoComplete" +
                                (locationError ? " is-invalid" : ""),
                              initialValue: location.address,
                              onChange: handlePlaceSelected,
                              placeholder: "Search for a location",
                              onFocus: (e) => {
                                e.target.value = "";
                                setLocationError(null);
                              },
                              styles: {
                                placeholder: (provided) => ({
                                  ...provided,
                                  color: "#7c8fac",
                                }),
                                control: (provided) => ({
                                  ...provided,
                                  background: "transparent",
                                  border: "1px solid #333f55",
                                  borderRadius: "5px",
                                }),
                                input: (provided) => ({
                                  ...provided,
                                  color: "#fff",
                                  cursor: "text",
                                }),
                                option: (provided) => ({
                                  ...provided,
                                  color: "#fff",
                                  background: "#0F1820",
                                  cursor: "pointer",
                                }),
                                dropdownIndicator: (provided) => ({
                                  ...provided,
                                  display: "none",
                                }),
                                indicatorSeparator: (provided) => ({
                                  ...provided,
                                  display: "none",
                                }),
                                menu: (provided) => ({
                                  ...provided,
                                  background: "#0F1820",
                                }),
                                loadingIndicator: (provided) => ({
                                  ...provided,
                                  color: "#fff",
                                }),
                                loadingMessage: (provided) => ({
                                  ...provided,
                                  color: "#fff",
                                }),
                                noOptionsMessage: (provided) => ({
                                  ...provided,
                                  color: "#fff",
                                }),
                                singleValue: (provided) => ({
                                  ...provided,
                                  color: "#fff",
                                }),
                              },
                            }}
                            apiOptions={{
                              region: "za",
                            }}
                            autocompletionRequest={{
                              componentRestrictions: {
                                country: ["za"],
                              },
                            }}
                            style={{ background: "#ff0000" }}
                          />
                          {locationError ? (
                            <div className="invalid-feedback">
                              {locationError}
                            </div>
                          ) : null}
                          {location.address ? (
                            <small className="text-muted">
                              {location.address}
                            </small>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-xl-6">
                        <div className="mb-3">
                          <label htmlFor="ridingTypes" className="form-label">
                            Riding Types
                          </label>
                          <Select
                            className="custom-select"
                            onChange={(selectedOptions) => {
                              setRidingTypes(
                                selectedOptions.map((option) => option.value)
                              );
                            }}
                            defaultValue={ridingTypes.map((type) => ({
                              value: type,
                              label:
                                type.charAt(0).toUpperCase() +
                                type.slice(1).toLowerCase(),
                            }))}
                            components={{
                              IndicatorSeparator: () => null,
                              ClearIndicator: () => null,
                              Control: ({ children, ...props }) => {
                                return (
                                  <div
                                    className="form-select-custom"
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    {children}
                                  </div>
                                );
                              },
                            }}
                            isMulti={true}
                            options={[
                              { value: "enduro", label: "Enduro" },
                              { value: "mx", label: "MX" },
                              { value: "trail", label: "Trail" },
                              { value: "adventure", label: "Adventure" },
                              { value: "freestyle", label: "Freestyle" },
                              { value: "supermoto", label: "Supermoto" },
                              { value: "track", label: "Track" },
                              { value: "other", label: "Other" },
                            ]}
                          />
                        </div>
                      </div>
                      <div className="col-xl-12">
                        <div className="mb-3">
                          <label
                            htmlFor="eventDescription"
                            className="form-label"
                          >
                            Riding Spot Description
                          </label>
                          <textarea
                            className="form-control"
                            id="eventDescription"
                            rows="15"
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-4">
                    <div className="mb-3">
                      <label className="form-label">
                        Riding Spot Image <span className="text-danger">*</span>
                      </label>
                      <div
                        {...getRootProps({
                          className:
                            "dropzone border rounded-3 p-3 text-center",
                        })}
                      >
                        <input {...getInputProps()} />
                        {preview ? (
                          <div>
                            <img
                              src={preview}
                              alt="Preview"
                              style={{
                                maxWidth: "100%",
                                maxHeight: "150px",
                                marginBottom: "10px",
                              }}
                            />
                            <button
                              type="button"
                              className="btn btn-danger btn-sm"
                              onClick={removeFile}
                            >
                              Remove File
                            </button>
                          </div>
                        ) : (
                          <p className="mb-0">
                            Drag an image here, or click to upload
                          </p>
                        )}
                      </div>
                    </div>

                    {userDetails?.role === "admin" ? (
                      <div className="mb-3 d-flex justify-content-end flex-column">
                        <label htmlFor="Json" className="form-label">
                          Riding Spot JSON
                        </label>
                        <textarea
                          className="form-control"
                          id="Json"
                          rows="10"
                          value={spotJson}
                          onChange={(e) => setSpotJson(e.target.value)}
                        />
                        <button
                          className="btn btn-grey mt-3"
                          type="button"
                          onClick={processJson}
                        >
                          Parse JSON
                        </button>
                      </div>
                    ) : null}
                  </div>
                  <div className="col-xl-12 mt-4">
                    <h5>Error Reports</h5>
                    {errorReports.length === 0 ? (
                      <p className="text-muted">No error reports found</p>
                    ) : (
                      <div className="table-responsive">
                        <table className="table">
                          <thead>
                            <tr>
                              <th>Status</th>
                              <th>Date Reported</th>
                              <th>Description</th>
                              <th>Reporter</th>
                              <th>Actions</th>
                            </tr>
                          </thead>
                          <tbody>
                            {errorReports.map((report) => (
                              <tr key={report.id}>
                                <td>
                                  <span
                                    className={`badge text-secondary ${
                                      report.doneAt
                                        ? "bg-success"
                                        : "bg-warning"
                                    }`}
                                  >
                                    {report.doneAt ? "Resolved" : "Pending"}
                                  </span>
                                </td>
                                <td>
                                  {new Date(
                                    report.timestamp?.seconds * 1000
                                  ).toLocaleDateString()}
                                </td>
                                <td>{report.error}</td>
                                <td>{report.user || "Anonymous"}</td>
                                <td>
                                  {!report.doneAt && (
                                    <button
                                      type="button"
                                      className="btn btn-sm btn-success"
                                      onClick={() =>
                                        handleErrorReportDone(report.id)
                                      }
                                    >
                                      Mark as Done
                                    </button>
                                  )}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    )}
                  </div>
                </div>
                <div className="card-footer d-flex justify-content-end">
                  <div className="form-check form-switch py-2 me-4">
                    <input
                      className="form-check-input cursor-pointer"
                      type="checkbox"
                      id="published"
                      defaultChecked={published}
                      onChange={(e) => setPublished(!published)}
                    />
                    <label className="form-check-label" htmlFor="published">
                      Publish
                    </label>
                  </div>
                  <button type="submit" className="btn btn-primary d-flex">
                    {uploading || loading ? (
                      <div className="spin me-2">
                        <i className="ti ti-loader"></i>
                      </div>
                    ) : null}
                    Save Riding Spot
                  </button>
                </div>
              </form>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditRidingSpot;
