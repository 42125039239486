import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  useCallback,
} from "react";
import { auth } from "../firebase";
import {
  onAuthStateChanged,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  signOut,
  sendEmailVerification,
  sendPasswordResetEmail,
  browserSessionPersistence,
} from "firebase/auth";
import { db } from "../firebase";
import { getDocs, collection, doc, setDoc } from "firebase/firestore";
import { useNavigate } from "react-router-dom";

// Create a context for auth state
const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [userDetails, setUserDetails] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);

  const [users, setUsers] = useState([]);
  const [categories, setCategories] = useState([]);
  const [events, setEvents] = useState([]);
  const [notifications, setNotifications] = useState([]);
  const [products, setProducts] = useState([]);
  const [settings, setSettings] = useState([]);
  const [sponsors, setSponsors] = useState([]);
  const [supportRequests, setSupportRequests] = useState([]);
  const [ridingSpots, setRidingSpots] = useState([]);
  const [workshops, setWorkshops] = useState([]);
  const [reports, setReports] = useState([]);

  // Memoize initializeUser function
  const initializeUser = useCallback(async (user) => {
    if (user) {
      setUser({ ...user });
      setIsAuthenticated(true);
      await getCurrentUserDetails(user.uid);
    } else {
      setUser(null);
      setIsAuthenticated(false);
    }
    setLoading(false);
  }, []); // Empty dependency array since it doesn't depend on any state

  // Modified useEffect for auth state
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, initializeUser);
    return () => unsubscribe();
  }, [initializeUser]);

  // Modified useEffect for fetching collections
  useEffect(() => {
    if (!userDetails || loading) return;

    const fetchData = async () => {
      if (userDetails.role === "admin") {
        await Promise.all([
          fetchCollection("events", setEvents),
          fetchCollection("products", setProducts),
          fetchCollection("support_requests", setSupportRequests),
          fetchCollection("riding_spots", setRidingSpots),
          fetchCollection("reports", setReports),
          fetchCollection("workshops", setWorkshops),
          fetchCollection("notifications", setNotifications),
        ]);
      } else {
        // For non-admin users (like organisers), fetch only their data
        await Promise.all([
          fetchCollectionByUser("events", setEvents, userDetails.id),
          fetchCollectionByUser("products", setProducts, userDetails.id),
          fetchCollectionByUser("support_requests", setSupportRequests, userDetails.id),
        ]);
      }

      await Promise.all([
        fetchCollection("users", setUsers),
        fetchCollection("sponsors", setSponsors),
        fetchCollection("categories", setCategories),
        fetchCollection("settings", setSettings),
        fetchCollection("sponsors", setSponsors),
      ]);
    };

    fetchData();
  }, [userDetails, loading]);

  const fetchCollection = async (collectionName, setState) => {
    try {
      const querySnapshot = await getDocs(collection(db, collectionName));
      const data = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setState(data);
      // console.log(`${collectionName} fetched successfully:`, data);
    } catch (error) {
      // console.error(`Error fetching ${collectionName} collection:`, error);
    }
  };

  const fetchCollectionByUser = async (collectionName, setState, userId) => {
    try {
      const querySnapshot = await getDocs(collection(db, collectionName));
      const data = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      // Fix: Make sure we're comparing the correct property
      // Some collections might use 'createdBy' and others 'uid'
      const filteredData = data.filter((item) => {
        return item.createdBy === userId || item.uid === userId;
      });
      setState(filteredData);
      console.log(`${collectionName} filtered for user ${userId}:`, filteredData);
    } catch (error) {
      console.error(`Error fetching ${collectionName} collection:`, error);
    }
  };

  const getCurrentUserDetails = async (userId) => {
    try {
      const querySnapshot = await getDocs(collection(db, "users"));
      const data = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      const currentUser = data.filter((item) => item.id === userId);

      setUserDetails(currentUser[0]);
    } catch (error) {
      console.error("Error fetching user details:", error);
    }
  };

  // const fetchAllInOneDocument = async () => {
  //   try {
  //     const docSnapshot = await getDoc(doc(db, "meta", "allCollections"));
  //     if (docSnapshot.exists()) {
  //       const data = docSnapshot.data();
  //       setUsers(data.users || []);
  //       setCategories(data.categories || []);
  //       setEvents(data.events || []);
  //       setNotifications(data.notifications || []);
  //       setProducts(data.products || []);
  //       setSettings(data.settings || []);
  //       setSponsors(data.sponsors || []);
  //       setSupportRequests(data.support_requests || []);
  //       // console.log("Firebase single document fetched successfully:", data);
  //     }
  //   } catch (error) {
  //     console.error("Error fetching meta document:", error);
  //   }
  // };

  // Login function using Firebase Authentication
  const login = (email, password, rememberMe) => {
    if (!rememberMe) {
      browserSessionPersistence.type = "SESSION";
      auth.setPersistence(browserSessionPersistence);
    }

    return signInWithEmailAndPassword(auth, email, password);
  };

  const resendEmailVerification = async () => {
    return sendEmailVerification(auth.currentUser);
  };

  // Register function using Firebase Authentication
  const register = async (email, password, organiserName, preferredProvince) => {
    try {
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );

      // Send email verification
      await sendEmailVerification(userCredential.user);

      // Create a record in the users collection with the user's email and role
      const userData = {
        firstName: organiserName,
        lastName: "",
        email: email,
        role: "organiser",
        status: "pending",
        createdAt: new Date(),
        aIAssistantId: null,
        gender: null,
        phone: null,
        lastLogin: new Date(),
        userPlan: "free",
        settings: {
          preferedProvince: preferredProvince,
          platform: "Web",
        },
        photoUrl: null,
        phoneNumberVerified: false,
        uid: userCredential.user.uid,
      };
      await setDoc(doc(db, "users", userCredential.user.uid), userData);
      // setUserDetails(userData);

      // logout();

      // Return a success message
      return {
        success: true,
        message:
          "Your account has been created, please verify your email address by click on the link sent to your email address.",
      };
    } catch (error) {
      // Handle any errors
      console.error(error);
      return { success: false, message: error.message };
    }
  };

  // Reset password function using Firebase Authentication
  const resetPassword = (email) => {
    return sendPasswordResetEmail(auth, email);
  };

  // Logout function using Firebase Authentication
  const logout = () => {
    return signOut(auth);
  };

  const value = {
    user,
    isAuthenticated,
    setIsAuthenticated,
    loading,
    setLoading,
    login,
    register,
    resetPassword,
    logout,
    users,
    setUsers,
    sponsors,
    setSponsors,
    categories,
    events,
    setEvents,
    notifications,
    products,
    settings,
    supportRequests,
    userDetails,
    resendEmailVerification,
    ridingSpots,
    reports,
    setReports,
    setRidingSpots,
    workshops,
    setWorkshops,
    setProducts,
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};
