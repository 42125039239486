import React, { useState, useCallback, useEffect } from "react";
import Announcement from "../../../components/announcement";
import SponsorModal from "../../../components/SponsorModal";
import Swal from "sweetalert2";
import { useDropzone } from "react-dropzone";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import GooglePlacesAutocomplete, {
  geocodeByPlaceId,
} from "react-google-places-autocomplete";
import { Editor } from "@tinymce/tinymce-react";
import { toast } from "react-toastify";
import moment from "moment";
import { useAuth } from "../../../stores/AuthContext";
import { storage, db } from "../../../firebase";
import {
  Timestamp,
  doc,
  updateDoc,
  collection,
  getDocs,
  addDoc,
} from "firebase/firestore";
import { Link, useNavigate, useParams } from "react-router-dom";
import { addSponsor } from "../../../services/firebase_service";

function EditEvent() {
  const { id } = useParams();
  const navigate = useNavigate();
  const { userDetails, events, setEvents, sponsors, setSponsors } = useAuth();
  const [loading, setLoading] = useState(false);
  const [event, setEvent] = useState(null);
  const [eventTitle, setEventTitle] = useState("");
  const [eventLocation, setEventLocation] = useState("");
  const [eventAddress, setEventAddress] = useState("");
  const [eventStartDate, setEventStartDate] = useState("");
  const [eventEndDate, setEventEndDate] = useState("");
  const [riderLevel, setRiderLevel] = useState("");
  const [bikeType, setBikeType] = useState("Bikes & Quads");
  const [eventPrice, setEventPrice] = useState("");
  const [eventLink, setEventLink] = useState("");
  const [inAppRegistration, setInAppRegistration] = useState(false);
  const [eventDistance, setEventDistance] = useState("");
  const [eventDescription, setEventDescription] = useState("");
  const [users, setUsers] = useState([]);
  const [selectedCreator, setSelectedCreator] = useState("");

  const [selectedFile, setSelectedFile] = useState(null);
  const [preview, setPreview] = useState(null);
  const [uploading, setUploading] = useState(false);

  const [locationError, setLocationError] = useState(null);

  const [mainSponsor, setMainSponsor] = useState({
    name: "",
    email: "",
    contactNumber: "",
    website: "",
    imageUrl: "",
  });
  const [mainSponsorId, setMainSponsorId] = useState("");
  const [sponsorIds, setSponsorIds] = useState([]);
  const [selectedSponsors, setSelectedSponsors] = useState([]);
  const [mainSponsorImage, setMainSponsorImage] = useState(null);
  const [mainSponsorImagePreview, setMainSponsorImagePreview] = useState(null);
  const [selectedSponsor, setSelectedSponsor] = useState("");
  const [isSavingSponsor, setIsSavingSponsor] = useState(false);
  const [showSponsorModal, setShowSponsorModal] = useState(false);

  // Fetch all users for admin to select
  useEffect(() => {
    const fetchUsers = async () => {
      if (userDetails?.role === "admin") {
        try {
          const usersCollection = collection(db, "users");
          const userDocs = await getDocs(usersCollection);
          const usersList = userDocs.docs
            .map((doc) => ({
              id: doc.id,
              ...doc.data(),
            }))
            .filter((user) => user.role === "organiser"); // Filter for organisers only
          setUsers(usersList);
        } catch (error) {
          console.error("Error fetching users:", error);
        }
      }
    };

    fetchUsers();
  }, [userDetails]);

  // Load event data
  useEffect(() => {
    const eventData = events.find((e) => e.id === id);
    if (eventData) {
      setEvent(eventData);
      setEventTitle(eventData.title || "");
      setEventLocation(eventData.location || "");
      setEventAddress(eventData.address || "");
      setEventStartDate(
        eventData.date
          ? moment(eventData.date.toDate()).format("YYYY-MM-DDTHH:mm")
          : ""
      );
      setEventEndDate(
        eventData.endDate
          ? moment(eventData.endDate.toDate()).format("YYYY-MM-DDTHH:mm")
          : ""
      );
      setRiderLevel(eventData.riderLevel || "");
      setBikeType(eventData.bikeType || "Bikes & Quads");
      setEventPrice(eventData.price || "");
      setEventLink(eventData.link || "");
      setInAppRegistration(eventData.inAppRegistration || false);
      setEventDistance(eventData.distance || "");
      setEventDescription(eventData.description || "");
      setSelectedCreator(eventData.createdBy || "");

      if (eventData.imageUrl) {
        setPreview(eventData.imageUrl);
      }

      // Set mainSponsorId from event data
      if (eventData.mainSponsorId) {
        setMainSponsorId(eventData.mainSponsorId);
        // Find the main sponsor in the sponsors list
        const mainSponsorData = sponsors.find(s => s.id === eventData.mainSponsorId);
        if (mainSponsorData) {
          setMainSponsor(mainSponsorData);
          if (mainSponsorData.imageUrl) {
            setMainSponsorImagePreview(mainSponsorData.imageUrl);
          }
        }
      } else if (eventData.mainSponsor) {
        // For backward compatibility
        setMainSponsor(eventData.mainSponsor);
        if (eventData.mainSponsor.imageUrl) {
          setMainSponsorImagePreview(eventData.mainSponsor.imageUrl);
        }
      }

      // Set sponsorIds from event data
      if (eventData.sponsorIds && Array.isArray(eventData.sponsorIds)) {
        setSponsorIds(eventData.sponsorIds);
        // Load selected sponsors data
        const selectedSponsorsList = eventData.sponsorIds.map(id => 
          sponsors.find(s => s.id === id)
        ).filter(Boolean);
        setSelectedSponsors(selectedSponsorsList);
      } else if (eventData.sponsors && Array.isArray(eventData.sponsors)) {
        // For backward compatibility
        setSponsors(eventData.sponsors);
        setSponsorIds([]);
      } else {
        setSponsors([]);
        setSponsorIds([]);
        setSelectedSponsors([]);
      }
    }
  }, [events, id, sponsors]);

  const handleDrop = useCallback((acceptedFiles) => {
    if (acceptedFiles && acceptedFiles.length) {
      const file = acceptedFiles[0];
      setSelectedFile(file);
      setPreview(URL.createObjectURL(file));
    }
  }, []);

  const removeFile = () => {
    setSelectedFile(null);
    setPreview(event?.imageUrl || null);
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: handleDrop,
    accept: {
      "image/*": [".png", ".jpg", ".jpeg", ".gif", ".webp"],
    },
    maxFiles: 1,
  });

  const uploadImage = async () => {
    if (!selectedFile) {
      return event?.imageUrl || null;
    }

    const storageRef = ref(storage, `eventImages/${selectedFile.name}`);
    const uploadTask = uploadBytesResumable(storageRef, selectedFile);

    setUploading(true);

    return new Promise((resolve, reject) => {
      uploadTask.on(
        "state_changed",
        null,
        (error) => {
          console.error("Upload error:", error);
          Swal.fire("Error!", "Failed to upload the image.", "error");
          setUploading(false);
          reject(error);
        },
        async () => {
          const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
          setUploading(false);
          resolve(downloadURL);
        }
      );
    });
  };

  const removeMainSponsorImage = () => {
    setMainSponsorImage(null);
    setMainSponsorImagePreview(null);
    setMainSponsor({ ...mainSponsor, imageUrl: "" });
  };

  const handleMainSponsorImageDrop = useCallback((acceptedFiles) => {
    if (acceptedFiles && acceptedFiles.length) {
      const file = acceptedFiles[0];
      setMainSponsorImage(file);
      setMainSponsorImagePreview(URL.createObjectURL(file));
    }
  }, []);

  const {
    getRootProps: getMainSponsorImageRootProps,
    getInputProps: getMainSponsorImageInputProps,
  } = useDropzone({
    onDrop: handleMainSponsorImageDrop,
    accept: {
      "image/*": [".png", ".jpg", ".jpeg", ".gif", ".webp"],
    },
    maxFiles: 1,
  });

  const uploadMainSponsorImage = async () => {
    if (!mainSponsorImage) {
      return mainSponsor.imageUrl || null;
    }

    const storageRef = ref(storage, `sponsorImages/${mainSponsorImage.name}`);
    const uploadTask = uploadBytesResumable(storageRef, mainSponsorImage);

    setUploading(true);

    return new Promise((resolve, reject) => {
      uploadTask.on(
        "state_changed",
        null,
        (error) => {
          console.error("Sponsor image upload error:", error);
          Swal.fire("Error!", "Failed to upload the sponsor image.", "error");
          setUploading(false);
          reject(error);
        },
        async () => {
          const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
          setUploading(false);
          resolve(downloadURL);
        }
      );
    });
  };

  const addSponsorData = () => {
    if (sponsorIds.length < 5) {
      setShowSponsorModal(true);
    } else {
      toast.warning("Maximum of 5 additional sponsors allowed");
    }
  };

  const updateSponsor = (index, field, value) => {
    const updatedSponsors = [...sponsors];
    updatedSponsors[index] = { ...updatedSponsors[index], [field]: value };
    setSponsors(updatedSponsors);
  };

  const removeSponsor = (index) => {
    const updatedIds = [...sponsorIds];
    updatedIds.splice(index, 1);
    setSponsorIds(updatedIds);
    
    const updatedSponsors = [...selectedSponsors];
    updatedSponsors.splice(index, 1);
    setSelectedSponsors(updatedSponsors);
  };

  const handlePlaceSelected = (value) => {
    const placeId = value.value.place_id;

    geocodeByPlaceId(placeId)
      .then((results) => {
        const addressComponents = results[0].address_components;

        const suburb = addressComponents.find(
          (component) =>
            component.types.includes("sublocality") ||
            component.types.includes("locality")
        )?.long_name;

        const province = addressComponents.find((component) =>
          component.types.includes("administrative_area_level_1")
        )?.long_name;

        if (suburb && province) {
          setEventLocation(`${suburb}, ${province}`);
          setEventAddress(results[0].formatted_address);
        } else {
          setLocationError(
            "The location you selected is not valid. Please search for a valid location."
          );
        }
      })
      .catch((error) => console.error(error));
  };

  const handleSponsorSelect = (e) => {
    const sponsorId = e.target.value;
    if (!sponsorId) return;

    const selectedSponsorData = sponsors.find((s) => s.id === sponsorId);
    if (!selectedSponsorData) return;

    // Check if we're selecting for main sponsor or additional sponsors
    if (selectedSponsorData && !mainSponsorId) {
      // Set as main sponsor
      setMainSponsor(selectedSponsorData);
      setMainSponsorId(selectedSponsorData.id);
      
      if (selectedSponsorData.imageUrl) {
        setMainSponsorImagePreview(selectedSponsorData.imageUrl);
      }
    } else {
      // Add to additional sponsors if not already included
      if (!sponsorIds.includes(selectedSponsorData.id)) {
        setSponsorIds([...sponsorIds, selectedSponsorData.id]);
        setSelectedSponsors([...selectedSponsors, selectedSponsorData]);
      }
    }
    
    setSelectedSponsor("");
  };

  const saveCurrentSponsorToFirebase = async () => {
    try {
      setIsSavingSponsor(true);
      let sponsorData;

      // Determine which sponsor to save
      if (sponsors.length === 0) {
        // Save main sponsor
        if (!mainSponsor.name) {
          toast.error("Please enter a sponsor name before saving");
          setIsSavingSponsor(false);
          return;
        }

        // If we have a new logo image, upload it
        let imageUrl = mainSponsor.imageUrl;
        if (mainSponsorImage) {
          imageUrl = await uploadMainSponsorImage();
        }

        sponsorData = {
          ...mainSponsor,
          imageUrl: imageUrl,
          createdAt: Timestamp.now(),
          createdBy: userDetails.uid,
        };
      } else {
        // Save additional sponsor
        const lastSponsor = sponsors[sponsors.length - 1];
        if (!lastSponsor.name) {
          toast.error("Please enter a sponsor name before saving");
          setIsSavingSponsor(false);
          return;
        }

        sponsorData = {
          ...lastSponsor,
          createdAt: Timestamp.now(),
          createdBy: userDetails.uid,
        };
      }

      // Add to Firebase sponsors collection
      const sponsorsCollection = collection(db, "sponsors");
      const docRef = await addDoc(sponsorsCollection, sponsorData);

      // Add to context
      const newSponsor = { id: docRef.id, ...sponsorData };
      setSponsors((prev) => [...prev, newSponsor]);

      toast.success("Sponsor saved for future use!");
      setIsSavingSponsor(false);
    } catch (error) {
      console.error("Error saving sponsor:", error);
      toast.error("Failed to save sponsor");
      setIsSavingSponsor(false);
    }
  };

  const checkSponsors = async () => {
    if (sponsors.length !== 0) {
      const filterForMainSponsor = sponsors.filter((sponsor) =>sponsor.id === mainSponsorId);
      if (filterForMainSponsor.length === 0) {
        const sponsorRef = await addSponsor(mainSponsor);
        return sponsorRef.id;
      } else {
        return filterForMainSponsor.id;
      }
    } else {
      const sponsorRef = await addSponsor(mainSponsor);
      console.log("sponsorRef", sponsorRef);
      return sponsorRef.id;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("sponsors", sponsors);
    console.log("main sponsor", mainSponsor);

    try {
      setLoading(true);
      const imageUrl = await uploadImage();

      // No need to upload sponsor image here anymore as it's handled in the sponsor creation

      const eventData = {
        ...event,
        price: eventPrice,
        link: eventLink,
        imageUrl: imageUrl,
        riderLevel: riderLevel,
        endDate: Timestamp.fromDate(new Date(eventEndDate)),
        bikeType: bikeType,
        title: eventTitle,
        date: Timestamp.fromDate(new Date(eventStartDate)),
        description: eventDescription,
        location: eventLocation,
        address: eventAddress,
        distance: eventDistance,
        updatedAt: Timestamp.now(),
        updatedBy: userDetails.uid,
        inAppRegistration: inAppRegistration,
        mainSponsorId: mainSponsorId,
        sponsorIds: sponsorIds,
      };

      // For backward compatibility
      if (mainSponsorId && mainSponsor) {
        eventData.mainSponsor = mainSponsor;
      }

      // If admin has selected a different creator, update the createdBy field
      if (
        userDetails?.role === "admin" &&
        selectedCreator !== event.createdBy
      ) {
        eventData.createdBy = selectedCreator;
      }

      // Update the event in Firestore
      await updateDoc(doc(db, "events", id), eventData);

      // Update the events list in context
      setEvents(
        events.map((e) => (e.id === id ? { ...e, ...eventData, id } : e))
      );

      setLoading(false);
      toast.success("Event updated successfully!");
      navigate("/events");
    } catch (error) {
      setLoading(false);
      toast.error(`Error updating event: ${error}`);
      console.error("Error updating event:", error);
    }
  };

  // New function to handle opening the sponsor modal
  const handleCreateSponsor = () => {
    setShowSponsorModal(true);
  };

  // New function to handle the modal being closed
  const handleCloseSponsorModal = () => {
    setShowSponsorModal(false);
  };

  // New function to handle when a sponsor is saved from the modal
  const handleSponsorSaved = (newSponsor) => {
    if (!mainSponsorId) {
      // Set as main sponsor if no main sponsor is selected
      setMainSponsor(newSponsor);
      setMainSponsorId(newSponsor.id);
      
      if (newSponsor.imageUrl) {
        setMainSponsorImagePreview(newSponsor.imageUrl);
      }
    } else {
      // Add to additional sponsors
      setSponsorIds([...sponsorIds, newSponsor.id]);
      setSelectedSponsors([...selectedSponsors, newSponsor]);
    }
  };

  if (!event) {
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ minHeight: "200px" }}
      >
        <div className="spinner-border text-primary" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  }

  return (
    <div>
      <Announcement />
      <SponsorModal 
        show={showSponsorModal} 
        handleClose={handleCloseSponsorModal} 
        onSponsorSaved={handleSponsorSaved}
      />
      <div className="row">
        <div className="col-xl-12">
          <div className="card">
            <div className="card-header bg-transparent">
              <div className="bg-transparent d-flex align-items-center justify-content-between mb-3">
                <div className="left">
                  <h4 className="">Edit Event</h4>
                  <p className="mb-0">Update event details</p>
                </div>
                <div className="right">
                  <Link to="/events" className="btn btn-primary">
                    <i className="ti ti-arrow-left"></i> Back to Events
                  </Link>
                </div>
              </div>
            </div>
            <form id="editEventForm" onSubmit={handleSubmit}>
              <div className="card-body pt-0 row">
                <div className="col-xl-8">
                  <div className="row">
                    <div className="col-xl-6">
                      <div className="mb-3">
                        <label htmlFor="eventTitle" className="form-label">
                          Event Name <span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="eventTitle"
                          name="eventTitle"
                          value={eventTitle}
                          onChange={(e) => setEventTitle(e.target.value)}
                          required
                        />
                      </div>
                    </div>
                    <div className="col-xl-6">
                      <div className="mb-3">
                        <label htmlFor="eventStartDate" className="form-label">
                          Event Start <span className="text-danger">*</span>
                        </label>
                        <input
                          type="datetime-local"
                          className="form-control"
                          id="eventStartDate"
                          name="eventStartDate"
                          value={eventStartDate}
                          onChange={(e) => setEventStartDate(e.target.value)}
                          required
                        />
                      </div>
                    </div>
                    <div className="col-xl-6">
                      <div className="mb-3">
                        <label htmlFor="eventLocation" className="form-label">
                          Event Location <span className="text-danger">*</span>
                        </label>
                        <GooglePlacesAutocomplete
                          apiKey="AIzaSyD_Lsht21cw3UEhTUP9cPW0R9kylZIqCmM"
                          selectProps={{
                            className:
                              "locationAutoComplete" +
                              (locationError ? " is-invalid" : ""),
                            placeholder: "Search for a location",
                            onFocus: (e) => {
                              e.target.value = "";
                              setLocationError(null);
                            },
                            onChange: handlePlaceSelected,
                            styles: {
                              placeholder: (provided) => ({
                                ...provided,
                                color: "#7c8fac",
                              }),
                              control: (provided) => ({
                                ...provided,
                                background: "transparent",
                                border: "1px solid #333f55",
                                borderRadius: "5px",
                              }),
                              input: (provided) => ({
                                ...provided,
                                color: "#fff",
                                cursor: "text",
                              }),
                              option: (provided) => ({
                                ...provided,
                                color: "#fff",
                                background: "#0F1820",
                                cursor: "pointer",
                              }),
                              dropdownIndicator: (provided) => ({
                                ...provided,
                                display: "none",
                              }),
                              indicatorSeparator: (provided) => ({
                                ...provided,
                                display: "none",
                              }),
                              menu: (provided) => ({
                                ...provided,
                                background: "#0F1820",
                              }),
                              loadingIndicator: (provided) => ({
                                ...provided,
                                color: "#fff",
                              }),
                              loadingMessage: (provided) => ({
                                ...provided,
                                color: "#fff",
                              }),
                              noOptionsMessage: (provided) => ({
                                ...provided,
                                color: "#fff",
                              }),
                              singleValue: (provided) => ({
                                ...provided,
                                color: "#fff",
                              }),
                            },
                          }}
                          apiOptions={{
                            region: "za",
                          }}
                          autocompletionRequest={{
                            componentRestrictions: {
                              country: ["za"],
                            },
                          }}
                        />
                        {locationError ? (
                          <div className="invalid-feedback">
                            {locationError}
                          </div>
                        ) : null}
                        {eventLocation ? (
                          <small className="text-muted">{eventLocation}</small>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-xl-6">
                      <div className="mb-3">
                        <label htmlFor="eventEndDate" className="form-label">
                          Event End <span className="text-danger">*</span>
                        </label>
                        <input
                          type="datetime-local"
                          className="form-control"
                          id="eventEndDate"
                          name="eventEndDate"
                          value={eventEndDate}
                          onChange={(e) => setEventEndDate(e.target.value)}
                          required
                        />
                      </div>
                    </div>
                    <div className="col-xl-6">
                      <div className="mb-3">
                        <label htmlFor="bikeType" className="form-label">
                          Bike Type <span className="text-danger">*</span>
                        </label>
                        <select
                          className="form-select"
                          name="bikeType"
                          id="bikeType"
                          value={bikeType}
                          onChange={(e) => setBikeType(e.target.value)}
                        >
                          <option value="Bikes & Quads">Bikes & Quads</option>
                          <option value="Bikes Only">Bikes Only</option>
                          <option value="Quads Only">Quads Only</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-xl-6">
                      <div className="mb-3">
                        <label htmlFor="riderLevel" className="form-label">
                          Rider Level
                        </label>
                        <select
                          className="form-select"
                          name="riderLevel"
                          id="riderLevel"
                          value={riderLevel}
                          onChange={(e) => setRiderLevel(e.target.value)}
                        >
                          <option value=""></option>
                          <option value="All Levels">All Levels</option>
                          <option value="Beginner">Beginner</option>
                          <option value="Intermediate">Intermediate</option>
                          <option value="Advanced">Advanced</option>
                          <option value="Expert">Expert</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-xl-6">
                      <div className="mb-3">
                        <label htmlFor="eventDistance" className="form-label">
                          Event Distance
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="eventDistance"
                          name="eventDistance"
                          value={eventDistance}
                          onChange={(e) => setEventDistance(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-xl-6">
                      <div className="mb-3">
                        <label htmlFor="eventPrice" className="form-label">
                          Event Price
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="eventPrice"
                          name="eventPrice"
                          value={eventPrice}
                          onChange={(e) => setEventPrice(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-xl-12">
                      <div className="mb-3">
                        <label htmlFor="eventLink" className="form-label">
                          Registration Link
                        </label>
                        <div className="input-group mb-2">
                          <input
                            type="text"
                            className="form-control"
                            id="eventLink"
                            name="eventLink"
                            value={eventLink}
                            onChange={(e) => setEventLink(e.target.value)}
                            required
                          />
                          <div className="input-group-text">
                            <div className="form-check form-switch mb-0">
                              <input
                                className="form-check-input cursor-pointer"
                                type="checkbox"
                                id="inAppRegistration"
                                checked={inAppRegistration}
                                onChange={(e) =>
                                  setInAppRegistration(e.target.checked)
                                }
                              />
                              <label
                                className="form-check-label ms-1 cursor-pointer"
                                htmlFor="inAppRegistration"
                              >
                                In-app registration
                              </label>
                            </div>
                          </div>
                        </div>
                        <small className="text-muted">
                          If in-app registration is enabled, users will register
                          directly through the app. Otherwise, they will be
                          redirected to this external registration link when
                          clicking "Register".
                        </small>
                      </div>
                    </div>
                    <div className="col-xl-12">
                      <div className="mb-3">
                        <label
                          htmlFor="eventDescription"
                          className="form-label"
                        >
                          Event Description
                        </label>
                        <Editor
                          apiKey="7islfbqk6jv9f0kuf9w3b60ce00uv0jagjbof6c9a71v8bw6"
                          value={eventDescription}
                          onEditorChange={(content) =>
                            setEventDescription(content)
                          }
                          init={{
                            height: 500,
                            menubar: false,
                            plugins: [
                              "advlist",
                              "autolink",
                              "lists",
                              "link",
                              "image",
                              "charmap",
                              "preview",
                              "anchor",
                              "searchreplace",
                              "visualblocks",
                              "code",
                              "fullscreen",
                              "insertdatetime",
                              "media",
                              "table",
                              "code",
                              "help",
                              "wordcount",
                            ],
                            toolbar:
                              "undo redo | blocks | code |" +
                              "bold italic | alignleft aligncenter " +
                              "alignright alignjustify | bullist numlist | " +
                              "removeformat",
                            statusbar: false,
                            content_style:
                              "body { background-color: #0F1820; color: #fff; font-family: 'Poppins', sans-serif; }",
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <hr className="my-4" />
                  <h5>Sponsors</h5>
                  <div className="row">
                    <div className="col-xl-12 mb-4">
                      <h6 className="mb-3 d-flex justify-content-between align-items-center">
                        Main Sponsor
                        <button
                          type="button"
                          className="btn btn-sm btn-outline-primary"
                          onClick={handleCreateSponsor}
                        >
                          <i className="ti ti-plus"></i> New Sponsor
                        </button>
                      </h6>

                      {/* Main sponsor selection dropdown */}
                      <div className="mb-3">
                        <label className="form-label">Select Main Sponsor</label>
                        <select
                          className="form-select"
                          value={mainSponsorId}
                          // initialvalue={mainSponsorId}
                          onChange={(e) => {
                            const id = e.target.value;
                            if (id) {
                              const sponsor = sponsors.find(s => s.id === id);
                              if (sponsor) {
                                setMainSponsor(sponsor);
                                setMainSponsorId(id);
                                if (sponsor.imageUrl) {
                                  setMainSponsorImagePreview(sponsor.imageUrl);
                                } else {
                                  setMainSponsorImagePreview(null);
                                }
                              }
                            } else {
                              setMainSponsor({
                                name: "",
                                email: "",
                                contactNumber: "",
                                website: "",
                                imageUrl: "",
                              });
                              setMainSponsorId("");
                              setMainSponsorImagePreview(null);
                            }
                          }}
                        >
                          <option value="">Select a sponsor...</option>
                          {sponsors.length > 0 &&
                            sponsors.map((sponsor) => (
                              <option key={sponsor.id} value={sponsor.id}>
                                {sponsor.name}
                              </option>
                            ))}
                        </select>
                      </div>

                      {/* Display the selected main sponsor details */}
                      {mainSponsor && mainSponsor.name && (
                        <div className="card p-3 mb-3">
                          <div className="d-flex align-items-center">
                            {mainSponsorImagePreview && (
                              <img
                                src={mainSponsorImagePreview}
                                alt={mainSponsor.name}
                                style={{ width: "60px", marginRight: "15px" }}
                              />
                            )}
                            <div>
                              <h5 className="mb-0">{mainSponsor.name}</h5>
                              {mainSponsor.website && (
                                <a href={mainSponsor.website} target="_blank" rel="noopener noreferrer" className="text-muted">
                                  {mainSponsor.website}
                                </a>
                              )}
                              <div className="small text-muted mt-1">
                                {mainSponsor.email && <div>{mainSponsor.email}</div>}
                                {mainSponsor.contactNumber && <div>{mainSponsor.contactNumber}</div>}
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>

                    <div className="col-xl-12">
                      <h6 className="d-flex align-items-center justify-content-between mb-3">
                        Additional Sponsors
                        <div>
                          <button
                            type="button"
                            className="btn btn-sm btn-outline-primary me-2"
                            onClick={handleCreateSponsor}
                          >
                            <i className="ti ti-plus"></i> New Sponsor
                          </button>
                        </div>
                      </h6>

                      {/* Additional sponsors selection dropdown */}
                      <div className="mb-3">
                        <label className="form-label">Add Additional Sponsor</label>
                        <select
                          className="form-select"
                          value={selectedSponsor}
                          onChange={handleSponsorSelect}
                        >
                          <option value="">Select a sponsor...</option>
                          {sponsors &&
                            sponsors.map((sponsor) => (
                              <option key={sponsor.id} value={sponsor.id}>
                                {sponsor.name}
                              </option>
                            ))}
                        </select>
                      </div>

                      {sponsorIds.length === 0 && (
                        <p className="text-muted">No additional sponsors added yet.</p>
                      )}

                      {/* Display selected additional sponsors */}
                      {selectedSponsors.map((sponsor, index) => (
                        <div
                          key={index}
                          className="card p-3 mb-3"
                        >
                          <div className="d-flex justify-content-between align-items-center">
                            <div className="d-flex align-items-center">
                              {sponsor.imageUrl && (
                                <img
                                  src={sponsor.imageUrl}
                                  alt={sponsor.name}
                                  style={{ width: "40px", marginRight: "15px" }}
                                />
                              )}
                              <div>
                                <h6 className="mb-0">{sponsor.name}</h6>
                                {sponsor.website && (
                                  <a href={sponsor.website} target="_blank" rel="noopener noreferrer" className="text-muted small">
                                    {sponsor.website}
                                  </a>
                                )}
                              </div>
                            </div>
                            <button
                              type="button"
                              className="btn btn-sm btn-outline-danger"
                              onClick={() => removeSponsor(index)}
                            >
                              <i className="ti ti-trash"></i>
                            </button>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                <div className="col-xl-4">
                  <div className="mb-3">
                    <label className="form-label">
                      Event Image <span className="text-danger">*</span>
                    </label>
                    <div
                      {...getRootProps({
                        className: "dropzone border rounded-3 p-3 text-center",
                      })}
                    >
                      <input {...getInputProps()} />
                      {preview ? (
                        <div>
                          <img
                            src={preview}
                            alt="Preview"
                            style={{
                              maxWidth: "100%",
                              maxHeight: "150px",
                              marginBottom: "10px",
                            }}
                          />
                          <button
                            type="button"
                            className="btn btn-danger btn-sm"
                            onClick={removeFile}
                          >
                            Remove File
                          </button>
                        </div>
                      ) : (
                        <p className="mb-0">
                          Drag an image here, or click to upload
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-footer d-flex justify-content-end">
                {userDetails?.role === "admin" && (
                  <div className="d-flex flex-row gap-2 align-items-center me-5">
                    <div className="form-check form-switch">
                      <input
                        className="form-check-input cursor-pointer"
                        type="checkbox"
                        id="publishedToggle"
                        checked={event.published}
                        onChange={(e) => {
                          setEvent({
                            ...event,
                            published: e.target.checked,
                          });
                        }}
                      />
                      <label
                        className="form-check-label cursor-pointer"
                        htmlFor="publishedToggle"
                      >
                        Published
                      </label>
                    </div>
                    <div className="form-check form-switch">
                      <input
                        className="form-check-input cursor-pointer"
                        type="checkbox"
                        id="featuredToggle"
                        checked={event.featured}
                        onChange={(e) => {
                          setEvent({
                            ...event,
                            featured: e.target.checked,
                          });
                        }}
                      />
                      <label
                        className="form-check-label cursor-pointer"
                        htmlFor="featuredToggle"
                      >
                        Featured
                      </label>
                    </div>
                    <div className="form-group ms-4">
                      <select
                        className="form-select"
                        value={selectedCreator}
                        onChange={(e) => setSelectedCreator(e.target.value)}
                      >
                        <option value="">Select Organiser</option>
                        {users.map((user) => (
                          <option key={user.id} value={user.id}>
                            {user.firstName} {user.lastName} ({user.email})
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                )}
                <button
                  type="submit"
                  className="btn btn-primary d-flex align-items-center"
                >
                  {uploading || loading ? (
                    <div className="spin me-2">
                      <i className="ti ti-loader"></i>
                    </div>
                  ) : null}
                  Update Event
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditEvent;
