import React, { useEffect, useState, useCallback, useMemo, useRef } from "react";
import { useAuth } from "../../../stores/AuthContext";
import { Link, useNavigate } from "react-router-dom";
import DataTable from "datatables.net-react";
import DT from "datatables.net-dt";
import "./events.css";
import Announcement from "../../../components/announcement";
import { Tooltip } from "react-tooltip";
import {
  deleteEvent,
  updateEvent,
  notifyProUsers,
} from "../../../services/firebase_service";
import { toast } from "react-toastify";
import { collection, query, getCountFromServer } from "firebase/firestore";
import { db } from "../../../firebase";
import { globalSettings } from "../../../stores/GlobalContext";

DataTable.use(DT);

function Events() {
  document.querySelector("title").innerHTML =
      "Events | " + globalSettings.appName;
  const { events, user, setEvents, userDetails } = useAuth();
  const [filteredEvents, setFilteredEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [tableData, setTableData] = useState({});
  const [entriesCounts, setEntriesCounts] = useState({});
  const [showShareModal, setShowShareModal] = useState(false);
  const [shareEventId, setShareEventId] = useState(null);
  const [shareEventTitle, setShareEventTitle] = useState("");
  
  // Add a ref to track the DataTable instance
  const dataTableRef = useRef(null);
  const dataTableInitialized = useRef(false);

  const navigate = useNavigate();

  // Memoize table options to prevent recreating this object on each render
  const tableOptions = useMemo(() => ({
    order: [[6, "desc"]],
    paging: true,
    searching: true,
    lengthMenu: [10, 25, 50, 75, 100],
    language: {
      emptyTable: "No events available to display",
    },
    columnDefs: [
      {
        targets: [0, 1],
        visible: false,
      },
      {
        targets: [2],
        render: function (data, type, row) {
          return data
            ? `<div class="d-flex"><img src="${
                row[1]
              }" class="rounded-circle cursor-pointer me-2 object-fit-cover" width="40" height="40" onClick="navigateToEvent('${
                row[0]
              }')" /><div class="desc"><div class="d-flex align-items-center gap-2"><span class="text-primary text-body fw-bold underLink" style="cursor:pointer;" onClick="navigateToEvent('${
                row[0]
              }')">${data.split(",")[0]}</span>${
                row[8]
                  ? `<button class="btn btn-sm btn-outline-primary" onclick="shareEvent('${row[0]}', '${data.split(",")[0]}')"><i class="ti ti-share"></i></button>`
                  : ""
              }</div><small class="text-muted">${
                data.split(",")[1]
              }</small></div></div>`
            : ``;
        },
      },
      {
        targets: [6],
        render: function (data, type, row) {
          if (type === "sort" || type === "type") {
            return new Date(data.split(" ").join(" ")).getTime();
          }
          return `<span class="text-body fw-bold">${data}</span>`;
        },
      },
      {
        targets: [3],
        render: function (data, type, row) {
          return `<span class="badge bg-primary text-secondary cursor-pointer" onClick="navigateToEvent('${row[0]}')">${data}</span>`;
        },
      },
      {
        targets: [8],
        render: function (data, type, row) {
          if (user.role === "admin") {
            return `<div class="form-check form-switch py-2">
                    <input class="form-check-input cursor-pointer" type="checkbox" id="status${
                      row[0]
                    }" ${
              data ? "checked" : ""
            } onChange="window.updateStatus('${row[0]}')">
                  </div>`;
          } else {
            if (data) {
              return `<span class="badge bg-success">Published</span>`;
            } else {
              return `<span class="badge bg-warning">Pending</span>`;
            }
          }
        },
      },
      {
        targets: [9],
        sortable: false,
        render: function (data, type, row) {
          if (userDetails.role === "admin") {
            return `<div class="dropdown">
                    <button type="button" class="btn btn-dark dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                      Actions
                    </button>
                    <div class="dropdown-menu" style="">
                      <a class="dropdown-item waves-effect cursor-pointer" onClick="notifyPros('${
                        row[0]
                      }')"><i class="ti ti-bell me-1"></i> Notify Pro</a>
                      <a class="dropdown-item waves-effect cursor-pointer" onClick="viewEvent('${
                        row[0]
                      }')"><i class="ti ti-list me-1"></i> View Entries</a>
                      <a class="dropdown-item waves-effect cursor-pointer" onClick="editEvent('${
                        row[0]
                      }')"><i class="ti ti-pencil me-1"></i> Edit</a>
                      <a class="dropdown-item waves-effect cursor-pointer" onClick="duplicateEvent('${
                        row[0]
                      }')"><i class="ti ti-copy me-1"></i> Duplicate</a>
                      <a class="dropdown-item waves-effect cursor-pointer" onClick="deleteEvent('${
                        row[0]
                      }')"><i class="ti ti-trash me-1"></i> Delete</a>
                    </div>
                  </div>`;
          } else {
            return `<div class="dropdown">
                    <button type="button" class="btn p-0 dropdown-toggle hide-arrow" data-bs-toggle="dropdown" aria-expanded="false">
                      <i class="ti ti-dots-vertical"></i>
                    </button>
                    <div class="dropdown-menu" style="">
                      <a class="dropdown-item waves-effect cursor-pointer" onClick="viewEvent('${
                        row[0]
                      }')"><i class="ti ti-list me-1"></i> View Entries</a>
                      <a class="dropdown-item waves-effect cursor-pointer" onClick="editEvent('${
                        row[0]
                      }')"><i class="ti ti-pencil me-1"></i> Edit</a>
                      <a class="dropdown-item waves-effect cursor-pointer" onClick="duplicateEvent('${
                        row[0]
                      }')"><i class="ti ti-copy me-1"></i> Duplicate</a>
                      <a class="dropdown-item waves-effect cursor-pointer" onClick="deleteEvent('${
                        row[0]
                      }')"><i class="ti ti-trash me-1"></i> Delete</a>
                    </div>
                  </div>`;
          }
        },
      },
    ],
  }), [user.role, userDetails.role]);

  useEffect(() => {
    if (events) {
      setFilteredEvents(events);
      setLoading(false);
    }
  }, [events]);

  // Optimize fetch entries counts with batched requests and getCountFromServer
  useEffect(() => {
    const fetchEntriesCounts = async () => {
      if (!events || events.length === 0) return;
      
      const counts = {};
      const batchSize = 10; // Process in batches to avoid too many parallel requests
      
      for (let i = 0; i < events.length; i += batchSize) {
        const batch = events.slice(i, i + batchSize);
        const countPromises = batch.map(async (event) => {
          const entriesRef = collection(db, `events/${event.id}/entries`);
          const snapshot = await getCountFromServer(query(entriesRef));
          return { id: event.id, count: snapshot.data().count };
        });
        
        const results = await Promise.all(countPromises);
        results.forEach(result => {
          counts[result.id] = result.count;
        });
      }
      
      setEntriesCounts(counts);
    };

    if (events && events.length > 0) {
      fetchEntriesCounts();
    }
  }, [events]);

  // Memoize table data to prevent unnecessary calculations
  useEffect(() => {
    if (!filteredEvents.length) return;
    
    const newTableData = filteredEvents.map((event) => {
      const eventContent = event.description.replace(/<\/?[^>]+(>|$)/g, "");

      return [
        event.id,
        event.imageUrl ?? "https://via.placeholder.com/150",
        `${event.title},${eventContent.substring(0, 50)}` ?? "",
        entriesCounts[event.id] || 0,
        event.location.split(",")[1] ?? "",
        event.riderLevel ?? "",
        event.date
          ? new Date(event.date.seconds * 1000).toLocaleDateString("en-GB", {
              day: "2-digit",
              month: "short",
              year: "numeric",
            })
          : "",
        event.price ?? "",
        event.published ?? "",
        event.id,
      ];
    });
    
    setTableData(newTableData);
  }, [filteredEvents, entriesCounts]);

  // Callbacks for events actions
  const updateStatus = useCallback(async (eventId) => {
    const event = events.find((event) => event.id === eventId);
    if (!event) return;
    
    event.published = !event.published;

    try {
      await updateEvent(eventId, event);
      const updatedEvents = events.map((e) => (e.id === eventId ? event : e));
      setEvents(updatedEvents);
      if (event.published) {
        toast.success("Event published!");
      } else {
        toast.success("Event unpublished!");
      }
    } catch (error) {
      toast.error("Error: Unable to update event status");
      console.error("Error updating event status", error);
    }
  }, [events, setEvents]);

  const copyEventLink = useCallback(async (eventId) => {
    const shareLink = `https://share.funduro.app/event/${eventId}`;
    try {
      await navigator.clipboard.writeText(shareLink);
      toast.success("Link copied to clipboard!");
    } catch (error) {
      console.error("Failed to copy link:", error);
      toast.error("Failed to copy link");
    }
  }, []);

  const handleDeleteEvent = useCallback(async (eventId) => {
    if (window.confirm("Are you sure you want to delete this event?")) {
      try {
        await deleteEvent(eventId);
        const updatedEvents = events.filter((e) => e.id !== eventId);
        setEvents(updatedEvents);
        toast.success("Event deleted successfully!");
      } catch (error) {
        toast.error("Error: Unable to delete event");
        console.error("Error deleting event", error);
      }
    }
  }, [events, setEvents]);

  const handleNotifyPros = useCallback(async (eventId) => {
    const event = events.find((event) => event.id === eventId);
    if (!event) {
      toast.error("Event not found");
      return;
    }

    const eventDetails = {
      title: event.title,
      date: event.date,
      location: event.location,
      imageUrl: event.imageUrl,
    };

    try {
      await notifyProUsers(eventId, eventDetails);
      toast.success("Pro users have been notified");
    } catch (error) {
      toast.error("Failed to notify users");
      console.error("Error notifying users", error);
    }
  }, [events]);

  const viewEvent = useCallback((eventId) => {
    navigate(`/event/${eventId}/entries`);
  }, [navigate]);

  const editEvent = useCallback((eventId) => {
    navigate(`/event/${eventId}/edit`);
  }, [navigate]);

  const duplicateEvent = useCallback((eventId) => {
    navigate(`/event/new?duplicate=${eventId}`);
  }, [navigate]);

  const navigateToEvent = useCallback((eventId) => {
    navigate(`/event/${eventId}/entries`);
  }, [navigate]);

  const shareEvent = useCallback((eventId, eventTitle) => {
    setShareEventId(eventId);
    setShareEventTitle(eventTitle);
    setShowShareModal(true);
  }, []);

  // Setup global event handlers
  useEffect(() => {
    window.updateStatus = updateStatus;
    window.copyEventLink = copyEventLink;
    window.deleteEvent = handleDeleteEvent;
    window.notifyPros = handleNotifyPros;
    window.viewEvent = viewEvent;
    window.editEvent = editEvent;
    window.duplicateEvent = duplicateEvent;
    window.navigateToEvent = navigateToEvent;
    window.shareEvent = shareEvent;

    // Clean up the functions when the component unmounts
    return () => {
      delete window.navigateToEvent;
      delete window.updateStatus;
      delete window.notifyPros;
      delete window.viewEvent;
      delete window.editEvent;
      delete window.duplicateEvent;
      delete window.deleteEvent;
      delete window.copyEventLink;
      delete window.shareEvent;
    };
  }, [updateStatus, copyEventLink, handleDeleteEvent, handleNotifyPros, viewEvent, editEvent, duplicateEvent, navigateToEvent, shareEvent]);

  // Function to handle social media sharing
  const handleShare = useCallback((platform) => {
    const shareUrl = `https://share.funduro.app/event/${shareEventId}`;
    const title = `Check out this event: ${shareEventTitle}`;

    let shareLink = "";

    switch (platform) {
      case "facebook":
        shareLink = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
          shareUrl
        )}`;
        break;
      case "twitter":
        shareLink = `https://twitter.com/intent/tweet?url=${encodeURIComponent(
          shareUrl
        )}&text=${encodeURIComponent(title)}`;
        break;
      case "whatsapp":
        shareLink = `https://api.whatsapp.com/send?text=${encodeURIComponent(
          title + " " + shareUrl
        )}`;
        break;
      case "telegram":
        shareLink = `https://telegram.me/share/url?url=${encodeURIComponent(
          shareUrl
        )}&text=${encodeURIComponent(title)}`;
        break;
      case "email":
        shareLink = `mailto:?subject=${encodeURIComponent(
          title
        )}&body=${encodeURIComponent(
          "I thought you might be interested in this event: " + shareUrl
        )}`;
        break;
      case "copy":
        navigator.clipboard
          .writeText(shareUrl)
          .then(() => toast.success("Link copied to clipboard!"))
          .catch(() => toast.error("Failed to copy link"));
        setShowShareModal(false);
        return;
      default:
        return;
    }

    window.open(shareLink, "_blank", "noopener,noreferrer");
    setShowShareModal(false);
  }, [shareEventId, shareEventTitle]);

  // Memoize the share modal to prevent unnecessary rerenders
  const shareModal = useMemo(() => {
    if (!showShareModal) return null;
    
    return (
      <div
        className="modal fade show"
        style={{ display: "block", backgroundColor: "rgba(0,0,0,0.5)" }}
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Share Event</h5>
              <button
                type="button"
                className="btn-close"
                onClick={() => setShowShareModal(false)}
              ></button>
            </div>
            <div className="modal-body">
              <h6 className="mb-3">Share "{shareEventTitle}"</h6>
              <div className="d-flex flex-wrap gap-2 justify-content-center">
                <button
                  onClick={() => handleShare("facebook")}
                  className="btn btn-outline-primary btn-icon rounded-circle fs-7"
                >
                  <i className="ti ti-brand-facebook"></i>
                </button>
                <button
                  onClick={() => handleShare("twitter")}
                  className="btn btn-outline-info btn-icon rounded-circle fs-7"
                >
                  <i className="ti ti-brand-twitter"></i>
                </button>
                <button
                  onClick={() => handleShare("whatsapp")}
                  className="btn btn-outline-success btn-icon rounded-circle fs-7"
                >
                  <i className="ti ti-brand-whatsapp"></i>
                </button>
                <button
                  onClick={() => handleShare("telegram")}
                  className="btn btn-outline-info btn-icon rounded-circle fs-7"
                >
                  <i className="ti ti-brand-telegram"></i>
                </button>
                <button
                  onClick={() => handleShare("email")}
                  className="btn btn-outline-secondary btn-icon rounded-circle fs-7"
                >
                  <i className="ti ti-mail"></i>
                </button>
                <button
                  onClick={() => handleShare("copy")}
                  className="btn btn-outline-dark btn-icon rounded-circle fs-7"
                >
                  <i className="ti ti-copy"></i>
                </button>
              </div>
              <div className="form-group mt-3">
                <div className="input-group">
                  <input
                    type="text"
                    className="form-control"
                    value={`https://share.funduro.app/event/${shareEventId}`}
                    readOnly
                  />
                  <button
                    className="btn btn-outline-primary"
                    type="button"
                    onClick={() => handleShare("copy")}
                  >
                    <i className="ti ti-copy"></i> Copy
                  </button>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                onClick={() => setShowShareModal(false)}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }, [showShareModal, shareEventId, shareEventTitle, handleShare]);

  // Clean up DataTable when component unmounts or before re-initialization
  useEffect(() => {
    return () => {
      if (dataTableInitialized.current && dataTableRef.current) {
        try {
          // Try to destroy the DataTable to prevent ResizeObserver issues
          dataTableRef.current.destroy();
          dataTableInitialized.current = false;
        } catch (error) {
          console.error("Error destroying DataTable:", error);
        }
      }
    };
  }, []);

  // Modified to handle DataTable initialization
  useEffect(() => {
    if (events) {
      // Set a short delay to ensure DOM is ready
      setTimeout(() => {
        setFilteredEvents(events);
        setLoading(false);
      }, 50);
    }
  }, [events]);

  // Memoize table data to prevent unnecessary calculations
  useEffect(() => {
    if (!filteredEvents.length) return;
    
    // Clean up previous instance before creating a new one
    if (dataTableInitialized.current && dataTableRef.current) {
      try {
        dataTableRef.current.destroy();
      } catch (error) {
        console.error("Error destroying DataTable:", error);
      }
      dataTableInitialized.current = false;
    }
    
    const newTableData = filteredEvents.map((event) => {
      const eventContent = event.description.replace(/<\/?[^>]+(>|$)/g, "");

      return [
        event.id,
        event.imageUrl ?? "https://via.placeholder.com/150",
        `${event.title},${eventContent.substring(0, 50)}` ?? "",
        entriesCounts[event.id] || 0,
        event.location.split(",")[1] ?? "",
        event.riderLevel ?? "",
        event.date
          ? new Date(event.date.seconds * 1000).toLocaleDateString("en-GB", {
              day: "2-digit",
              month: "short",
              year: "numeric",
            })
          : "",
        event.price ?? "",
        event.published ?? "",
        event.id,
      ];
    });
    
    setTableData(newTableData);
  }, [filteredEvents, entriesCounts]);

  return (
    <div>
      <Announcement />
      <div className="row">
        <div className="col-xl-12">
          <div className="card">
            <div className="card-header bg-transparent">
              <div className="bg-transparent d-flex align-items-center justify-content-between mb-3">
                <div className="left">
                  <h4 className="">Events</h4>
                  <p className="mb-0">Manage and view all registered events</p>
                </div>
                <div className="right">
                  <Tooltip id="tooltopBottom" place="left" />
                  {!user.emailVerified ? (
                    <div
                      data-tooltip-id="tooltopBottom"
                      data-tooltip-content="You need to verify your email address first"
                      className="cursor-not-allowed"
                    >
                      <button className="btn btn-primary" disabled>
                        <i className="ti ti-plus"></i> New
                      </button>
                    </div>
                  ) : (
                    <Link to="/event/new" className="btn btn-primary">
                      <i className="ti ti-plus"></i> New
                    </Link>
                  )}
                  <Tooltip id="disabledBtn" />
                </div>
              </div>
            </div>
            <div className="card-body pt-0">
              <div className="alert alert-info">
                <div className="d-flex align-items-center gap-2">
                  <i className="ti ti-info"></i>
                  <span>
                    Click on the event title to view more details and entries
                  </span>
                </div>
              </div>
              <div className="table-responsive rounded-4" data-bs-theme="dark">
                {!loading ? (
                  <DataTable
                    className="table text-nowrap usersTable table-dark mb-0 align-middle"
                    options={tableOptions}
                    data={tableData}
                    ref={(table) => {
                      if (table) {
                        dataTableRef.current = table;
                        dataTableInitialized.current = true;
                      }
                    }}
                  >
                    <thead>
                      <tr>
                        <th className="fs-4 fw-semibold mb-0">ID</th>
                        <th className="fs-4 fw-semibold mb-0">Image</th>
                        <th className="fs-4 fw-semibold mb-0">Title</th>
                        <th className="fs-4 fw-semibold mb-0">Entries</th>
                        <th className="fs-4 fw-semibold mb-0">Province</th>
                        <th className="fs-4 fw-semibold mb-0">Rider Level</th>
                        <th className="fs-4 fw-semibold mb-0">Date</th>
                        <th className="fs-4 fw-semibold mb-0">Price</th>
                        <th className="fs-4 fw-semibold mb-0">Status</th>
                        <th>&nbsp;</th>
                      </tr>
                    </thead>
                  </DataTable>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Share Modal - Render the memoized modal */}
      {shareModal}
    </div>
  );
}

// Export memoized component to prevent unnecessary renders when parent re-renders
export default React.memo(Events);