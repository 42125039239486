import React, { useEffect, useState } from 'react';
import { useAuth } from '../../stores/AuthContext';
import DataTable from 'datatables.net-react';
import DT from 'datatables.net-dt';
import { collection, query, getDocs, where } from 'firebase/firestore';
import { db } from '../../firebase';
import { Link, useNavigate } from 'react-router-dom';
import Announcement from '../../components/announcement';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';
import { globalSettings } from '../../stores/GlobalContext';

DataTable.use(DT);

function GlobalEntries() {
  document.querySelector("title").innerHTML = "Global Entries | " + globalSettings.appName;
  
  const { user, events, users } = useAuth();
  const [allEntries, setAllEntries] = useState([]);
  const [filteredEntries, setFilteredEntries] = useState([]);
  const [loading, setLoading] = useState(true);
  const [userEvents, setUserEvents] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState('all');
  const [dateRange, setDateRange] = useState({
    startDate: null,
    endDate: null
  });
  const [tableData, setTableData] = useState({});
  const navigate = useNavigate();

  const tableOptions = {
    order: [[1, 'desc']],
    paging: true,
    searching: true,
    lengthMenu: [10, 25, 50, 75, 100],
    language: {
      emptyTable: "No entries found",
    },
    columnDefs: [
      {
        targets: [0],
        visible: false,
      },
      {
        targets: [2],
        render: function (data, type, row) {
          return `<span class="text-primary fw-bold cursor-pointer" onclick="navigateToEventEntries('${row[0].split('|')[1]}')">${data}</span>`;
        }
      },
      {
        targets: [3],
        render: function (data, type, row) {
          return `<a href="mailto:${data}" class="underLink" target="_blank">${data}</a>`;
        },
      },
      {
        targets: [5],
        render: function (data, type, row) {
          return `<a href="tel:${data}" class="underLink" target="_blank">${data}</a>`;
        },
      },
    {
        targets: [3],
        sortable: false,
      },
      {
        targets: [1],
        render: function (data, type) {
          if (type === 'sort' || type === 'type') {
            return new Date(data).getTime();
          }
          return data;
        }
      }
    ]
  };

  // Fetch events created by the current user
  useEffect(() => {
    if (events && events.length > 0) {
      const filteredEvents = events.filter(event => event.createdBy === user.uid);
      setUserEvents(filteredEvents);
    }
  }, [events, user]);

  // Fetch all entries for user's events
  useEffect(() => {
    const fetchAllEntries = async () => {
      if (!userEvents.length) return;
      
      setLoading(true);
      const entriesData = [];
      
      for (const event of userEvents) {
        try {
          const entriesRef = collection(db, `events/${event.id}/entries`);
          const entriesSnapshot = await getDocs(query(entriesRef));
          
          entriesSnapshot.forEach(doc => {
            const entry = doc.data();
            entriesData.push({
              ...entry,
              id: doc.id,
              eventId: event.id,
              eventTitle: event.title,
              entryDate: entry.createdAt ? new Date(entry.createdAt.seconds * 1000) : new Date()
            });
          });
        } catch (error) {
          console.error(`Error fetching entries for event ${event.id}:`, error);
        }
      }
      
      setAllEntries(entriesData);
      setFilteredEntries(entriesData);
      setLoading(false);
    };

    if (userEvents.length > 0) {
      fetchAllEntries();
    }
  }, [userEvents]);

  // Apply filters when filter values change
  useEffect(() => {
    if (allEntries.length === 0) return;
    
    let filtered = [...allEntries];
    
    // Apply event filter
    if (selectedEvent !== 'all') {
      filtered = filtered.filter(entry => entry.eventId === selectedEvent);
    }
    
    // Apply date range filter
    if (dateRange.startDate && dateRange.endDate) {
      const start = dateRange.startDate.startOf('day').valueOf();
      const end = dateRange.endDate.endOf('day').valueOf();
      
      filtered = filtered.filter(entry => {
        const entryTime = entry.entryDate.getTime();
        return entryTime >= start && entryTime <= end;
      });
    }
    
    setFilteredEntries(filtered);
  }, [selectedEvent, dateRange, allEntries]);

  const getUserDetails = (id) => {
    const user = users?.find(user => user.uid === id);
    return user || {};
  };

  // Prepare data for the DataTable
  useEffect(() => {
    setTableData(
      filteredEntries.map((entry) => {
        const userDetail = getUserDetails(entry.userId) || {};
        const formattedDate = entry.createdAt?.seconds
          ? new Date(entry.createdAt.seconds * 1000).toLocaleDateString("en-GB", {
              day: "2-digit",
              month: "short",
              year: "numeric",
              hour: "2-digit",
              minute: "2-digit",
            })
          : "Unknown";

        return [
          `${entry.id}|${entry.eventId}`,
          formattedDate,
          entry.eventTitle,
          `${userDetail?.firstName || ""} ${userDetail?.lastName || ""}`.trim() || "Unknown",
          userDetail?.email || "Unknown",
          userDetail?.phoneNumber?.number ? `0${userDetail.phoneNumber.number}` : "Unknown"
        ];
      })
    );
  }, [filteredEntries, users]);

  const handleEventFilterChange = (e) => {
    setSelectedEvent(e.target.value);
  };

  const handleDateRangeChange = (event, picker) => {
    setDateRange({
      startDate: picker.startDate,
      endDate: picker.endDate
    });
  };

  const clearDateRange = () => {
    setDateRange({
      startDate: null,
      endDate: null
    });
  };

  const formatDateRange = () => {
    if (dateRange.startDate && dateRange.endDate) {
      return `${dateRange.startDate.format('MMM D, YYYY')} - ${dateRange.endDate.format('MMM D, YYYY')}`;
    }
    return 'All Dates';
  };

  // Setup window functions for navigation
  useEffect(() => {
    window.navigateToEventEntries = (eventId) => {
      navigate(`/event/${eventId}/entries`);
    };
    
    // Clean up function when component unmounts
    return () => {
      delete window.navigateToEventEntries;
    };
  }, [navigate]);
  
  return (
    <div>
      <Announcement />
      <div className="row">
        <div className="col-xl-12">
          <div className="card">
            <div className="card-header bg-transparent">
              <div className="bg-transparent d-flex align-items-center justify-content-between mb-3">
                <div className="left">
                  <h4 className="">Global Entries</h4>
                  <p className="mb-0">View all entries across your events</p>
                </div>
              </div>
            </div>
            
            <div className="card-body">
              {/* Filters */}
              <div className="row mb-4">
                <div className="col-md-6">
                  <div className="form-group mb-3">
                    <label className="form-label">Filter by Event</label>
                    <select 
                      className="form-select" 
                      value={selectedEvent} 
                      onChange={handleEventFilterChange}
                    >
                      <option value="all">All Events</option>
                      {userEvents.map(event => (
                        <option key={event.id} value={event.id}>
                          {event.title}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="form-label">Filter by Date Range</label>
                    <div className="input-group">
                      <DateRangePicker
                        initialSettings={{ 
                          autoUpdateInput: false,
                          locale: { cancelLabel: 'Clear' }
                        }}
                        onApply={handleDateRangeChange}
                        onCancel={clearDateRange}
                      >
                        <input 
                          type="text" 
                          className="form-control" 
                          value={formatDateRange()} 
                          readOnly
                        />
                      </DateRangePicker>
                      {(dateRange.startDate && dateRange.endDate) && (
                        <button 
                          className="btn btn-outline-secondary" 
                          type="button"
                          onClick={clearDateRange}
                        >
                          <i className="ti ti-x"></i>
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              {/* Table */}
              <div className="alert alert-info">
                <div className="d-flex align-items-center gap-2">
                  <i className="ti ti-info"></i>
                  <span>Click on the event name to view detailed entries for that specific event</span>
                </div>
              </div>

              <div className="table-responsive rounded-4" data-bs-theme="dark">
                {!loading ? (
                  <DataTable
                    className="table text-nowrap entriesTable table-dark mb-0 align-middle"
                    options={tableOptions}
                    data={tableData}
                  >
                    <thead>
                      <tr>
                        <th>ID</th>
                        <th>Date</th>
                        <th>Event</th>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Phone</th>
                      </tr>
                    </thead>
                  </DataTable>
                ) : (
                  <div className="d-flex justify-content-center py-5">
                    <div className="spinner-border text-primary" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  </div>
                )}
              </div>

              {/* No entries message */}
              {!loading && filteredEntries.length === 0 && (
                <div className="text-center py-5">
                  <i className="ti ti-clipboard-x fs-1 text-muted"></i>
                  <h4 className="mt-3">No entries found</h4>
                  <p className="text-muted">
                    {userEvents.length === 0 
                      ? "You haven't created any events yet." 
                      : "No entries match your current filter criteria."}
                  </p>
                  {userEvents.length === 0 && (
                    <Link to="/event/new" className="btn btn-primary mt-2">
                      <i className="ti ti-plus me-2"></i>Create Event
                    </Link>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default GlobalEntries;