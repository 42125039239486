import React, { useCallback, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { useDropzone } from "react-dropzone";
import { storage } from "../../../firebase";
import { toast } from "react-toastify";
import GooglePlacesAutocomplete, {
  geocodeByPlaceId,
} from "react-google-places-autocomplete";
import Select from "react-select";
import Announcement from "../../../components/announcement";
import { useAuth } from "../../../stores/AuthContext";
import { addRidingSpot } from "../../../services/firebase_service";

function NewRidingSpot() {
  const { setRidingSpots, userDetails } = useAuth();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const [spotName, setSpotName] = useState(null);
  const [price, setPrice] = useState("");
  const [contactEmail, setContactEmail] = useState("");
  const [contactNumber, setContactNumber] = useState("");
  const [location, setLocation] = useState([]);
  const [locationError, setLocationError] = useState("");
  const [distance, setDistance] = useState("");
  const [description, setDescription] = useState("");
  const [image, setImage] = useState("");
  const [published, setPublished] = useState(false);
  const [bikeType, setBikeType] = useState("Bikes & Quads");
  const [riderLevel, setRiderLevel] = useState("");
  const [ridingTypes, setRidingTypes] = useState([]);
  const [createdAt, setCreatedAt] = useState("");
  const [spotJson, setSpotJson] = useState("");

  const [selectedFile, setSelectedFile] = useState(null);
  const [preview, setPreview] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [uploadedImageUrl, setUploadedImageUrl] = useState("");

  const handleDrop = useCallback((acceptedFiles) => {
    if (acceptedFiles && acceptedFiles.length) {
      const file = acceptedFiles[0];
      setSelectedFile(file);
      setPreview(URL.createObjectURL(file));
    }
  }, []);

  const removeFile = () => {
    setSelectedFile(null);
    setPreview(null);
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: handleDrop,
    accept: {
      "image/*": [".jpeg", ".jpg", ".png", ".gif"],
    },
    maxFiles: 1,
  });

  const uploadImage = async () => {
    if (!selectedFile) {
      return null;
    }

    try {
      setUploading(true);
      const storageRef = ref(
        storage,
        `ridingSpotImages/${Date.now()}_${selectedFile.name}`
      );
      const uploadTask = uploadBytesResumable(storageRef, selectedFile);

      return new Promise((resolve, reject) => {
        uploadTask.on(
          "state_changed",
          null,
          (error) => {
            console.error("Upload error:", error);
            toast.error("Failed to upload the image.");
            setUploading(false);
            reject(null);
          },
          async () => {
            try {
              const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
              setUploadedImageUrl(downloadURL);
              setUploading(false);
              resolve(downloadURL);
            } catch (error) {
              console.error("Error getting download URL:", error);
              setUploading(false);
              reject(null);
            }
          }
        );
      });
    } catch (error) {
      console.error("Error in uploadImage:", error);
      setUploading(false);
      return null;
    }
  };

  const handlePlaceSelected = (value) => {
    const placeId = value.value.place_id;

    geocodeByPlaceId(placeId)
      .then((results) => {
        // Extract address components
        const addressComponents = results[0].address_components;

        // Find suburb (locality or sublocality)
        const suburb = addressComponents.find(
          (component) =>
            component.types.includes("sublocality") ||
            component.types.includes("locality")
        )?.long_name;

        // Find province (administrative_area_level_1)
        const province = addressComponents.find((component) =>
          component.types.includes("administrative_area_level_1")
        )?.long_name;

        // You can now set these values in your state if needed
        if (suburb && province) {
          const locationData = {
            suburb: suburb,
            province: province,
            city:
              addressComponents.find((component) =>
                component.types.includes("locality")
              )?.long_name || "",
            address: results[0].formatted_address,
            place: results[0].place_id || "",
            street:
              addressComponents.find((component) =>
                component.types.includes("route")
              )?.long_name || "",
          };
          setLocation(locationData);
        } else {
          setLocationError(
            "The location you selected is not valid. Please search for a valid location."
          );
        }
      })
      .catch((error) => console.error(error));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      let finalImageUrl = "";

      // Upload image first if there's a selected file
      if (selectedFile) {
        finalImageUrl = await uploadImage();
        if (!finalImageUrl) {
          toast.error("Failed to upload image");
          setLoading(false);
          return;
        }
      }

      const updatedSpot = {
        name: spotName || "",
        price: price || "",
        contactEmail: contactEmail || "",
        contactNumber: contactNumber || "",
        location: location || "",
        distance: distance || "",
        description: description || "",
        imageUrl: finalImageUrl, // Use the uploaded image URL
        published: published || false,
        bikeType: bikeType || "",
        riderLevel: riderLevel || "",
        ridingTypes: ridingTypes || [],
        createdAt: new Date(),
        updatedAt: new Date(),
        createdBy: userDetails?.id || "",
      };

      if (!spotName || !bikeType) {
        toast.error("Please fill in all required fields.");
        setLoading(false);
        return;
      }

      // Add the riding spot to Firebase
      await addRidingSpot(updatedSpot);

      // Update local state
      setRidingSpots((prev) => [...prev, updatedSpot]);

      toast.success("Riding Spot added successfully!");
      setLoading(false);
      navigate("/riding-spots");
    } catch (error) {
      console.error("Error saving riding spot:", error);
      toast.error("Error saving riding spot. Please try again.");
      setLoading(false);
    }
  };

  const processJson = () => {};

  return (
    <div>
      <Announcement />
      <div className="row">
        <div className="col-xl-12">
          <div className="card">
            <div className="card-header bg-transparent">
              <div className="bg-transparent d-flex align-items-center justify-content-between mb-3">
                <div className="left">
                  <h4 className="">Edit Riding Spot</h4>
                  <p className="mb-0">Add your Riding Spot details below</p>
                </div>
                <div className="right">
                  <Link to="/riding-spots" className="btn btn-primary">
                    <i className="ti ti-arrow-left"></i> Back to Riding Spots
                  </Link>
                </div>
              </div>
            </div>

            <form id="editRidingSpot" onSubmit={handleSubmit}>
              <div className="card-body pt-0 row">
                <div className="col-xl-8">
                  <div className="row">
                    <div className="col-xl-6">
                      <div className="mb-3">
                        <label htmlFor="eventTitle" className="form-label">
                          Riding Spot Name{" "}
                          <span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="spotName"
                          name="spotName"
                          value={spotName}
                          onChange={(e) => setSpotName(e.target.value)}
                          required
                        />
                      </div>
                    </div>

                    <div className="col-xl-6">
                      <div className="mb-3">
                        <label htmlFor="contactEmail" className="form-label">
                          Contact Email <span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="contactEmail"
                          name="contactEmail"
                          value={contactEmail}
                          onChange={(e) => setContactEmail(e.target.value)}
                          required
                        />
                      </div>
                    </div>

                    <div className="col-xl-6">
                      <div className="mb-3">
                        <label htmlFor="contactNumber" className="form-label">
                          Contact Number <span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="contactNumber"
                          name="contactNumber"
                          value={contactNumber}
                          onChange={(e) => setContactNumber(e.target.value)}
                          required
                        />
                      </div>
                    </div>

                    <div className="col-xl-6">
                      <div className="mb-3">
                        <label htmlFor="bikeType" className="form-label">
                          Bike Type <span className="text-danger">*</span>
                        </label>
                        <select
                          className="form-select"
                          name="bikeType"
                          id="bikeType"
                          value={bikeType}
                          onChange={(e) => setBikeType(e.target.value)}
                        >
                          <option value="Bikes & Quads">Bikes & Quads</option>
                          <option value="Bikes Only">Bikes Only</option>
                          <option value="Quads Only">Quads Only</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-xl-6">
                      <div className="mb-3">
                        <label htmlFor="riderLevel" className="form-label">
                          Rider Level
                        </label>
                        <select
                          className="form-select"
                          name="riderLevel"
                          id="riderLevel"
                          value={riderLevel}
                          onChange={(e) => setRiderLevel(e.target.value)}
                        >
                          <option value=""></option>
                          <option value="All Levels">All Levels</option>
                          <option value="Beginner">Beginner</option>
                          <option value="Intermediate">Intermediate</option>
                          <option value="Advanced">Advanced</option>
                          <option value="Expert">Expert</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-xl-6">
                      <div className="mb-3">
                        <label htmlFor="distance" className="form-label">
                          Riding Spot Distance
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="distance"
                          name="distance"
                          value={distance}
                          onChange={(e) => setDistance(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-xl-6">
                      <div className="mb-3">
                        <label htmlFor="price" className="form-label">
                          Riding Spot Price
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="price"
                          name="price"
                          value={price}
                          onChange={(e) => setPrice(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-xl-6">
                      <div className="mb-3">
                        <label htmlFor="eventLocation" className="form-label">
                          Riding Spot Location{" "}
                          <span className="text-danger">*</span>
                        </label>
                        <GooglePlacesAutocomplete
                          apiKey="AIzaSyD_Lsht21cw3UEhTUP9cPW0R9kylZIqCmM"
                          selectProps={{
                            className:
                              "locationAutoComplete" +
                              (locationError ? " is-invalid" : ""),
                            initialValue: location.address,
                            onChange: handlePlaceSelected,
                            placeholder: "Search for a location",
                            onFocus: (e) => {
                              e.target.value = "";
                              setLocationError(null);
                            },
                            styles: {
                              placeholder: (provided) => ({
                                ...provided,
                                color: "#7c8fac",
                              }),
                              control: (provided) => ({
                                ...provided,
                                background: "transparent",
                                border: "1px solid #333f55",
                                borderRadius: "5px",
                              }),
                              input: (provided) => ({
                                ...provided,
                                color: "#fff",
                                cursor: "text",
                              }),
                              option: (provided) => ({
                                ...provided,
                                color: "#fff",
                                background: "#0F1820",
                                cursor: "pointer",
                              }),
                              dropdownIndicator: (provided) => ({
                                ...provided,
                                display: "none",
                              }),
                              indicatorSeparator: (provided) => ({
                                ...provided,
                                display: "none",
                              }),
                              menu: (provided) => ({
                                ...provided,
                                background: "#0F1820",
                              }),
                              loadingIndicator: (provided) => ({
                                ...provided,
                                color: "#fff",
                              }),
                              loadingMessage: (provided) => ({
                                ...provided,
                                color: "#fff",
                              }),
                              noOptionsMessage: (provided) => ({
                                ...provided,
                                color: "#fff",
                              }),
                              singleValue: (provided) => ({
                                ...provided,
                                color: "#fff",
                              }),
                            },
                          }}
                          apiOptions={{
                            region: "za",
                          }}
                          autocompletionRequest={{
                            componentRestrictions: {
                              country: ["za"],
                            },
                          }}
                          style={{ background: "#ff0000" }}
                        />
                        {locationError ? (
                          <div className="invalid-feedback">
                            {locationError}
                          </div>
                        ) : null}
                        {location.address ? (
                          <small className="text-muted">
                            {location.address}
                          </small>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-xl-6">
                      <div className="mb-3">
                        <label htmlFor="ridingTypes" className="form-label">
                          Riding Types
                        </label>
                        <Select
                          className="custom-select"
                          onChange={(selectedOptions) => {
                            setRidingTypes(
                              selectedOptions.map((option) => option.value)
                            );
                          }}
                          defaultValue={ridingTypes.map((type) => ({
                            value: type,
                            label:
                              type.charAt(0).toUpperCase() +
                              type.slice(1).toLowerCase(),
                          }))}
                          components={{
                            IndicatorSeparator: () => null,
                            ClearIndicator: () => null,
                            Control: ({ children, ...props }) => {
                              return (
                                <div
                                  className="form-select-custom"
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  {children}
                                </div>
                              );
                            },
                          }}
                          isMulti={true}
                          options={[
                            { value: "enduro", label: "Enduro" },
                            { value: "mx", label: "MX" },
                            { value: "trail", label: "Trail" },
                            { value: "adventure", label: "Adventure" },
                            { value: "freestyle", label: "Freestyle" },
                            { value: "supermoto", label: "Supermoto" },
                            { value: "track", label: "Track" },
                            { value: "other", label: "Other" },
                          ]}
                        />
                      </div>
                    </div>
                    <div className="col-xl-12">
                      <div className="mb-3">
                        <label
                          htmlFor="eventDescription"
                          className="form-label"
                        >
                          Riding Spot Description
                        </label>
                        <textarea
                          className="form-control"
                          id="eventDescription"
                          rows="15"
                          value={description}
                          onChange={(e) => setDescription(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-4">
                  <div className="mb-3">
                    <label className="form-label">
                      Riding Spot Image <span className="text-danger">*</span>
                    </label>
                    <div
                      {...getRootProps({
                        className: "dropzone border rounded-3 p-3 text-center",
                      })}
                    >
                      <input {...getInputProps()} />
                      {preview ? (
                        <div>
                          <img
                            src={preview}
                            alt="Preview"
                            style={{
                              maxWidth: "100%",
                              maxHeight: "150px",
                              marginBottom: "10px",
                            }}
                          />
                          <button
                            type="button"
                            className="btn btn-danger btn-sm"
                            onClick={removeFile}
                          >
                            Remove File
                          </button>
                        </div>
                      ) : (
                        <p className="mb-0">
                          Drag an image here, or click to upload
                        </p>
                      )}
                    </div>
                  </div>

                  {userDetails?.role === "admin" ? (
                    <div className="mb-3 d-flex justify-content-end flex-column">
                      <label htmlFor="Json" className="form-label">
                        Riding Spot JSON
                      </label>
                      <textarea
                        className="form-control"
                        id="Json"
                        rows="10"
                        value={spotJson}
                        onChange={(e) => setSpotJson(e.target.value)}
                      />
                      <button
                        className="btn btn-grey mt-3"
                        type="button"
                        onClick={processJson}
                      >
                        Parse JSON
                      </button>
                    </div>
                  ) : null}
                </div>
              </div>
              <div className="card-footer d-flex justify-content-end">
                <div className="form-check form-switch py-2 me-4">
                  <input
                    className="form-check-input cursor-pointer"
                    type="checkbox"
                    id="published"
                    defaultChecked={published}
                    onChange={(e) => setPublished(!published)}
                  />
                  <label className="form-check-label" htmlFor="published">
                    Publish
                  </label>
                </div>
                <button type="submit" className="btn btn-primary d-flex">
                  {uploading || loading ? (
                    <div className="spin me-2">
                      <i className="ti ti-loader"></i>
                    </div>
                  ) : null}
                  Save Riding Spot
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NewRidingSpot;
