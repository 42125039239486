import React, { useCallback, useState } from "react";
import Announcement from "../../components/announcement";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../../stores/AuthContext";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { useDropzone } from "react-dropzone";
import { storage } from "../../firebase";
import { toast } from "react-toastify";
import GooglePlacesAutocomplete, {
  geocodeByPlaceId,
} from "react-google-places-autocomplete";
import Select from "react-select";
import { addWorkshop } from "../../services/firebase_service";

function NewWorkshop() {
  const navigate = useNavigate();
  const { setWorkshops } = useAuth();
  const [workshop, setWorkshop] = useState({
    location: {
      place: "",
      country: "",
      address: "",
      province: "",
      street: "",
      city: "",
    },
    name: "",
    categories: [],
    featured: false,
    reviews: [],
    email: "",
    phone: {
      verified: false,
      countryFlag: "",
      countryDialCode: "",
      countryIsoCode: "",
      number: "",
    },
    imageUrl: "",
    description: "",
    verified: false,
    website: "",
    published: false,
    products: [],
    services: [],
    logoUrl: "",
    socials: [],
  });

  const [loading, setLoading] = useState(false);
  const [locationError, setLocationError] = useState(null);
  const [categories, setCategories] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [preview, setPreview] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [uploadedImageUrl, setUploadedImageUrl] = useState("");
  const [newSocial, setNewSocial] = useState({ platform: "", link: "" });

  const handleDrop = useCallback((acceptedFiles) => {
    if (acceptedFiles && acceptedFiles.length) {
      const file = acceptedFiles[0];
      setSelectedFile(file);
      setPreview(URL.createObjectURL(file));
    }
  }, []);

  const removeFile = () => {
    setSelectedFile(null);
    setPreview(null);
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: handleDrop,
    accept: {
      "image/*": [".jpeg", ".jpg", ".png", ".gif"],
    },
    maxFiles: 1,
  });

  const uploadImage = async () => {
    if (!selectedFile) {
      return null;
    }

    const storageRef = ref(storage, `workshopImages/${selectedFile.name}`);
    const uploadTask = uploadBytesResumable(storageRef, selectedFile);

    setUploading(true);

    return new Promise((resolve, reject) => {
      uploadTask.on(
        "state_changed",
        null,
        (error) => {
          console.error("Upload error:", error);
          toast.fire("Error!", "Failed to upload the image.", "error");
          setUploading(false);
          reject(error);
        },
        async () => {
          const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
          setUploadedImageUrl(downloadURL);
          setUploading(false);
          resolve(downloadURL);
        }
      );
    });
  };

  const handlePlaceSelected = (value) => {
    const placeId = value.value.place_id;

    geocodeByPlaceId(placeId)
      .then((results) => {
        // Extract address components
        const addressComponents = results[0].address_components;

        // Find suburb (locality or sublocality)
        const suburb = addressComponents.find(
          (component) =>
            component.types.includes("sublocality") ||
            component.types.includes("locality")
        )?.long_name;

        // Find province (administrative_area_level_1)
        const province = addressComponents.find((component) =>
          component.types.includes("administrative_area_level_1")
        )?.long_name;

        // You can now set these values in your state if needed
        if (suburb && province) {
          const locationData = {
            suburb: suburb,
            province: province,
            city:
              addressComponents.find((component) =>
                component.types.includes("locality")
              )?.long_name || "",
            address: results[0].formatted_address || "",
            place: results[0].place_id || "",
            street:
              addressComponents.find((component) =>
                component.types.includes("route")
              )?.long_name || "",
          };
          setWorkshop({ ...workshop, location: locationData });
        } else {
          setLocationError(
            "The location you selected is not valid. Please search for a valid location."
          );
        }
      })
      .catch((error) => console.error(error));
  };

  const handleAddSocial = () => {
    if (newSocial.platform && newSocial.link) {
      const social = {
        platform: newSocial.platform,
        link: newSocial.link,
        createdAt: new Date(),
      };

      setWorkshop({
        ...workshop,
        socials: [...(workshop.socials || []), social],
      });
      setNewSocial({ platform: "", link: "" });
    }
  };

  const handleRemoveSocial = (index) => {
    const updatedSocials = [...workshop.socials];
    updatedSocials.splice(index, 1);
    setWorkshop({ ...workshop, socials: updatedSocials });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (
      workshop.name &&
      workshop.location &&
      workshop.description &&
      categories.length > 0
    ) {
      try {
        let imageUrl = "";
        if (selectedFile) {
          imageUrl = await uploadImage();
        }
        let newWorkshop = {
          ...workshop,
          logoUrl: imageUrl,
          categories: categories.map((category) => category.value),
          createdAt: new Date(),
        };
        const doc = await addWorkshop(newWorkshop);
        newWorkshop = { ...newWorkshop, id: doc.id };
        

        setWorkshops((prevWorkshops) => [...prevWorkshops, newWorkshop]);
        toast.success("Workshop created successfully!");
        navigate("/workshops");
      } catch (error) {
        console.error("Error creating workshop:", error);
        toast.error("Error creating workshop. Please try again.");
      }
    } else {
      toast.error("Please fill in all required fields.");
    }
    setLoading(false);
  };

  return (
    <div>
      <Announcement />
      <div className="row">
        <div className="col-xl-12">
          <div className="card">
            <div className="card-header bg-transparent">
              <div className="bg-transparent d-flex align-items-center justify-content-between mb-3">
                <div className="left">
                  <h4 className="">New Workshop</h4>
                  <p className="mb-0">Create a new workshop</p>
                </div>
                <div className="right">
                  <Link to="/workshops" className="btn btn-primary">
                    <i className="ti ti-arrow-left"></i> Back to Workshops
                  </Link>
                </div>
              </div>
            </div>
            <form id="newWorkshop" onSubmit={handleSubmit}>
              <div className="card-body pt-0 row">
                <div className="col-xl-8">
                  <div className="row">
                    <div className="col-xl-6">
                      <div className="mb-3">
                        <label htmlFor="eventTitle" className="form-label">
                          Workshop Name <span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="name"
                          name="name"
                          value={workshop.name || ""}
                          onChange={(e) =>
                            setWorkshop({ ...workshop, name: e.target.value })
                          }
                          required
                        />
                      </div>
                    </div>

                    <div className="col-xl-6">
                      <div className="mb-3">
                        <label htmlFor="eventTitle" className="form-label">
                          Contact Email <span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="email"
                          name="email"
                          value={workshop.email || ""}
                          onChange={(e) =>
                            setWorkshop({
                              ...workshop,
                              email: e.target.value,
                            })
                          }
                          required
                        />
                      </div>
                    </div>

                    <div className="col-xl-6">
                      <div className="mb-3">
                        <label htmlFor="eventTitle" className="form-label">
                          Contact Phone <span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="phone"
                          name="phone"
                          value={workshop.phone.number || ""}
                          onChange={(e) => {
                            if (
                              e.target.value !== "" &&
                              e.target.value !== null &&
                              e.target.value.length > 0
                            ) {
                              if (!/^\d+$/.test(e.target.value)) {
                                e.target.value = e.target.value.replace(
                                  /[^0-9]/g,
                                  ""
                                );
                              }
                            }
                            setWorkshop({
                              ...workshop,
                              phone: {
                                ...workshop.phone,
                                number: e.target.value,
                                countryDialCode: "27",
                                countryFlag: "🇿🇦",
                                countryIsoCode: "ZA",
                              },
                            });
                          }}
                          required
                        />
                        <small className="text-muted">
                          eg. 0712345678 (No spaces or special characters)
                        </small>
                      </div>
                    </div>

                    <div className="col-xl-6">
                      <div className="mb-3">
                        <label htmlFor="eventTitle" className="form-label">
                          Website
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="website"
                          name="website"
                          value={workshop.website || ""}
                          onChange={(e) =>
                            setWorkshop({
                              ...workshop,
                              website: e.target.value,
                            })
                          }
                        />
                      </div>
                    </div>

                    <div className="col-xl-6">
                      <div className="mb-3">
                        <label htmlFor="eventLocation" className="form-label">
                          Location <span className="text-danger">*</span>
                        </label>
                        <GooglePlacesAutocomplete
                          apiKey="AIzaSyD_Lsht21cw3UEhTUP9cPW0R9kylZIqCmM"
                          selectProps={{
                            className:
                              "locationAutoComplete" +
                              (locationError ? " is-invalid" : ""),
                            initialValue:
                              (workshop.location &&
                                workshop.location.address) ||
                              "",
                            onChange: handlePlaceSelected,
                            placeholder: "Search for a location",
                            onFocus: (e) => {
                              e.target.value = "";
                              setLocationError(null);
                            },
                            styles: {
                              placeholder: (provided) => ({
                                ...provided,
                                color: "#7c8fac",
                              }),
                              control: (provided) => ({
                                ...provided,
                                background: "transparent",
                                border: "1px solid #333f55",
                                borderRadius: "5px",
                              }),
                              input: (provided) => ({
                                ...provided,
                                color: "#fff",
                                cursor: "text",
                              }),
                              option: (provided) => ({
                                ...provided,
                                color: "#fff",
                                background: "#0F1820",
                                cursor: "pointer",
                              }),
                              dropdownIndicator: (provided) => ({
                                ...provided,
                                display: "none",
                              }),
                              indicatorSeparator: (provided) => ({
                                ...provided,
                                display: "none",
                              }),
                              menu: (provided) => ({
                                ...provided,
                                background: "#0F1820",
                              }),
                              loadingIndicator: (provided) => ({
                                ...provided,
                                color: "#fff",
                              }),
                              loadingMessage: (provided) => ({
                                ...provided,
                                color: "#fff",
                              }),
                              noOptionsMessage: (provided) => ({
                                ...provided,
                                color: "#fff",
                              }),
                              singleValue: (provided) => ({
                                ...provided,
                                color: "#fff",
                              }),
                            },
                          }}
                          apiOptions={{
                            region: "za",
                          }}
                          autocompletionRequest={{
                            componentRestrictions: {
                              country: ["za"],
                            },
                          }}
                          style={{ background: "#ff0000" }}
                        />
                        {locationError ? (
                          <div className="invalid-feedback">
                            {locationError}
                          </div>
                        ) : null}
                        {workshop.location != null &&
                          workshop.location.address && (
                            <small className="text-muted">
                              {workshop.location.address || ""}
                            </small>
                          )}
                      </div>
                    </div>
                    <div className="col-xl-6">
                      <div className="mb-3">
                        <label htmlFor="categories" className="form-label">
                          Categories
                        </label>
                        <Select
                          className="custom-select"
                          onChange={(selectedOptions) => {
                            setWorkshop({
                              ...workshop,
                              categories: selectedOptions.map(
                                (option) => option.value
                              ),
                            });
                            setCategories(selectedOptions);
                          }}
                          components={{
                            IndicatorSeparator: () => null,
                            ClearIndicator: () => null,
                            Control: ({ children, ...props }) => {
                              return (
                                <div
                                  className="form-select-custom"
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  {children}
                                </div>
                              );
                            },
                          }}
                          isMulti={true}
                          options={[
                            { value: "mechanic", label: "Mechanic" },
                            { value: "spares", label: "Spares" },
                            { value: "parts", label: "Parts" },
                            { value: "training", label: "Training" },
                            { value: "online", label: "Online" },
                            { value: "store", label: "Store" },
                            { value: "gear", label: "Gear" },
                            { value: "accessories", label: "Accessories" },
                          ]}
                        />
                      </div>
                    </div>
                    <div className="col-xl-12">
                      <div className="mb-3">
                        <label
                          htmlFor="eventDescription"
                          className="form-label"
                        >
                          Workshop Description
                        </label>
                        <textarea
                          className="form-control"
                          id="eventDescription"
                          rows="15"
                          value={workshop.description || ""}
                          onChange={(e) =>
                            setWorkshop({
                              ...workshop,
                              description: e.target.value,
                            })
                          }
                        />
                      </div>
                    </div>
                    <div className="col-xl-12">
                      <div className="mb-3">
                        <label className="form-label">Social Media Links</label>
                        <div className="row g-3">
                          <div className="col-md-4">
                            <select
                              className="form-select"
                              value={newSocial.platform}
                              onChange={(e) =>
                                setNewSocial({
                                  ...newSocial,
                                  platform: e.target.value,
                                })
                              }
                            >
                              <option value="">Select Platform</option>
                              <option value="facebook">Facebook</option>
                              <option value="instagram">Instagram</option>
                              <option value="twitter">Twitter</option>
                              <option value="youtube">YouTube</option>
                              <option value="tiktok">TikTok</option>
                              <option value="whatsapp">WhatsApp</option>
                            </select>
                          </div>
                          <div className="col-md-6">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Social Media Link"
                              value={newSocial.link}
                              onChange={(e) =>
                                setNewSocial({
                                  ...newSocial,
                                  link: e.target.value,
                                })
                              }
                            />
                          </div>
                          <div className="col-md-2">
                            <button
                              type="button"
                              className="btn btn-primary w-100"
                              onClick={handleAddSocial}
                              disabled={!newSocial.platform || !newSocial.link}
                            >
                              Add Link
                            </button>
                          </div>
                        </div>

                        {workshop.socials && workshop.socials.length > 0 && (
                          <div className="mt-3">
                            <table className="table">
                              <thead>
                                <tr>
                                  <th>Platform</th>
                                  <th>Link</th>
                                  <th>Actions</th>
                                </tr>
                              </thead>
                              <tbody>
                                {workshop.socials.map((social, index) => (
                                  <tr key={index}>
                                    <td>
                                      {social.platform.charAt(0).toUpperCase() +
                                        social.platform.slice(1)}
                                    </td>
                                    <td>{social.link}</td>
                                    <td>
                                      <button
                                        type="button"
                                        className="btn btn-danger btn-sm"
                                        onClick={() => handleRemoveSocial(index)}
                                      >
                                        Remove
                                      </button>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-4">
                  <div className="mb-3">
                    <label className="form-label">
                      Workshop Image <span className="text-danger">*</span>
                    </label>
                    <div
                      {...getRootProps({
                        className: "dropzone border rounded-3 p-3 text-center",
                      })}
                    >
                      <input {...getInputProps()} />
                      {preview ? (
                        <div>
                          <img
                            src={preview}
                            alt="Preview"
                            style={{
                              maxWidth: "100%",
                              maxHeight: "150px",
                              marginBottom: "10px",
                            }}
                          />
                          <button
                            type="button"
                            className="btn btn-danger btn-sm"
                            onClick={removeFile}
                          >
                            Remove File
                          </button>
                        </div>
                      ) : (
                        <p className="mb-0">
                          Drag an image here, or click to upload
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-footer d-flex justify-content-end">
                <div className="form-check form-switch py-2 me-4">
                  <input
                    className="form-check-input cursor-pointer"
                    type="checkbox"
                    id="featured"
                    checked={workshop.featured}
                    onChange={(e) =>
                      setWorkshop({
                        ...workshop,
                        featured: !workshop.featured,
                      })
                    }
                  />
                  <label className="form-check-label" htmlFor="featured">
                    Featured
                  </label>
                </div>
                <div className="form-check form-switch py-2 me-4">
                  <input
                    className="form-check-input cursor-pointer"
                    type="checkbox"
                    id="verified"
                    checked={workshop.verified}
                    onChange={(e) =>
                      setWorkshop({
                        ...workshop,
                        verified: !workshop.verified,
                      })
                    }
                  />
                  <label className="form-check-label" htmlFor="verified">
                    Verified
                  </label>
                </div>
                <div className="form-check form-switch py-2 me-4">
                  <input
                    className="form-check-input cursor-pointer"
                    type="checkbox"
                    id="published"
                    checked={workshop.published}
                    onChange={(e) =>
                      setWorkshop({
                        ...workshop,
                        published: !workshop.published,
                      })
                    }
                  />
                  <label className="form-check-label" htmlFor="published">
                    Publish
                  </label>
                </div>
                <button type="submit" className="btn btn-primary d-flex">
                  {uploading || loading ? (
                    <div className="spin me-2">
                      <i className="ti ti-loader"></i>
                    </div>
                  ) : null}
                  Save Workshop
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NewWorkshop;
