import React, { useEffect, useState } from "react";
import { useAuth } from "../../../stores/AuthContext";
import { capitalize } from "../../../utils/stringUtils";
import { formatDistanceToNow } from "date-fns";
import { Link, useNavigate } from "react-router-dom";
import DataTable from "datatables.net-react";
import DT from "datatables.net-dt";
import "./users.css";
import Announcement from "../../../components/announcement";
import { deleteUserDocs } from "../../../services/firebase_service";
import { toast } from "react-toastify";

DataTable.use(DT);

function Users() {
  const { users, setUsers } = useAuth();
  const [loading, setLoading] = useState(true);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [filteredProvince, setFilteredProvince] = useState(null);
  const [filteredGender, setFilteredGender] = useState(null);
  const [filteredRole, setFilteredRole] = useState(null);
  const [filteredPlan, setFilteredPlan] = useState(null);
  const [filteredPlatform, setFilteredPlatform] = useState(null);
  const [filteredLoginWith, setFilteredLoginWith] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [tableData, setTableData] = useState([]);
  const navigate = useNavigate();

  // Predefined filter options
  const roleOptions = ["admin", "user", "organiser"];
  const genderOptions = ["male", "female", "other"];
  const planOptions = ["free", "pro"];
  const platformOptions = ["ios", "android", "web"];
  const loginWithOptions = ["facebook", "google", "standard"];
  const provinceOptions = [
    "All",
    "Eastern Cape",
    "Free State",
    "Gauteng",
    "KwaZulu-Natal",
    "Limpopo",
    "Mpumalanga",
    "Northern Cape",
    "North West",
    "Western Cape"
  ];

  const tableOptions = {
    order: [[6, "desc"]],
    paging: true,
    searching: true,
    lengthMenu: [10, 25, 50, 75, 100],
    language: {
      emptyTable: "No users available to display",
    },
    columnDefs: [
      {
        targets: [0],
        render: function (data, type, row) {
          return `<div class="d-flex align-items-center justify-content-between">
            <div class="left d-flex align-items-center">
              <img src="${row[10]}" class="rounded-circle cursor-pointer" width="40" height="40" onClick="window.handleRowClick('${row[8]}')" />
              <div class="ms-3">
                <h6 class="fs-4 fw-semibold mb-0 cursor-pointer" onClick="window.handleRowClick('${row[9]}')">${data} ${row[12] == 'facebook' ? '<i class="ti ti-brand-facebook text-info"></i>' : row[12] == 'google' ? '<i class="ti ti-brand-google text-danger"></i>' :''}</h6>
                <span class="fw-normal"><a href="mailto:${row[10]}" target="_blank" rel="noreferrer" class="underLink">${row[11]}</a></span>
              </div>
            </div>
            <div class="copyUid">
              <button class="btn btn-sm btn-outline-primary" onClick="window.copyUid('${row[8]}')">
                <i class="ti ti-copy"></i>
              </button>
            </div>
          </div>`;
        },
      },
      {
        targets: [2],
        render: function (data, type, row) {
          const statusClass =
            data === "Active"
              ? "bg-success-subtle text-success"
              : data === "Inactive"
              ? "bg-warning-subtle text-warning"
              : "bg-danger-subtle text-danger";
          return `<span class="badge ${statusClass}">${data}</span>`;
        },
      },
      {
        targets: [6], // Signed Up column
        render: function (data, type, row) {
          if (type === "sort") {
            return data; // Keep timestamp for sorting
          }
          return data
            ? formatDistanceToNow(new Date(parseInt(data)), { addSuffix: true })
            : "Unknown";
        },
      },
      {
        targets: [8],
        render: function (data, type, row) {
          if (data.toLowerCase() === "ios") {
            return `<div class="text-center fs-6 text-black"><i class="ti ti-brand-apple"></i></div>`;
          } else if (data.toLowerCase() === "android") {
            return `<div class="text-center fs-6 text-success"><i class="ti ti-brand-android"></i></div>`;
          } else if (data.toLowerCase() === "web") {
            return `<div class="text-center fs-6 text-light"><i class="ti ti-world"></i></div>`;
          } else {
            return data;
          }
        },
      },
      {
        targets: [9],
        sortable: false,
        render: function (data, type, row) {
          if (data !== "6IOcXft8StSE06moK2WG17BS1eT2") {
            return `<div class="dropdown dropstart">
            <a href="#" class="text-muted" data-bs-toggle="dropdown" aria-expanded="false">
              <i class="ti ti-dots-vertical fs-6"></i>
            </a>
            <ul class="dropdown-menu">
              <li><button class="dropdown-item d-flex align-items-center gap-3" onClick="window.editUser('${data}')">
                <i class="fs-4 ti ti-edit"></i>Edit</button></li>
              <li><button class="dropdown-item d-flex align-items-center gap-3" onClick="window.deleteUser('${data}')">
                <i class="fs-4 ti ti-trash"></i>Delete</button></li>
            </ul>
          </div>`;
          } else {
            return `<div class="dropdown dropstart">
            <a href="#" class="text-muted" data-bs-toggle="dropdown" aria-expanded="false">
              <i class="ti ti-dots-vertical fs-6"></i>
            </a>
            <ul class="dropdown-menu">
              <li><button class="dropdown-item d-flex align-items-center gap-3" onClick="window.editUser('${data}')">
                <i class="fs-4 ti ti-edit"></i>Edit</button></li>
            </ul>
          </div>`;
          }
        },
      },
    ],
  };

  useEffect(() => {
    if (users) {
      applyFilters();
      setLoading(false);
    }
  }, [users, filteredProvince, filteredGender, filteredRole, filteredPlan, filteredPlatform, filteredLoginWith, searchQuery]);

  useEffect(() => {
    if (filteredUsers.length > 0) {
      setTableData(
        filteredUsers.map((user) => [
          `${user.firstName} ${user.lastName}`,
          user.role ? capitalize(user.role) : "User",
          calculateStatus(user),
          user.gender ?? "",
          user.settings?.preferedProvince ?? "",
          user.lastLogin?.seconds
            ? formatDistanceToNow(new Date(user.lastLogin.seconds * 1000), {
                addSuffix: true,
              })
            : "Never",
          user.createdAt?.seconds
            ? new Date(user.createdAt.seconds * 1000).getTime()
            : 0,
          user.userPlan ? capitalize(user.userPlan) : "Free",
          user.settings?.platform ?? "",
          user.uid,
          user.photoURL ??
            `https://ui-avatars.com/api/?name=${user.firstName}+${user.lastName}&background=random&color=fff`,
          user.email,
          user.settings?.loginWith ?? "",
        ])
      );
    }
  }, [filteredUsers]);

  useEffect(() => {
    window.handleRowClick = (uid) => {
      navigate(`/user/${uid}`);
    };

    window.copyUid = (uid) => {
      navigator.clipboard.writeText(uid);
      toast.success("User ID copied to clipboard");
    };

    window.editUser = (uid) => {
      navigate(`/user/${uid}/edit`);
    };

    window.deleteUser = deleteUser;

    return () => {
      delete window.handleRowClick;
      delete window.copyUid;
      delete window.editUser;
      delete window.deleteUser;
    };
  }, [navigate]);

  const handleRowClick = (id) => {
    navigate(`/user/${id}`);
  };

  const applyFilters = () => {
    let filtered = [...users];

    if (filteredProvince) {
      filtered = filtered.filter(
        (user) => user.settings?.preferedProvince === filteredProvince
      );
    }

    if (filteredGender) {
      filtered = filtered.filter(
        (user) => user.gender?.toLowerCase() === filteredGender.toLowerCase()
      );
    }

    if (filteredRole) {
      filtered = filtered.filter(
        (user) => user.role?.toLowerCase() === filteredRole.toLowerCase()
      );
    }

    if (filteredPlan) {
      if (filteredPlan === null || filteredPlan === undefined) {
        filteredPlan = "free";
      }
      filtered = filtered.filter(
        (user) =>
          (user.userPlan?.toLowerCase() || "free") ===
          filteredPlan.toLowerCase()
      );
    }

    if (filteredPlatform) {
      filtered = filtered.filter(
        (user) => 
          user.settings?.platform?.toLowerCase() === filteredPlatform.toLowerCase()
      );
    }

    if (filteredLoginWith) {
      if (filteredLoginWith === "standard") {
        // Filter for users that don't use Facebook or Google
        filtered = filtered.filter(
          (user) => !user.settings?.loginWith || (user.settings?.loginWith !== "facebook" && user.settings?.loginWith !== "google")
        );
      } else {
        filtered = filtered.filter(
          (user) => user.settings?.loginWith === filteredLoginWith
        );
      }
    }

    if (searchQuery.trim() !== "") {
      filtered = filtered.filter((user) =>
        `${user.firstName} ${user.lastName} ${user.email} ${user?.gender} ${user?.settings?.preferedProvince} ${user?.userPlan} ${user?.settings?.platform}`
          .toLowerCase()
          .includes(searchQuery.toLowerCase())
      );
    }

    setFilteredUsers(filtered);
  };

  const filterUsersByProvince = (province) => {
    setFilteredProvince(province);
  };

  const filterByGender = (gender) => {
    setFilteredGender(gender);
  };

  const filterByRole = (role) => {
    setFilteredRole(role);
  };

  const filterByPlan = (plan) => {
    setFilteredPlan(plan);
  };

  const filterByPlatform = (platform) => {
    setFilteredPlatform(platform);
  };

  const filterByLoginWith = (loginWith) => {
    setFilteredLoginWith(loginWith);
  };

  const removeProvinceFilter = () => {
    setFilteredProvince(null);
  };

  const removeRoleFilter = () => {
    setFilteredRole(null);
  };

  const removeGenderFilter = () => {
    setFilteredGender(null);
  };

  const removePlanFilter = () => {
    setFilteredPlan(null);
  };

  const removePlatformFilter = () => {
    setFilteredPlatform(null);
  };

  const removeLoginWithFilter = () => {
    setFilteredLoginWith(null);
  };

  const deleteUser = async (uid) => {
    if (window.confirm("Are you sure you want to delete this user?")) {
      const deleteUserResp = await deleteUserDocs(uid);

      if (deleteUserResp) {
        const updatedUsers = users.filter((user) => user.uid !== uid);
        setFilteredUsers(updatedUsers);
        setUsers(updatedUsers);
        toast.success("User deleted successfully");
      }
    }
  };

  const calculateStatus = (user) => {
    const lastLoginDate = user.lastLogin?.seconds
      ? new Date(user.lastLogin.seconds * 1000)
      : null;
    const createdAtDate = user.createdAt?.seconds
      ? new Date(user.createdAt.seconds * 1000)
      : null;

    const threeMonthsAgo = new Date();
    threeMonthsAgo.setMonth(threeMonthsAgo.getMonth() - 3);

    const sixMonthsAgo = new Date();
    sixMonthsAgo.setMonth(sixMonthsAgo.getMonth() - 6);

    let status = "Active";

    if (lastLoginDate) {
      if (lastLoginDate < sixMonthsAgo) {
        status = "Lost";
      } else if (lastLoginDate < threeMonthsAgo) {
        status = "Inactive";
      }
    } else if (createdAtDate && createdAtDate < threeMonthsAgo) {
      status = "Lost";
    }

    return status;
  };

  return (
    <div>
      <Announcement />
      <div className="row">
        <div className="col-xl-12">
          <div className="card">
            <div className="card-header bg-transparent d-flex align-items-center justify-content-between">
              <div className="left">
                <h4 className="card-title">Users ({filteredUsers.length})</h4>
                <p className="card-subtitle">
                  Manage and view all registered users
                </p>
              </div>
              <div className="right">
                <Link to="/users/new" className="btn btn-primary">
                  <i className="ti ti-plus"></i> New
                </Link>
              </div>
            </div>
            <div className="card-body pt-0">
              <div className="row mb-3">
                <div className="col-md-12">
                  <div className="d-flex justify-content-start align-items-center mb-3">
                    {filteredProvince && (
                      <span className="mb-1 me-1 badge text-bg-dark">
                        Province: {filteredProvince}{" "}
                        <button
                          className="btn btn-sm"
                          onClick={removeProvinceFilter}
                        >
                          <i className="ti ti-x"></i>
                        </button>
                      </span>
                    )}
                    {filteredGender && (
                      <span className="mb-1 me-1 badge text-bg-dark">
                        Gender: {capitalize(filteredGender)}{" "}
                        <button
                          className="btn btn-sm"
                          onClick={removeGenderFilter}
                        >
                          <i className="ti ti-x"></i>
                        </button>
                      </span>
                    )}
                    {filteredRole && (
                      <span className="mb-1 me-1 badge text-bg-dark">
                        Role: {capitalize(filteredRole)}{" "}
                        <button
                          className="btn btn-sm"
                          onClick={removeRoleFilter}
                        >
                          <i className="ti ti-x"></i>
                        </button>
                      </span>
                    )}
                    {filteredPlan && (
                      <span className="mb-1 me-1 badge text-bg-dark">
                        Plan: {capitalize(filteredPlan)}{" "}
                        <button
                          className="btn btn-sm"
                          onClick={removePlanFilter}
                        >
                          <i className="ti ti-x"></i>
                        </button>
                      </span>
                    )}
                    {filteredPlatform && (
                      <span className="mb-1 me-1 badge text-bg-dark">
                        Platform: {capitalize(filteredPlatform)}{" "}
                        <button
                          className="btn btn-sm"
                          onClick={removePlatformFilter}
                        >
                          <i className="ti ti-x"></i>
                        </button>
                      </span>
                    )}
                    {filteredLoginWith && (
                      <span className="mb-1 me-1 badge text-bg-dark">
                        Login: {capitalize(filteredLoginWith)}{" "}
                        <button
                          className="btn btn-sm"
                          onClick={removeLoginWithFilter}
                        >
                          <i className="ti ti-x"></i>
                        </button>
                      </span>
                    )}
                  </div>
                  <div className="d-flex flex-wrap gap-2">
                    {/* Role Dropdown */}
                    <div className="dropdown">
                      <button 
                        className="btn btn-outline-primary dropdown-toggle" 
                        type="button" 
                        data-bs-toggle="dropdown" 
                        aria-expanded="false"
                      >
                        Filter by Role
                      </button>
                      <ul className="dropdown-menu">
                        {roleOptions.map((role) => (
                          <li key={role}>
                            <button 
                              className="dropdown-item" 
                              onClick={() => filterByRole(role)}
                            >
                              {capitalize(role)}
                            </button>
                          </li>
                        ))}
                      </ul>
                    </div>

                    {/* Gender Dropdown */}
                    <div className="dropdown">
                      <button 
                        className="btn btn-outline-primary dropdown-toggle" 
                        type="button" 
                        data-bs-toggle="dropdown" 
                        aria-expanded="false"
                      >
                        Filter by Gender
                      </button>
                      <ul className="dropdown-menu">
                        {genderOptions.map((gender) => (
                          <li key={gender}>
                            <button 
                              className="dropdown-item" 
                              onClick={() => filterByGender(gender)}
                            >
                              {capitalize(gender)}
                            </button>
                          </li>
                        ))}
                      </ul>
                    </div>

                    {/* Province Dropdown */}
                    <div className="dropdown">
                      <button 
                        className="btn btn-outline-primary dropdown-toggle" 
                        type="button" 
                        data-bs-toggle="dropdown" 
                        aria-expanded="false"
                      >
                        Filter by Province
                      </button>
                      <ul className="dropdown-menu">
                        {provinceOptions.map((province) => (
                          <li key={province}>
                            <button 
                              className="dropdown-item" 
                              onClick={() => filterUsersByProvince(province)}
                            >
                              {province}
                            </button>
                          </li>
                        ))}
                      </ul>
                    </div>

                    {/* Plan Dropdown */}
                    <div className="dropdown">
                      <button 
                        className="btn btn-outline-primary dropdown-toggle" 
                        type="button" 
                        data-bs-toggle="dropdown" 
                        aria-expanded="false"
                      >
                        Filter by Plan
                      </button>
                      <ul className="dropdown-menu">
                        {planOptions.map((plan) => (
                          <li key={plan}>
                            <button 
                              className="dropdown-item" 
                              onClick={() => filterByPlan(plan)}
                            >
                              {capitalize(plan)}
                            </button>
                          </li>
                        ))}
                      </ul>
                    </div>

                    {/* Platform Dropdown */}
                    <div className="dropdown">
                      <button 
                        className="btn btn-outline-primary dropdown-toggle" 
                        type="button" 
                        data-bs-toggle="dropdown" 
                        aria-expanded="false"
                      >
                        Filter by Platform
                      </button>
                      <ul className="dropdown-menu">
                        {platformOptions.map((platform) => (
                          <li key={platform}>
                            <button 
                              className="dropdown-item" 
                              onClick={() => filterByPlatform(platform)}
                            >
                              {capitalize(platform)}
                            </button>
                          </li>
                        ))}
                      </ul>
                    </div>

                    {/* Login With Dropdown */}
                    <div className="dropdown">
                      <button 
                        className="btn btn-outline-primary dropdown-toggle" 
                        type="button" 
                        data-bs-toggle="dropdown" 
                        aria-expanded="false"
                      >
                        Filter by Login Method
                      </button>
                      <ul className="dropdown-menu">
                        {loginWithOptions.map((loginWith) => (
                          <li key={loginWith}>
                            <button 
                              className="dropdown-item" 
                              onClick={() => filterByLoginWith(loginWith)}
                            >
                              {capitalize(loginWith)}
                            </button>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
                
              </div>
              <div
                className="table-responsive border rounded-4"
                data-bs-theme="dark"
              >
                {loading ? (
                  <div className="text-center py-4">
                    <div className="spinner-border text-primary" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  </div>
                ) : filteredUsers.length > 0 ? (
                  <DataTable
                    className="table text-nowrap usersTable table-dark mb-0 align-middle"
                    options={tableOptions}
                    data={tableData}
                  >
                    <thead className="text-dark fs-4">
                      <tr>
                        <th>
                          <h6 className="fs-4 fw-semibold mb-0">Full Name</h6>
                        </th>
                        <th>
                          <h6 className="fs-4 fw-semibold mb-0">Role</h6>
                        </th>
                        <th>
                          <h6 className="fs-4 fw-semibold mb-0">Status</h6>
                        </th>
                        <th>
                          <h6 className="fs-4 fw-semibold mb-0">Gender</h6>
                        </th>
                        <th>
                          <h6 className="fs-4 fw-semibold mb-0">
                            Preferred Province
                          </h6>
                        </th>
                        <th>
                          <h6 className="fs-4 fw-semibold mb-0">Last Login</h6>
                        </th>
                        <th>
                          <h6 className="fs-4 fw-semibold mb-0">Signed Up</h6>
                        </th>
                        <th>
                          <h6 className="fs-4 fw-semibold mb-0">Plan</h6>
                        </th>
                        <th>
                          <h6 className="fs-4 fw-semibold mb-0">Platform</h6>
                        </th>
                        <th>
                          <h6 className="fs-4 fw-semibold mb-0">&nbsp;</h6>
                        </th>
                      </tr>
                    </thead>
                  </DataTable>
                ) : (
                  <div className="text-center py-5">
                    <div className="mb-3">
                      <i className="ti ti-search fs-1 text-muted"></i>
                    </div>
                    <h5 className="fs-5 fw-semibold mb-1">No Results Found</h5>
                    <p className="text-muted">
                      No users match your current filter criteria. Try adjusting your filters or search query.
                    </p>
                    <button 
                      className="btn btn-outline-primary mt-2" 
                      onClick={() => {
                        setFilteredProvince(null);
                        setFilteredGender(null);
                        setFilteredRole(null);
                        setFilteredPlan(null);
                        setFilteredPlatform(null);
                        setSearchQuery("");
                      }}
                    >
                      <i className="ti ti-refresh me-1"></i> Reset All Filters
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Users;
