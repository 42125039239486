import React, { useEffect, useState } from "react";
import { useAuth } from "../../stores/AuthContext";
import CustomLineChart from "../../components/line_chart";
import { useNavigate } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import { formatDistanceToNow } from "date-fns";

const OrganizerDashboard = () => {
  const [myEvents, setMyEvents] = useState([]);
  const [upcomingEvents, setUpcomingEvents] = useState([]);
  const [totalParticipants, setTotalParticipants] = useState(0);
  const [totalParticipantsThisYear, setTotalParticipantsThisYear] = useState(0); // New state for yearly participants
  const [myEventsThisMonth, setMyEventsThisMonth] = useState(0);
  const [myEventsLastMonth, setMyEventsLastMonth] = useState(0);
  const [eventsDifference, setEventsDifference] = useState(0);
  const [eventsStatsData, setEventsStatsData] = useState([]);

  const { events, userDetails } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (!events || !userDetails) {
      return;
    }

    setMyEvents(events);

    // Get upcoming events
    const now = new Date();
    const upcoming = events
      .filter((event) => new Date(event.date.seconds * 1000) >= now)
      .sort((a, b) => a.date.seconds - b.date.seconds)
      .slice(0, 5);

    setUpcomingEvents(upcoming);

    // Calculate total participants across all organizer's events
    const participants = events.reduce(
      (total, event) => total + (event.participants?.length || 0),
      0
    );

    setTotalParticipants(participants);

    // Calculate total participants for this year only
    const currentYear = new Date().getFullYear();

    const thisYearParticipants = events.reduce((total, event) => {
      const eventYear = new Date(event.date.seconds * 1000).getFullYear();
      if (eventYear === currentYear) {
        return total + (event.participants?.length || 0);
      }
      return total;
    }, 0);

    setTotalParticipantsThisYear(thisYearParticipants);

    // Calculate events this month and last month
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth();

    const lastMonth = currentMonth === 0 ? 11 : currentMonth - 1;
    const lastMonthYear = currentMonth === 0 ? currentYear - 1 : currentYear;

    let thisMonthEvents = 0;
    let lastMonthEvents = 0;

    events.forEach((event) => {
      const eventDate = new Date(event.date.seconds * 1000);
      const eventMonth = eventDate.getMonth();
      const eventYear = eventDate.getFullYear();

      if (eventMonth === currentMonth && eventYear === currentYear) {
        thisMonthEvents++;
      } else if (eventMonth === lastMonth && eventYear === lastMonthYear) {
        lastMonthEvents++;
      }
    });

    setMyEventsThisMonth(thisMonthEvents);
    setMyEventsLastMonth(lastMonthEvents);

    // Calculate difference
    const eventsDiff =
      lastMonthEvents === 0
        ? 0
        : ((thisMonthEvents - lastMonthEvents) / lastMonthEvents) * 100;

    setEventsDifference(Math.round(eventsDiff));

    // Create monthly stats for line chart
    const generateMonthlyStats = () => {
      const months = Array.from({ length: 12 }, (_, i) => {
        const d = new Date();
        d.setMonth(d.getMonth() - i);
        return {
          month: d.getMonth(),
          year: d.getFullYear(),
          name: d.toLocaleString("default", { month: "short" }),
          events: 0,
          participants: 0,
        };
      }).reverse();

      // Count events per month
      events.forEach((event) => {
        const eventDate = new Date(event.date.seconds * 1000);
        const eventMonth = eventDate.getMonth();
        const eventYear = eventDate.getFullYear();

        const monthIndex = months.findIndex(
          (m) => m.month === eventMonth && m.year === eventYear
        );

        if (monthIndex !== -1) {
          months[monthIndex].events++;
          months[monthIndex].participants += event.participants?.length || 0;
        }
      });

      // Create chart data
      const eventsStats = months.map((m) => ({
        name: m.name,
        events: m.events,
        participants: m.participants,
        pv: 0,
        amt: 0,
      }));

      return eventsStats;
    };

    const eventsStats = generateMonthlyStats();
    setEventsStatsData(eventsStats);
  }, [events, userDetails]);

  const handleCreateEvent = () => {
    navigate("/event/new");
  };

  const handleEventClick = (eventId) => {
    navigate(`/event/${eventId}/entries`);
  };

  return (
    <>
      {/* <div className="row">
        <div className="col-lg-6 d-flex">
          <iframe
            src="https://www.loom.com/embed/2540ada046da485da5f2d9d50f9933f9?sid=ee0390fc-728a-4a1a-8922-114860225550"
            webkitallowfullscreen
            mozallowfullscreen
            allowfullscreen
            style={{
              top: 0,
              left: 0,
              width: "100%",
              height: "350px",
            }}
          ></iframe>
        </div>
        <div className="col-lg-6 d-flex align-items-stretch">&nbsp;</div>
      </div> */}
      <div className="row">
        <div className="col-sm-4 d-flex align-items-stretch">
          <div className="card w-100">
            <div className="card-body pb-0 mb-xxl-4 pb-1">
              <p className="mb-1 fs-3">My Events</p>
              <h4 className="fw-semibold fs-7">{myEventsThisMonth}</h4>
              <div className="d-flex align-items-center mb-3">
                {Math.abs(eventsDifference) < 0.1 ? (
                  <span className="me-1 rounded-circle bg-warning-subtle round-20 d-flex align-items-center justify-content-center">
                    <i className="ti ti-minus text-warning"></i>
                  </span>
                ) : eventsDifference > 0 ? (
                  <span className="me-1 rounded-circle bg-success-subtle round-20 d-flex align-items-center justify-content-center">
                    <i className="ti ti-arrow-up-left text-success"></i>
                  </span>
                ) : (
                  <span className="me-1 rounded-circle bg-danger-subtle round-20 d-flex align-items-center justify-content-center">
                    <i className="ti ti-arrow-down-right text-danger"></i>
                  </span>
                )}
                <p
                  className="text-dark fs-3 mb-0"
                  data-tooltip-id="last-months-my-events"
                  data-tooltip-content={`Last month: ${myEventsLastMonth} events`}
                >
                  <Tooltip
                    id="last-months-my-events"
                    place="right"
                    effect="solid"
                    type="dark"
                    backgroundColor="#000"
                  ></Tooltip>
                  {Math.abs(eventsDifference) ?? 0}%
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- Total Participants --> */}
        <div className="col-sm-4 d-flex align-items-stretch">
          <div className="card w-100">
            <div className="card-body">
              <p className="mb-1 fs-3">Total Participants</p>
              <h4 className="fw-semibold fs-7">{totalParticipantsThisYear}</h4>
              <div className="d-flex align-items-center mb-3">
                <p
                  className="text-dark fs-3 mb-0"
                  data-tooltip-id="yearly-participants"
                  data-tooltip-content={`All-time participants: ${totalParticipants}`}
                >
                  For {new Date().getFullYear()}
                  <Tooltip
                    id="yearly-participants"
                    place="right"
                    effect="solid"
                    type="dark"
                    backgroundColor="#000"
                  ></Tooltip>
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- Create New Event --> */}
        <div className="col-sm-4 d-flex align-items-stretch">
          <div className="card w-100 bg-primary-subtle">
            <div
              className="card-body d-flex flex-column justify-content-center align-items-center cursor-pointer"
              onClick={handleCreateEvent}
            >
              <i className="ti ti-plus text-primary fs-8 mb-2"></i>
              <h4 className="fw-semibold text-primary">Create New Event</h4>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-6 d-flex align-items-stretch">
          <div className="card w-100">
            <div className="card-body">
              <div className="d-sm-flex d-block align-items-center justify-content-between mb-9">
                <div className="mb-3 mb-sm-0">
                  <h4 className="card-title fw-semibold">My Events</h4>
                  <p className="card-subtitle mb-0">
                    Events organized by month (last 12 months)
                  </p>
                </div>
              </div>
              <div
                className="row align-items-center"
                style={{ minHeight: "300px" }}
              >
                <CustomLineChart label="events" data={eventsStatsData} />
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-6 d-flex align-items-stretch">
          <div className="card w-100">
            <div className="card-body">
              <div className="d-sm-flex d-block align-items-center justify-content-between mb-9">
                <div className="mb-3 mb-sm-0">
                  <h4 className="card-title fw-semibold">
                    Participant Attendance
                  </h4>
                  <p className="card-subtitle mb-0">
                    Number of participants by month (last 12 months)
                  </p>
                </div>
              </div>
              <div
                className="row align-items-center"
                style={{ minHeight: "300px" }}
              >
                <CustomLineChart label="participants" data={eventsStatsData} />
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-12 d-flex align-items-stretch">
          <div className="card w-100">
            <div className="card-body">
              <div className="d-sm-flex d-block align-items-center justify-content-between mb-7">
                <div className="mb-3 mb-sm-0">
                  <h4 className="card-title fw-semibold">Upcoming Events</h4>
                  <p className="card-subtitle">Your next scheduled events</p>
                </div>
              </div>
              <div className="table-responsive">
                <table className="table align-middle text-nowrap mb-0">
                  <thead>
                    <tr className="text-muted fw-semibold">
                      <th scope="col" className="ps-0">
                        Event Name
                      </th>
                      <th scope="col">Date</th>
                      <th scope="col">Location</th>
                      <th scope="col">Participants</th>
                    </tr>
                  </thead>
                  <tbody className="border-top">
                    {upcomingEvents.length > 0 ? (
                      upcomingEvents.map((event, index) => (
                        <tr
                          key={`${index}Event${event.id}`}
                          className="cursor-pointer"
                          onClick={() => handleEventClick(event.id)}
                        >
                          <td className="ps-0">
                            <div className="d-flex align-items-center">
                              <div>
                                <h6 className="fw-semibold mb-1">
                                  {event.title}
                                </h6>
                                <p className="fs-2 mb-0 text-muted">
                                  <span
                                    dangerouslySetInnerHTML={{
                                      __html:
                                        event.description?.substring(0, 50) +
                                        (event.description?.length > 50
                                          ? "..."
                                          : ""),
                                    }}
                                  />
                                </p>
                              </div>
                            </div>
                          </td>
                          <td>
                            <p className="mb-0 fw-normal fs-4">
                              {new Date(
                                event.date.seconds * 1000
                              ).toLocaleString("en-GB", {
                                day: "numeric",
                                month: "short",
                                year: "numeric",
                                hour: "2-digit",
                                minute: "2-digit",
                              })}
                            </p>
                          </td>
                          <td>
                            <p className="mb-0 fw-normal fs-4">
                              {event.location}
                            </p>
                          </td>
                          <td>
                            <span className="badge bg-primary-subtle text-primary rounded-pill">
                              {event.participants?.length || 0} Registered
                            </span>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="4" className="text-center py-4">
                          <p className="fs-4 mb-0">
                            No upcoming events scheduled
                          </p>
                          <button
                            className="btn btn-primary mt-2"
                            onClick={handleCreateEvent}
                          >
                            Create Your First Event
                          </button>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OrganizerDashboard;
