import React, { useEffect, useState } from "react";
import "./RequestFeature.css";
import { useAuth } from "../../stores/AuthContext";

function RequestFeature() {
  const { userDetails } = useAuth();
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    featureTitle: "",
    featureDescription: "",
    featureOn: "Funduro App", // Default value for the dropdown
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitStatus, setSubmitStatus] = useState(null);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    // Only update if userDetails has valid data
    if (userDetails?.firstName || userDetails?.email) {
      setFormData(prevFormData => ({
        ...prevFormData,
        name: userDetails.firstName + " " + userDetails.lastName,
        email: userDetails.email,
      }));
    }
  }, [userDetails]); // Only re-run when userDetails change

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.name.trim()) newErrors.name = "Name is required";
    if (!formData.email.trim()) {
      newErrors.email = "Email is required";
    } else if (!/^\S+@\S+\.\S+$/.test(formData.email)) {
      newErrors.email = "Email is invalid";
    }
    if (!formData.featureTitle.trim())
      newErrors.featureTitle = "Feature title is required";
    if (!formData.featureDescription.trim())
      newErrors.featureDescription = "Feature description is required";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const sendEmail = async () => {
    try {
      // Using a server endpoint that handles SMTP email sending
      const response = await fetch(
        "https://us-central1-funduro-za.cloudfunctions.net/sendEmail",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            to: "support@funduro.app",
            subject: `Feature Request: ${formData.featureTitle} [${formData.featureOn}]`,
            senderName: formData.name,
            senderEmail: formData.email,
            message: `Feature Location: ${formData.featureOn}\n\n${formData.featureDescription}`,
          }),
        }
      );

      if (response.ok) {
        return { success: true };
      } else {
        const errorData = await response.json();
        throw new Error(errorData.message || "Failed to send email");
      }
    } catch (error) {
      console.error("Error sending email:", error);
      return { success: false, error };
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) return;

    setIsSubmitting(true);
    setSubmitStatus(null);

    try {
      const result = await sendEmail();

      if (result.success) {
        setSubmitStatus({
          type: "success",
          message: "Your feature request has been submitted successfully!",
        });
        // Clear form
        setFormData({
          name: "",
          email: "",
          featureTitle: "",
          featureDescription: "",
          featureOn: "Funduro App", // Reset to default
        });
      } else {
        setSubmitStatus({
          type: "error",
          message:
            "Failed to submit your feature request. Please try again later.",
        });
      }
    } catch (error) {
      setSubmitStatus({
        type: "error",
        message: "An error occurred. Please try again later.",
      });
      console.error("Error in form submission:", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="card">
      <div className="card-header bg-transparent">
        <div className="d-flex align-items-center justify-content-between">
          <div className="left">
            <h4 className="mb-0">Request a Feature</h4>
            <p className="mb-0">
              Have an idea that would make Funduro better? We'd love to hear it!
              Fill out the form below and our team will review your suggestion.
            </p>
          </div>
        </div>
      </div>
      <div className="card-body">
        {submitStatus && (
          <div className={`status-message ${submitStatus.type}`}>
            {submitStatus.message}
          </div>
        )}

        <form onSubmit={handleSubmit} className="feature-request-form">
          <div className="form-group">
            <label htmlFor="name">Your Name</label>
            <input
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleInputChange}
              className={errors.name ? "error" : ""}
              disabled={userDetails.email ? true : false}
            />
            {errors.name && <span className="error-text">{errors.name}</span>}
          </div>

          <div className="form-group">
            <label htmlFor="email">Your Email</label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleInputChange}
              className={errors.email ? "error" : ""}
              disabled={userDetails.email ? true : false}
            />
            {errors.email && <span className="error-text">{errors.email}</span>}
          </div>

          <div className="form-group">
            <label htmlFor="featureOn">
              Feature On (Funduro App or Organiser Portal)
            </label>
            <select
              id="featureOn"
              name="featureOn"
              value={formData.featureOn}
              onChange={handleInputChange}
              className="form-select"
            >
              <option value="Funduro App">Funduro App</option>
              <option value="Organiser Portal">Organiser Portal</option>
            </select>
          </div>

          <div className="form-group">
            <label htmlFor="featureTitle">Feature Title</label>
            <input
              type="text"
              id="featureTitle"
              name="featureTitle"
              value={formData.featureTitle}
              onChange={handleInputChange}
              className={errors.featureTitle ? "error" : ""}
            />
            {errors.featureTitle && (
              <span className="error-text">{errors.featureTitle}</span>
            )}
          </div>

          <div className="form-group">
            <label htmlFor="featureDescription">Feature Description</label>
            <textarea
              id="featureDescription"
              name="featureDescription"
              value={formData.featureDescription}
              onChange={handleInputChange}
              rows={5}
              className={errors.featureDescription ? "error" : ""}
            ></textarea>
            {errors.featureDescription && (
              <span className="error-text">{errors.featureDescription}</span>
            )}
          </div>

          <button
            type="submit"
            className="btn btn-primary"
            disabled={isSubmitting}
          >
            {isSubmitting ? "Submitting..." : "Submit Feature Request"}
          </button>
        </form>
      </div>
    </div>
  );
}

export default RequestFeature;
